import React, { FC, useEffect, useMemo, useReducer, useState, useRef } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  MenuItem,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  CircularProgress,
  IconButton,
  InputAdornment,
  Checkbox,
  Card,
} from '@mui/material';
import AlarmIcon from '@mui/icons-material/AddAlarm';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { useCommonStyles } from '../../../../common/styles/common-styles';
import {
  NOTIFICATION_BASE_PATH,
  NOTIFICATION_MANUAL_PATH,
  NOTIFICATION_AUTO_PATH,
  MANDATORY_FIELD_ERROR_TEXT,
  LINK_INVALID_ERROR,
  NotificationType as NotificaionEnumTypes,
  ContentType,
  RedirectType,
  OpenInDevice,
  ReceiverType,
  TriggerTime,
  notificationFrom,
  DUPLICATED_AGENT_CODE,
  duplicatedAgentcode,
} from '../../constants';
import { NotificationDetail, NotificationType, i18nConfigItem, I18DataItem } from '../../types/notification-types';
import { find } from 'lodash';
import { NotificationFormMode } from '../../utils/common-utils';
import {
  createNotification,
  updateNotification,
  uploadExcel,
  fetchNotification,
  fetchNotificationI18Config,
  downloadTemplate,
  DOWNLOAD_TEMPLATE_URL,
  UPLOADED_FILE_URL,
  DOWNLOAD_LOG_URL,
} from '../../redux/notificationCrud';
import { appendAlertItem, AlertType } from '../../../../../redux/common/commonSlice';
import { LayoutSplashScreen } from 'src/app/layout';
import { useIntl } from 'react-intl';
import FileUploader, { FileObj } from '../../../../common/components/FileUploader';
import ButtonDialog from '../../../../common/components/ButtonDialog';
import { useSelector, useDispatch } from 'react-redux';
import { AuthenticationState } from '../../../../../app/modules/Auth/_redux/authSlice';
import { RootState } from '../../../../../redux/store';
import TargetApplicantSection from '../../../../common/components/TargetApplicantSection';
import SpecificEventDeeplink from 'src/app/common/components/SpecificDeeplinkList/event/index';
import SpecificTrainingDeeplink from 'src/app/common/components/SpecificDeeplinkList/training/index';
import SpecificSurveyDeeplink from 'src/app/common/components/SpecificDeeplinkList/survey/index';
import { Routes, SPECIFIC_ROUTES } from 'src/app/common/constants';
import { getCurrentConfig } from 'src/app/common/utils';
import { isObject, isEqual, isEmpty } from 'lodash';

const useStyles = makeStyles()((theme) => ({
  container: {
    // padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  textAreaRowContainer: {
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    width: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  inputMargin: {
    marginRight: 20,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  section: {
    padding: '10px 20px',
  },
  divideMargin: {
    marginBottom: 10,
  },
  normalBox: {
    width: 250,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 20,
  },
  settingTable: {
    width: '50%',
  },
  notificationMessage: {
    fontSize: '1rem',
  },
}));

type NotificationFormState = {
  notificationType?: NotificationType | '';
  category?: string;
  contentType?: string;
  msgTitle?: string;
  pushTitle?: string;
  msgContent?: string;
  pushContent?: string;
  redirectTo?: string;
  openBy?: string;
  link?: string;
  receiverType?: string;
  receivers?: FileObj;
  triggertime?: string;
  sendingTime?: Date | null;
  i18Data?: any;
  roleList?: string[];
  zoneList?: string[];
  filters?: FileObj[];
  historyReceiverType?: string;
  from: string;
  LinkParams: any;
};

const initialState: NotificationFormState = {
  notificationType: 'INBOX',
  category: '',
  msgTitle: '',
  contentType: ContentType.FIXED,
  msgContent: '',
  redirectTo: '',
  openBy: '',
  link: '',
  LinkParams: {},
  receiverType: ReceiverType['designation-receivers'],
  receivers: undefined,
  sendingTime: null,
  triggertime: '',
  i18Data: null,
  roleList: [],
  zoneList: [],
  filters: [],
  historyReceiverType: '',
  from: '',
};

type InitialFieldAction = {
  type: 'INITIAL_FIELDS';
  payload: NotificationFormState;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof NotificationFormState;
    value: any;
  };
};

type NotificationFormAction = InitialFieldAction | ModifyFieldAction;

const notificationFormReducer = (
  state: NotificationFormState,
  action: NotificationFormAction,
): NotificationFormState => {
  switch (action.type) {
    case 'INITIAL_FIELDS':
      return {
        notificationType: action.payload.notificationType,
        category: action.payload.category,
        msgTitle: action.payload.msgTitle,
        contentType: action.payload.contentType,
        msgContent: action.payload.msgContent,
        redirectTo: action.payload.redirectTo,
        openBy: action.payload.openBy,
        link: action.payload.link,
        receiverType: action.payload.receiverType,
        receivers: action.payload.receivers,
        sendingTime: action.payload.sendingTime,
        triggertime: action.payload.triggertime,
        roleList: action.payload.roleList,
        zoneList: action.payload.zoneList,
        filters: action.payload.filters,
        historyReceiverType: action.payload.historyReceiverType,
        from: action.payload.from,
        LinkParams: action.payload.LinkParams,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
  }
};

type ErrorState = {
  mandatory: {
    notificationType: boolean;
    category: boolean;
    // msgTitle: boolean;
    // msgContent: boolean;
    contentType: boolean;
    redirectTo: boolean;
    openBy: boolean;
    link: boolean;
    receiverType: boolean;
    receivers: boolean;
    sendingTime: boolean;
    triggertime: boolean;
    roleList: boolean;
    zoneList: boolean;
    linkParams: boolean;
    msgs: {
      [code: string]: {
        msgTitle: boolean;
        pushTitle: boolean;
      };
    };
    filters: boolean;
  };
  immediate: {
    template: boolean;
  };
};

const NotificationDetailForm: FC<RouteComponentProps> = ({ history, location }) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const [isSubmitting, setIsSubmiting] = useState<boolean>(false);
  const [deeplink, setDeepLink] = useState<Record<string, any> | string>('');
  let segmentInstance = useRef<any>();
  let specificDeeplinkListRef = useRef<any>();
  let specificSurveyDeeplinkRef = useRef<any>();
  const isEnableDesugnation = window.envConfig['ENABLE_DESIGNATION'] === 'true';
  const isEnableZone = window.envConfig['ENABLE_ZONE'] === 'true';
  const { id } = useParams<{ id: string }>();
  const params = useParams<{ from: string }>();

  const { formMode, code } = useMemo(
    () => ({
      formMode: id
        ? window.location.href.endsWith(`/view/${id}`)
          ? NotificationFormMode.VIEW
          : NotificationFormMode.EDIT
        : NotificationFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingI18n, setIsLoadingI18n] = useState<boolean>(false);
  const [i18nConfig, setI18nConfig] = useState<Array<i18nConfigItem>>([]);
  // eslint-disable-next-line
  const [showSelectEventBtn, setIsShowSelectEventBtn] = useState<boolean>(false);

  const [formState, formDispatch] = useReducer(notificationFormReducer, initialState);
  const [i18nDatas, setI18nDatas] = useState<{ [code: string]: I18DataItem }>({});
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const isDisabled = useMemo(() => {
    return formMode === NotificationFormMode.VIEW;
  }, [formMode]);

  const currentConfig = getCurrentConfig(user);
  const CategoryType = currentConfig?.notificationCategoryType as { [key: string]: string };
  const AppRoutes = currentConfig?.AppRoutes as { [key: string]: string };

  const validateRoles = () => {
    segmentInstance?.current?.validate();
  };

  const isSpecificLink =
    formState.link === Routes.SPECIFIC_TRAINING_LINK ||
    formState.link === Routes.SPECIFIC_SURVEY_LINK ||
    formState.link === Routes.SPECIFIC_EVENT_LINK;

  const validateSpecificLink = (id: any) => {
    const linkParamState = isSpecificLink ? (id ? false : true) : false;

    const currentErrorState: ErrorState = {
      ...errorState,
      mandatory: {
        ...errorState.mandatory,
        linkParams: linkParamState,
      },
    };
    setErrorState(currentErrorState);
  };

  const reloadData = (config?: i18nConfigItem[]) => {
    if (code) {
      setIsLoading(true);
      fetchNotification(code, dispatch)
        .then((result) => {
          const {
            notificationType,
            category,
            msgTitle,
            msgContent,
            pushTitle,
            pushContent,
            contentType,
            extendValue,
            receiverType,
            sendingTime,
            importId,
            fileName,
            sendImmediately,
            roleList,
            zoneList,
            filters,
            from,
          } = result;
          const { openBy = '', link = '', redirectTo = '', params = {} } = extendValue || {};
          let newReceiverType = receiverType;

          if (receiverType === ReceiverType['all-pruforce-users']) {
            newReceiverType = ReceiverType['designation-receivers'];
          }

          let filtersWithNewFiled = filters.map((item) => {
            const url = `${UPLOADED_FILE_URL}/${item.importId}?contentType=${contentType}`;

            item.url = url;
            item.receiverType = receiverType;
            item.contentType = contentType;

            return item;
          });

          let { i18nData } = result;
          formDispatch({
            type: 'INITIAL_FIELDS',
            payload: {
              notificationType,
              category,
              contentType,
              msgTitle,
              pushTitle,
              msgContent,
              pushContent,
              historyReceiverType: receiverType,
              receiverType: newReceiverType,
              sendingTime,
              triggertime: !sendImmediately ? TriggerTime.specificTime : TriggerTime.sendImmediately,
              openBy,
              roleList,
              zoneList,
              link,
              redirectTo,
              filters: filtersWithNewFiled,
              from,
              LinkParams: params,

              receivers: importId
                ? {
                    importId: importId as string,
                    fileName,
                    url: `${UPLOADED_FILE_URL}/${importId}?contentType=${contentType}`,
                  }
                : undefined,
            },
          });
          // use old version data to initialize new data
          if (!i18nData) {
            i18nData = {};
          }
          (config || i18nConfig).forEach((item) => {
            if (!i18nData[item.code]) {
              i18nData[item.code] = {
                msgTitle: notificationType?.indexOf('INBOX') >= 0 ? msgTitle : '',
                msgContent: notificationType?.indexOf('INBOX') >= 0 ? msgContent : '',
                pushTitle: notificationType?.indexOf('PUSH') >= 0 ? (pushTitle ? pushTitle : msgTitle) : '',
                // it should use title to judge if the old data use pushTitle pushContent to save data
                pushContent: notificationType?.indexOf('PUSH') >= 0 ? (pushTitle ? pushContent : msgContent) : '',
              };
            }
          });
          setI18nDatas(i18nData);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const toDownloadTemplate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const contentType = formState.contentType!;
    const url = `${DOWNLOAD_TEMPLATE_URL}?contentType=${contentType}`;
    downloadTemplate(
      contentType === ContentType.FIXED ? 'Fixed Content template.xlsx' : 'Variable Content template.xlsx',
      url,
      {},
      dispatch,
    );
  };

  const toDownloadUploadedFile =
    (fileName: string, url: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      downloadTemplate(`${fileName}.xlsx`, url, {}, dispatch);
    };

  const handleDownloadLog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    downloadTemplate(`${formState.msgTitle}.xlsx`, `${DOWNLOAD_LOG_URL}/${id}`, {}, dispatch);
  };

  useEffect(() => {
    const link = formState?.link ?? '';
    const params = formState.LinkParams;
    const isSpecificRoute = SPECIFIC_ROUTES.includes(link);

    if (!params || isEmpty(params) || isSpecificRoute) {
      setDeepLink(link);
    } else {
      setDeepLink(
        JSON.stringify({
          link,
          params,
        }),
      );
    }
  }, [formState]);

  // const modifyTargetApplicant = (option: keyof Pick<NotificationFormState, 'enrolmentType' | 'eligibleAgents' | 'eligibleDesignations' | 'excludedAgents' | 'agentListFileName'>, value: any) => {
  //   formDispatch({ type: 'MODIFY_FIELD', payload: { field: option, value } });
  // };

  const [errorState, setErrorState] = useState<ErrorState>({
    mandatory: {
      notificationType: false,
      category: false,
      // msgTitle: false,
      // msgContent: false,
      msgs: {},
      contentType: false,
      redirectTo: false,
      openBy: false,
      link: false,
      receiverType: false,
      receivers: false,
      sendingTime: false,
      triggertime: false,
      roleList: false,
      zoneList: false,
      filters: false,
      linkParams: false,
    },
    immediate: {
      template: false,
    },
  });
  const loadI18Config = () => {
    setIsLoadingI18n(true);
    fetchNotificationI18Config(dispatch)
      .then((result) => {
        result.forEach((item) => {
          i18nDatas[item.code] = {
            msgTitle: '',
            msgContent: '',
            pushTitle: '',
            pushContent: '',
          };
          errorState.mandatory.msgs[item.code] = {
            msgTitle: false,
            pushTitle: false,
          };
        });
        setI18nDatas(i18nDatas);
        setErrorState(errorState);
        setI18nConfig(result);

        reloadData(result);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingI18n(false);
      });
  };

  const validateUploadFiles = () => {
    if (!isRenderUpload) {
      return false;
    }

    let fileList = formState.filters;

    const hasFiles =
      fileList &&
      fileList.find((item) => {
        return item.receiverType === formState.receiverType && item.contentType === formState.contentType;
      });
    if (hasFiles) {
      return false;
    }
    return true;
  };

  const onSubmitErrorValidator = () => {
    validateRoles();
    let currentErrorState: ErrorState = {
      ...errorState,
      mandatory: {
        notificationType: formState.notificationType ? false : true,
        category: formState.category ? false : true,
        // msgTitle: formState.msgTitle ? false : true,
        // msgContent: formState.msgContent ? false : true,
        contentType: formState.contentType ? false : true,
        redirectTo: formState.redirectTo ? false : true,
        openBy: formState.redirectTo !== RedirectType.URL ? false : formState.openBy ? false : true,
        link: isLinkValid(formState.link) ? false : true,
        receiverType: formState.receiverType ? false : true,
        receivers: false,
        sendingTime:
          formState.triggertime === TriggerTime.sendImmediately ? false : formState.sendingTime ? false : true,
        triggertime: formState.triggertime ? false : true,
        roleList:
          isRenderUpload || !isEnableDesugnation
            ? false
            : formState.roleList && formState.roleList.length > 0
            ? false
            : true,
        zoneList:
          isRenderUpload || !isEnableZone ? false : formState.zoneList && formState.zoneList.length > 0 ? false : true,
        msgs: {},
        filters: validateUploadFiles(),
        // linkParams: isSelectSpecificEventLink ? (formState.LinkParams && formState.LinkParams.id ? false : true) : false,
        linkParams: isSpecificLink ? (formState?.LinkParams?.id ? false : true) : false,
      },
      immediate: {
        template: false,
      },
    };

    i18nConfig.forEach((item) => {
      currentErrorState.mandatory.msgs[item.code] = {
        msgTitle: i18nDatas[item.code].msgTitle ? false : true,
        pushTitle: i18nDatas[item.code].pushTitle ? false : true,
      };
    });
    setErrorState(currentErrorState);
    return currentErrorState;
  };

  const onSubmitForm = async () => {
    let currentErrorState = onSubmitErrorValidator();
    const hasPush = formState.notificationType ? formState.notificationType.indexOf('PUSH') >= 0 : false;
    const hasInbox = formState.notificationType ? formState.notificationType.indexOf('INBOX') >= 0 : false;
    if (
      find(currentErrorState.mandatory, (status) => status === true) === undefined &&
      find(currentErrorState.immediate, (status) => status === true) === undefined &&
      find(currentErrorState.mandatory.msgs, (status) => {
        let res = false;
        res = res || (hasPush && status.pushTitle === true);
        res = res || (hasInbox && status.msgTitle === true);
        return res;
      }) === undefined
    ) {
      const defaultItem = i18nDatas[i18nConfig[0].code];
      const { msgTitle = '', msgContent = '', pushTitle = '', pushContent = '' } = defaultItem;
      // Call Api and submit
      const {
        notificationType = '',
        category = '',
        redirectTo = '',
        openBy = '',
        link = '',
        receiverType = '',
        triggertime = '',
        receivers = { importId: '', fileName: '' },
        sendingTime,
        roleList = [],
        zoneList = [],
        filters = [],
        // eslint-disable-next-line
        from,
        LinkParams,
      } = formState;
      let { contentType = '' } = formState;
      if (isPushOnly) {
        contentType = ContentType.FIXED;
      }

      const fromWhere = formState.from === '' ? params.from.toUpperCase() : formState.from;

      const details: NotificationDetail = {
        creator: user && user.username, // todo: need to fix with real name
        notificationType,
        category,
        contentType,

        msgTitle,
        msgContent,
        pushTitle,
        pushContent,
        roleList,
        zoneList,
        filters,

        extendValue: { redirectTo, openBy, link, params: LinkParams },
        receiverType: contentType === ContentType.VARIABLE ? ReceiverType['specific-receivers'] : receiverType,
        importId: receivers && receivers.importId,
        fileName: receivers && receivers.fileName,
        triggertime,
        sendingTime,
        sendImmediately: triggertime === TriggerTime.sendImmediately,
        i18nData: i18nDatas,
        from: fromWhere,
      };

      if (triggertime === TriggerTime.sendImmediately) {
        delete details.sendingTime;
      }

      // 2021-09-22 if all section selected, receiverType set to all and no exclude file uploaded
      if (details.receiverType === ReceiverType['designation-receivers'] && segmentInstance?.current?.allSelected()) {
        if (
          !(
            formState.filters &&
            formState.filters.find(
              (item) =>
                item.inclusive === false &&
                item.receiverType === formState.receiverType &&
                item.contentType === formState.contentType,
            )
          )
        ) {
          details.receiverType = ReceiverType['all-pruforce-users'];
        }
      }
      try {
        if (formMode === NotificationFormMode.CREATE) {
          setIsSubmiting(true);
          const res = await createNotification(details, dispatch);
          setIsSubmiting(false);
          if (res) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved successfully.`,
                },
              ]),
            );
          } else {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: Translation('global.submit.fail'),
                  content: Translation('global.submit.fail'),
                },
              ]),
            );
          }
        } else if (code && formMode === NotificationFormMode.EDIT) {
          setIsSubmiting(true);
          const res = await updateNotification(details, code, dispatch);
          setIsSubmiting(false);
          if (res) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: 'Record updated and published successfully',
                },
              ]),
            );
          } else {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: Translation('global.submit.fail'),
                  content: Translation('global.submit.fail'),
                },
              ]),
            );
          }
        }
        if (fromWhere === notificationFrom.portal) {
          history.push(NOTIFICATION_BASE_PATH + NOTIFICATION_MANUAL_PATH);
        } else {
          history.push(NOTIFICATION_BASE_PATH + NOTIFICATION_AUTO_PATH);
        }
      } catch (err: any) {
        if (err.errors.responseCode === duplicatedAgentcode) {
          errorState.immediate.template = true;
          setErrorState(errorState);
        }
        setIsSubmiting(false);
      }
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const isLinkValid = (link?: string) => {
    if (
      formState.redirectTo === RedirectType.notDirect ||
      (link &&
        (formState.redirectTo === RedirectType.pageInApp || link.startsWith('https://') || link.startsWith('http://')))
    ) {
      return true;
    }
    return false;
  };

  const onDismissErrorHandler = (field: keyof ErrorState['mandatory'], value: any) => {
    if (field === 'link') {
      setErrorState({
        ...errorState,
        mandatory: {
          ...errorState.mandatory,
          link: !isLinkValid(value),
        },
      });
      return;
    }
    if (value) {
      setErrorState({
        ...errorState,
        mandatory: {
          ...errorState.mandatory,
          [field]: false,
        },
      });
    }
  };

  useEffect(() => {
    loadI18Config();
    /*
     to compatible old version msgData, it need get i18n config first, then intitalize each msg data
     of language with old version msgData, so move loadData inside loadConfig
    */
    // reloadData();
    // eslint-disable-next-line
  }, [code]);

  const isPushOnly = formState.notificationType === 'PUSH';
  const fillForm = (checked: boolean, item: i18nConfigItem, isPush: boolean) => {
    if (checked) {
      const i18nDatasCopy = JSON.parse(JSON.stringify(i18nDatas));
      if (isPush) {
        i18nDatasCopy[item.code].pushTitle = i18nDatas[i18nConfig[0].code].pushTitle || '';
        i18nDatasCopy[item.code].pushContent = i18nDatas[i18nConfig[0].code].pushContent || '';
      } else {
        i18nDatasCopy[item.code].msgTitle = i18nDatas[i18nConfig[0].code].msgTitle || '';
        i18nDatasCopy[item.code].msgContent = i18nDatas[i18nConfig[0].code].msgContent || '';
      }
      // setI18nDatas(JSON.parse(JSON.stringify(i18nDatas)));
      setI18nDatas(i18nDatasCopy);
    }
  };

  const fillForms = (checked: boolean) => {
    if (checked) {
      const i18nDatasCopy = JSON.parse(JSON.stringify(i18nDatas));
      i18nConfig.forEach((item) => {
        i18nDatasCopy[item.code].msgTitle = i18nDatas[item.code].pushTitle || '';
        i18nDatasCopy[item.code].msgContent = i18nDatas[item.code].pushContent || '';
      });
      setI18nDatas(i18nDatasCopy);
    }
  };

  const updateTitle = (isPush: boolean, code: string, value: string | undefined) => {
    if (!!value) {
      if (isPush) {
        errorState.mandatory.msgs[code].pushTitle = false;
      } else {
        errorState.mandatory.msgs[code].msgTitle = false;
      }
      setErrorState(errorState);
    }
    const i18nDatasCopy = JSON.parse(JSON.stringify(i18nDatas));
    if (isPush) {
      i18nDatasCopy[code].pushTitle = value;
    } else {
      i18nDatasCopy[code].msgTitle = value;
    }
    setI18nDatas(i18nDatasCopy);
  };

  const updateContent = (isPush: boolean, code: string, value: string | undefined) => {
    const i18nDatasCopy = JSON.parse(JSON.stringify(i18nDatas));
    if (isPush) {
      i18nDatasCopy[code].pushContent = value;
    } else {
      i18nDatasCopy[code].msgContent = value;
    }
    setI18nDatas(i18nDatasCopy);
  };

  const renderContentForm = (item: i18nConfigItem, isPush: boolean, index: number) => {
    const msgErrStates = errorState.mandatory.msgs[item.code] || {};
    // let checked = false
    // if (isPush){
    //   checked = i18nDatas[item.code].pushTitle === i18nDatas[i18nConfig[0].code].pushTitle &&
    //   i18nDatas[item.code].pushContent === i18nDatas[i18nConfig[0].code].pushContent;
    // } else {
    //   checked = i18nDatas[item.code].msgTitle === i18nDatas[i18nConfig[0].code].msgTitle &&
    //   i18nDatas[item.code].msgContent === i18nDatas[i18nConfig[0].code].msgContent;
    // }
    return (
      <div className={classes.section} key={index}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>{item.name}</span>
        </div>
        {isDisabled ? null : index === 0 ? null : (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => fillForm(e.target.checked, item, isPush)}
                name="copyCard"
                color="secondary"
                // checked={checked}
              />
            }
            label={Translation('component.formSelectItem.use-card-above').replace('%s', i18nConfig[0].name)}
          />
        )}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('component.formLabel.notification-title')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              error={isPush ? msgErrStates.pushTitle : msgErrStates.msgTitle}
              margin="dense"
              variant="outlined"
              fullWidth
              helperText={(isPush ? msgErrStates.pushTitle : msgErrStates.msgTitle) && MANDATORY_FIELD_ERROR_TEXT}
              value={isPush ? i18nDatas[item.code].pushTitle : i18nDatas[item.code].msgTitle}
              onChange={(e) => {
                updateTitle(isPush, item.code, e.target.value);
              }}
              onBlur={() => {
                if (isPush) {
                  updateTitle(isPush, item.code, i18nDatas[item.code].pushTitle?.trim());
                } else {
                  updateTitle(isPush, item.code, i18nDatas[item.code].msgTitle?.trim());
                }
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('component.formLabel.content')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              margin="dense"
              variant="outlined"
              fullWidth
              multiline
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              value={isPush ? i18nDatas[item.code].pushContent : i18nDatas[item.code].msgContent}
              onChange={(e) => {
                updateContent(isPush, item.code, e.target.value);
              }}
              onBlur={() => {
                if (isPush) {
                  updateContent(isPush, item.code, i18nDatas[item.code].pushContent?.trim());
                } else {
                  updateContent(isPush, item.code, i18nDatas[item.code].msgContent?.trim());
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCard = (isPush: boolean) => {
    return (
      <Card key={isPush ? 1 : 0}>
        <div className={classes.section}>
          {isDisabled ? null : isPush ? null : formState.notificationType !== 'PUSH_INBOX' ? null : (
            <FormControlLabel
              control={<Checkbox onChange={(e) => fillForms(e.target.checked)} name="copyCard" color="secondary" />}
              label={Translation('component.formSelectItem.use-cards-above')}
            />
          )}

          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>
              {isPush ? Translation('component.formSelectItem.push') : Translation('component.formSelectItem.inbox')}
            </span>
          </div>
          {!isPush && (
            <div className={classes.textAreaRowContainer}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>
                  {Translation('component.formLabel.content-type')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <FormControl error disabled={isDisabled}>
                <RadioGroup
                  aria-label="contentType"
                  name="contentType"
                  value={formState.contentType}
                  onChange={(e) => {
                    onDismissErrorHandler('contentType', e.target.value);
                    onDismissErrorHandler('filters', e.target.value);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'contentType', value: e.target.value } });
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filters', value: [] } });
                  }}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {Object.entries(ContentType).map(([label, value]) => (
                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      key={value}
                      value={value}
                      control={<Radio />}
                      label={Translation(
                        `component.formSelectItem.${label === 'FIXED' ? 'fixedContent' : 'variableContent'}`,
                      )}
                    />
                  ))}
                </RadioGroup>
                <FormHelperText style={{ marginLeft: 14 }}>
                  {errorState.mandatory.contentType && MANDATORY_FIELD_ERROR_TEXT}
                </FormHelperText>
              </FormControl>
            </div>
          )}
        </div>

        {i18nConfig.map((item, index) => {
          return renderContentForm(item, isPush, index);
        })}
      </Card>
    );
  };

  const renderNotificationCards = () => {
    const type = formState.notificationType;
    let hasPush = type ? type.indexOf('PUSH') >= 0 : false;
    let hasInbox = type ? type.indexOf('INBOX') >= 0 : false;
    return [hasPush && renderCard(true), hasInbox && renderCard(false)];
  };

  const isRenderUpload = isPushOnly
    ? formState.receiverType !== ReceiverType['designation-receivers']
    : formState.contentType === ContentType.VARIABLE ||
      formState.receiverType !== ReceiverType['designation-receivers'];

  const isSegment =
    (formState.receiverType === ReceiverType['designation-receivers'] ||
      formState.receiverType === ReceiverType['all-pruforce-users']) &&
    formState.contentType === ContentType.FIXED;

  const onToggleDesignation = (values: any) => {
    onDismissErrorHandler('roleList', values);
    validateRoles();
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'roleList', value: values } });
  };

  const onToggleZones = (values: any) => {
    onDismissErrorHandler('zoneList', values);
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'zoneList', value: values } });
  };

  const onFilesChanges = (data: any, isInclusive: boolean) => {
    let newFilters: any;

    let fileList = formState.filters;

    //remove different files

    if (data) {
      //@ts-ignore
      fileList =
        data &&
        fileList &&
        fileList.map((item, index) => {
          if (item.receiverType !== data.receiverType || item.contentType !== data.contentType) {
            return null;
          }

          return item;
        });

      fileList = fileList && fileList.filter((item: any) => item);
    }

    onDismissErrorHandler('filters', fileList);

    //add
    const isExit = fileList && fileList.find((item) => item.inclusive === isInclusive);

    if (!isExit) {
      data && fileList && fileList.push(data);
      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filters', value: fileList } });
      return;
    }

    //remove

    if (!data && fileList && fileList.length > 0) {
      newFilters =
        formState?.filters &&
        fileList.map((file) => {
          if (
            !data &&
            file.inclusive === isInclusive &&
            file.receiverType === formState.receiverType &&
            file.contentType === formState.contentType
          ) {
            return null;
          }
          return file;
        });
    }

    //replace
    if (data && fileList && fileList.length > 0) {
      newFilters =
        formState?.filters &&
        fileList.map((file) => {
          if (file.inclusive === isInclusive) {
            return data;
          }
          return file;
        });
    }

    newFilters = newFilters.filter((item: any) => item);
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filters', value: newFilters } });
  };

  const onSelectItem = (id: any) => {
    validateSpecificLink(id);
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'LinkParams', value: { id: id } } });
  };

  return (
    <>
      {isLoading || isLoadingI18n ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div className={classes.rowContainer}>
                <div className={commonClasses.header}>
                  {formMode === NotificationFormMode.CREATE ? 'Add' : isDisabled ? 'View' : 'Edit'} Notification
                </div>
              </div>
              <Button variant="contained" color="inherit" onClick={() => history.go(-1)}>
                {Translation('global.back.btnText')}
              </Button>
            </div>

            {/* Basic Information */}
            <div className={classes.section}>
              <div className={classes.divideMargin}>
                <span className={classes.subHeader}>{Translation('component.globalHeader.notificationType')}</span>
              </div>
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer}>
                  <span className={classes.field}>
                    {Translation('component.formLabel.notification-type')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <FormControl error disabled={isDisabled}>
                  <RadioGroup
                    aria-label="notificationType"
                    name="notificationType"
                    value={formState.notificationType}
                    onChange={(e) => {
                      onDismissErrorHandler('notificationType', e.target.value);
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'notificationType', value: e.target.value },
                      });
                    }}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {Object.entries(NotificaionEnumTypes).map(([value, label]) => (
                      <FormControlLabel
                        style={{ marginBottom: 0 }}
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={Translation(`component.formSelectItem.${label}`)}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText style={{ marginLeft: 14 }}>
                    {errorState.mandatory.notificationType && MANDATORY_FIELD_ERROR_TEXT}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('component.formLabel.category')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={isDisabled}
                    error={errorState.mandatory.category}
                    select
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    helperText={errorState.mandatory.category && MANDATORY_FIELD_ERROR_TEXT}
                    value={formState.category}
                    onChange={(e) => {
                      onDismissErrorHandler('category', e.target.value);
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'category', value: e.target.value } });
                    }}
                  >
                    {Object.entries(CategoryType).map(([label, value]) => (
                      <MenuItem key={value} value={value}>
                        {Translation(`notification.category.type.${label}`)}
                      </MenuItem>
                    ))}
                    {/* only add secretary for auto notification */}
                    {isDisabled && (
                      <MenuItem key={'inbox_secretary'} value={'inbox_secretary'}>
                        {Translation(`notification.category.type.Secretary`)}
                      </MenuItem>
                    )}
                  </TextField>
                </div>
              </div>
            </div>
            {renderNotificationCards()}
            <div className={classes.section}>
              {!isPushOnly && formState.contentType === 'VARIABLE' && (
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer} style={{ minWidth: 160 }}>
                    &nbsp;
                  </div>
                  <div style={{ display: 'block' }}>
                    <p style={{ marginBottom: 0 }}>{Translation('component.notification.variablecontent1')}</p>
                    <p style={{ marginBottom: 0 }}>{Translation('component.notification.variablecontent2')}</p>
                  </div>
                </div>
              )}
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer}>
                  <span className={classes.field}>
                    {Translation('component.formLabel.redirectTo')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <FormControl error disabled={isDisabled}>
                  <RadioGroup
                    aria-label="redirectTo"
                    name="redirectTo"
                    value={formState.redirectTo}
                    onChange={(e) => {
                      onDismissErrorHandler('redirectTo', e.target.value);
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'link', value: '' } });
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'redirectTo', value: e.target.value } });
                    }}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {Object.entries(RedirectType)
                      .filter(([label, value]) => {
                        if (isPushOnly && value === RedirectType.notDirect) return false;
                        return true;
                      })
                      .map(([label, value]) => (
                        <FormControlLabel
                          style={{ marginBottom: 0 }}
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={Translation(`component.formSelectItem.${label}`)}
                        />
                      ))}
                  </RadioGroup>
                  <FormHelperText style={{ marginLeft: 14 }}>
                    {errorState.mandatory.redirectTo && MANDATORY_FIELD_ERROR_TEXT}
                  </FormHelperText>
                </FormControl>
              </div>
              {formState.redirectTo === 'url' ? (
                <div className={classes.textAreaRowContainer}>
                  <div className={classes.checkboxFieldContainer}>
                    <span className={classes.field}>
                      {Translation('component.formLabel.openBy')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <FormControl error disabled={isDisabled}>
                    <RadioGroup
                      aria-label="openBy"
                      name="openBy"
                      value={formState.openBy}
                      onChange={(e) => {
                        onDismissErrorHandler('openBy', e.target.value);
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'openBy', value: e.target.value } });
                      }}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {Object.entries(OpenInDevice).map(([label, value]) => (
                        <FormControlLabel
                          style={{ marginBottom: 0 }}
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={Translation(`component.formSelectItem.${label}`)}
                        />
                      ))}
                    </RadioGroup>
                    <FormHelperText style={{ marginLeft: 14 }}>
                      {errorState.mandatory.openBy && MANDATORY_FIELD_ERROR_TEXT}
                    </FormHelperText>
                  </FormControl>
                </div>
              ) : null}
              {formState.redirectTo === 'url' ? (
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('component.formLabel.link')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      disabled={isDisabled}
                      error={errorState.mandatory.link}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      // multiline
                      // InputProps={{
                      //   classes: {
                      //     input: classes.textArea,
                      //   },
                      // }}
                      helperText={errorState.mandatory.link && Translation(LINK_INVALID_ERROR)}
                      value={formState.link}
                      onChange={(e) => {
                        onDismissErrorHandler('link', e.target.value);
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'link', value: e.target.value } });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {formState.redirectTo === 'app' ? (
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('component.formLabel.link')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      disabled={isDisabled}
                      error={errorState.mandatory.link}
                      select
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      helperText={errorState.mandatory.link && MANDATORY_FIELD_ERROR_TEXT}
                      value={
                        isDisabled && formState.category === 'inbox_secretary'
                          ? JSON.parse(deeplink as string).link
                          : deeplink
                      }
                      onChange={(e) => {
                        onDismissErrorHandler('link', e.target.value);

                        let currentValue: any = e.target.value;
                        let selectValue: any;

                        try {
                          selectValue = JSON.parse(currentValue);
                        } catch (e) {
                          selectValue = currentValue;
                        }

                        const selectedItem: any = Object.entries(AppRoutes).find(([label, value]: any) => {
                          const hasParams = isObject(selectValue);

                          if (typeof value != 'string' && hasParams) {
                            return value.link === selectValue.link && isEqual(value.params, selectValue.params);
                          }

                          return value === selectValue;
                        });

                        if (selectedItem && typeof selectedItem[1] !== 'string') {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'LinkParams', value: selectedItem[1].params },
                          });
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'link', value: selectedItem[1].link },
                          });

                          setDeepLink(
                            JSON.stringify({
                              link: selectedItem[1].link,
                              params: selectedItem[1].params,
                            }),
                          );
                        } else {
                          setDeepLink(selectValue);
                          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'link', value: selectValue } });
                          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'LinkParams', value: null } });
                        }
                      }}
                    >
                      {Object.entries(AppRoutes).map(([label, value]: any) => {
                        if (typeof value !== 'string') {
                          value = JSON.stringify(value);
                        }

                        return (
                          <MenuItem key={value} value={value}>
                            {Translation(label)}
                          </MenuItem>
                        );
                      })}
                      {/* only add secretary for auto notification */}
                      {isDisabled && (
                        <MenuItem key={'SecretaryDetailsScreen'} value={'SecretaryDetailsScreen'}>
                          {Translation('route.secretary')}
                        </MenuItem>
                      )}
                    </TextField>

                    {formState.link === Routes.SPECIFIC_EVENT_LINK && (
                      <SpecificEventDeeplink
                        onSelectItem={onSelectItem}
                        selectId={formState?.LinkParams?.id}
                        isView={formMode === NotificationFormMode.VIEW}
                      />
                    )}
                    {formState.link === Routes.SPECIFIC_SURVEY_LINK && (
                      <SpecificSurveyDeeplink
                        onSelectItem={onSelectItem}
                        selectId={formState?.LinkParams?.id}
                        isView={formMode === NotificationFormMode.VIEW}
                      />
                    )}
                    {formState.link === Routes.SPECIFIC_TRAINING_LINK && (
                      <SpecificTrainingDeeplink
                        onSelectItem={onSelectItem}
                        selectId={formState?.LinkParams?.id}
                        isView={formMode === NotificationFormMode.VIEW}
                      />
                    )}

                    {isSpecificLink && errorState.mandatory.linkParams && (
                      <FormHelperText style={{ color: '#f018a6' }}>
                        {Translation('app.input.placeholder.please-select')}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={classes.section}>
              <div className={classes.divideMargin}>
                <span className={classes.subHeader}>{Translation('component.formLabel.receiver-setting')}</span>
              </div>
              {(formState.contentType === ContentType.FIXED || isPushOnly) && (
                <div className={classes.textAreaRowContainer}>
                  <div className={classes.checkboxFieldContainer}>
                    <span className={classes.field}>
                      {Translation('component.formLabel.receiver')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <FormControl error disabled={isDisabled}>
                    <RadioGroup
                      aria-label="receiverType"
                      name="receiverType"
                      value={formState.receiverType}
                      onChange={(e) => {
                        onDismissErrorHandler('receiverType', e.target.value);
                        onDismissErrorHandler('filters', e.target.value);

                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'receiverType', value: e.target.value },
                        });
                      }}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        style={{ marginBottom: 0 }}
                        key="all-agents"
                        value="GROUP"
                        control={<Radio />}
                        label={Translation(`publish.receivers.selected.allAgents`)}
                      />

                      <FormControlLabel
                        style={{ marginBottom: 0 }}
                        key="upload-agent-list"
                        value="TARGET"
                        control={<Radio />}
                        label={Translation(`publish.receivers.selected.uploadAgentList`)}
                      />
                    </RadioGroup>
                    <FormHelperText style={{ marginLeft: 14 }}>
                      {errorState.mandatory.receiverType && MANDATORY_FIELD_ERROR_TEXT}
                    </FormHelperText>
                  </FormControl>
                </div>
              )}
              {isSegment && (
                <>
                  <div style={{ marginLeft: 160, marginTop: 20 }}>
                    <TargetApplicantSection
                      onRef={segmentInstance}
                      isDisabled={isDisabled}
                      eligibleDesignations={formState.roleList || []}
                      eligibleZones={formState.zoneList || []}
                      eligibleAgents={[]}
                      excludedAgents={[]}
                      onToggleDesignation={onToggleDesignation}
                      onToggleZones={onToggleZones}
                      isNotificatoin={true}
                      historyAgent={formState.historyReceiverType}
                      isNew={formMode === NotificationFormMode.CREATE}
                    />
                  </div>

                  <div style={{ marginTop: 20, marginLeft: 160 }} className={classes.textAreaRowContainer}>
                    <div style={{ paddingTop: 5 }} className={classes.checkboxFieldContainer}>
                      <span className={classes.field}>{Translation(`receiver.include.text`)}:</span>
                    </div>
                    <div style={{ display: 'block' }}>
                      <div style={{ flexGrow: 1 }}>
                        <FileUploader
                          maxFileSize={10}
                          disabled={isDisabled}
                          upload={(params: any) => {
                            return uploadExcel({ ...params, inclusive: true });
                          }}
                          download={toDownloadUploadedFile}
                          value={
                            formState.filters &&
                            formState.filters.find(
                              (item) =>
                                item.inclusive === true &&
                                item.receiverType === formState.receiverType &&
                                item.contentType === formState.contentType,
                            )
                          }
                          // eslint-disable-next-line
                          showAllowText={isDisabled ? null : Translation(`receiver.include.upload.allow.text`)}
                          onChange={(data) => {
                            if (data) {
                              data.receiverType = formState.receiverType;
                              data.contentType = formState.contentType;
                              data.inclusive = true;
                            }
                            onFilesChanges(data, true);
                          }}
                        />
                      </div>
                      <p
                        style={{
                          marginLeft: 14,
                          marginTop: 16,
                          display: 'block',
                          fontSize: '0.75rem',
                          color: '#f018a6',
                        }}
                      >
                        {errorState.mandatory.filters && MANDATORY_FIELD_ERROR_TEXT}
                      </p>
                    </div>
                  </div>

                  <div style={{ marginTop: 10, marginLeft: 160 }} className={classes.textAreaRowContainer}>
                    <div style={{ paddingTop: 5 }} className={classes.checkboxFieldContainer}>
                      <span className={classes.field}>{Translation(`receiver.exclude.text`)}:</span>
                    </div>
                    <div style={{ display: 'block' }}>
                      <div style={{ flexGrow: 1 }}>
                        <FileUploader
                          maxFileSize={10}
                          disabled={isDisabled}
                          upload={(params: any) => {
                            return uploadExcel({ ...params, inclusive: false });
                          }}
                          download={toDownloadUploadedFile}
                          value={
                            formState.filters &&
                            formState.filters.find(
                              (item) =>
                                item.inclusive === false &&
                                item.receiverType === formState.receiverType &&
                                item.contentType === formState.contentType,
                            )
                          }
                          // eslint-disable-next-line
                          showAllowText={isDisabled ? null : Translation(`receiver.exclude.upload.allow.text`)}
                          onChange={(data) => {
                            if (data) {
                              data.receiverType = formState.receiverType;
                              data.contentType = formState.contentType;
                              data.inclusive = false;
                            }
                            onFilesChanges(data, false);
                          }}
                        />
                      </div>
                      <p
                        style={{
                          marginLeft: 14,
                          marginTop: 16,
                          display: 'block',
                          fontSize: '0.75rem',
                          color: '#f018a6',
                        }}
                      >
                        {errorState.mandatory.filters && MANDATORY_FIELD_ERROR_TEXT}
                      </p>
                    </div>
                  </div>

                  <div style={{ marginTop: 5, marginLeft: 150 }} className={classes.textAreaRowContainer}>
                    <div style={{ paddingTop: 5 }} className={classes.checkboxFieldContainer}>
                      <span className={classes.field}> </span>
                    </div>
                    <div style={{ display: 'block' }}>
                      {isDisabled ? null : (
                        <div style={{ flexGrow: 1 }}>
                          <Button color="secondary" onClick={toDownloadTemplate}>
                            {Translation('component.notification.downloadTemplate')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {isRenderUpload && (
                <div style={{ marginTop: 5 }} className={classes.textAreaRowContainer}>
                  <div style={{ paddingTop: 5 }} className={classes.checkboxFieldContainer}>
                    <span className={classes.field}>
                      {Translation('component.formLabel.agent-list')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <div style={{ display: 'block' }}>
                    <div style={{ flexGrow: 1 }}>
                      <FileUploader
                        maxFileSize={10}
                        disabled={isDisabled}
                        upload={(params: any) => {
                          return uploadExcel({ ...params, inclusive: true });
                        }}
                        download={toDownloadUploadedFile}
                        value={
                          formState.receivers ||
                          (formState.filters &&
                            formState.filters.find(
                              (item) =>
                                item.receiverType === formState.receiverType &&
                                item.contentType === formState.contentType,
                            ))
                        }
                        // eslint-disable-next-line
                        showAllowText={
                          isDisabled ? null : (
                            <Button
                              style={{ marginLeft: 16 }}
                              color="secondary"
                              onClick={
                                toDownloadTemplate
                              } /*href={`${DOWNLOAD_TEMPLATE_URL}?contentType=${formState.contentType}`}*/
                            >
                              {Translation('component.notification.downloadTemplate')}
                            </Button>
                          )
                        }
                        onChange={(data) => {
                          if (data) {
                            data.inclusive = true;
                            data.contentType = formState.contentType;
                            data.receiverType = formState.receiverType;
                          }
                          onFilesChanges(data, true);
                        }}
                      />
                    </div>
                    <p style={{ marginLeft: 8, marginTop: 8, display: 'block', fontSize: '0.75rem', color: '#f018a6' }}>
                      {errorState.immediate.template &&
                        Translation('component.notification.variablecontent.duplicated.agentCode')}
                    </p>
                    <p
                      style={{ marginLeft: 14, marginTop: 16, display: 'block', fontSize: '0.75rem', color: '#f018a6' }}
                    >
                      {errorState.mandatory.filters && MANDATORY_FIELD_ERROR_TEXT}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.section}>
              <div className={classes.divideMargin}>
                <span className={classes.subHeader}>{Translation('component.formLabel.sendingTime')}</span>
              </div>
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer}>
                  <span className={classes.field}>
                    {Translation('component.formLabel.triggertime')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <FormControl error disabled={isDisabled}>
                  <RadioGroup
                    aria-label="triggertime"
                    name="triggertime"
                    value={formState.triggertime}
                    onChange={(e) => {
                      onDismissErrorHandler('triggertime', e.target.value);
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'triggertime', value: e.target.value } });
                    }}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {Object.entries(TriggerTime).map(([label, value]) => (
                      <FormControlLabel
                        style={{ marginBottom: 0 }}
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={Translation(`component.formSelectItem.${label}`)}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText style={{ marginLeft: 14 }}>
                    {errorState.mandatory.triggertime && MANDATORY_FIELD_ERROR_TEXT}
                  </FormHelperText>
                </FormControl>
              </div>
              {formState.triggertime === TriggerTime.specificTime ? (
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('component.formLabel.modifyTime')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <PruDateTimePicker
                    minutesStep={5}
                    slotProps={{
                      textField: {
                        error: errorState.mandatory.sendingTime,
                        helperText:
                          errorState.mandatory.sendingTime && Translation('app.input.placeholder.please-select'),
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <AlarmIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    ampm={false}
                    disabled={isDisabled}
                    format="DD/MM/YYYY HH:mm"
                    value={formState.sendingTime || null}
                    onChange={(date) => {
                      onDismissErrorHandler('sendingTime', date);
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'sendingTime', value: date } });
                    }}
                  />
                </div>
              ) : null}
            </div>
            {isDisabled ? (
              <div className={classes.section}>
                <div className={classes.divideMargin}>
                  <span className={classes.subHeader}>
                    {Translation('component.notificationForm.notification-log')}
                  </span>
                </div>
                <div>
                  {!isPushOnly ? (
                    <Button color="secondary" onClick={handleDownloadLog}>
                      {Translation('component.notificationForm.download-template-log')}
                    </Button>
                  ) : (
                    <i className={classes.notificationMessage}>
                      {Translation('component.message.no-download-log-file.notification-details')}
                    </i>
                  )}
                </div>
              </div>
            ) : null}

            <div className={classes.footerContainer}>
              {!isDisabled ? (
                <>
                  {/* todo: concel warling */}
                  {/* <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => onCancel()}
                  >
                    { Translation('app.button.cancel') }
                  </Button> */}
                  <ButtonDialog
                    onOk={onCancel}
                    dialogTxt={Translation('component.hint.cancel-warning')}
                    btnTxt={Translation('app.button.cancel')}
                  />
                  <Button
                    disabled={isSubmitting}
                    style={{ marginLeft: 25 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => onSubmitForm()}
                  >
                    {Translation('app.button.submit')}
                    {isSubmitting && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default NotificationDetailForm;
