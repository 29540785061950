import React, { FC, Fragment, useEffect, useReducer, useRef, useState } from 'react';
import {
  NewsArticleEditItem,
  NewsArticleItem,
  NewsArticleFormMode,
  NewsCategoryItem,
  NewsCategoryPaginateList,
  NewsImageItem,
  NewsTitleItem,
  NewsAttachmentItem,
  NewsContentItem,
  NewsContentWordcountItem,
  NewsArticleStatus,
  EnrolmentType,
  newsType,
} from '../../../../types/news-type';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  LinearProgress,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  Dialog,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { NEWS_BASE_PATH, NEWS_ARTICLE_PATH, AdminNewsPermissionType } from '../../../../constants';
import { useIntl } from 'react-intl';
import { RegionLocale, regionLocale, useServerLocale, getAvailableServerLocale, regionLocaleMap } from 'src/app/i18n';
import {
  ErrorFieldType,
  useErrorHandler,
  useDataProvider,
  useJWT,
  getDefaultDisplayDate,
  preFillZeroList,
  isPermitted,
  fileUpload,
} from 'src/app/common/utils';
import { PaginateList, AgentSegmentItem } from 'src/app/common/types';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { AlertType, appendAlertItem } from '../../../../../../../redux/common/commonSlice';
import { createBlob, getBlob, fetchAgentSegmentList } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import {
  createAndApproveNewNewsArticle,
  createNewNewsArticle,
  fetchNewsCategoryList,
  modifyAndApproveNewsArticle,
  modifyNewsArticle,
} from '../../../../network/newsCrud';
import { map } from 'lodash';
import AddTags from './AddTag';
import Tinymce from './Tinymce';
import moment from 'moment';
import DisplayAttachmentsTable from '../components/DisplayAttachmentsTable';
import TargetApplicationSection from './TargetApplicantSection';
import TargetApplicationSection_MO_BROKER from './TargetApplicantSection_MO_BROKER';
import { generateContentPlainText, getImageSizeFromUrl } from '../../../../utils/news';

type NewsDetailProps = {
  code?: string;
  formMode: NewsArticleFormMode;
  newsArticle?: NewsArticleItem;
  lastFileName: Pick<NewsArticleItem, 'agentListFileName'>;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  newsText: {
    lineHeight: 1.8,
  },
  imgRow: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  imgItem: {
    margin: 10,
    maxWidth: 500,
  },
  tag: {
    padding: '8px 10px',
    fontWeight: 600,
    fontSize: 12,
  },
  inputMargin: {
    marginRight: 20,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  settingTable: {
    width: '100%',
  },
  checkboxNoArchiveContainer: {
    margin: 0,
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textAreaRowContainer: {
    display: 'flex',
  },
  dialogContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogTitle: {
    fontSize: 20,
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}));

export type NewsArticleFormState = {
  code?: string;
  contentWordCount: NewsContentWordcountItem;
  content: NewsContentItem;
  title: NewsTitleItem;
  categoryId: string;
  archiveDate: Date | null;
  noArchiveDate: boolean;
  publishDate: Date | null;
  bannerFileId?: string;
  bannerFileName?: string;
  bannerFileUrl?: string;
  banner?: NewsImageItem;
  bannerFileType?: string;
  thumbnailFileId?: string;
  thumbnailFileName?: string;
  thumbnailFileUrl?: string;
  thumbnail?: NewsImageItem;
  tags?: string[];
  isSuggested: boolean;
  enquiryEmail?: string;
  attachments?: NewsAttachmentItem[];
  enrolmentType: EnrolmentType;
  eligibleDesignations?: string[];
  eligibleAgents?: string[];
  excludedAgents?: string[];
  agentListFileName?: string;
  newsType: newsType;
  segments: string[];
  segmentNames: Record<string, string>[];
  isHeadline: boolean;
};

const initialState: NewsArticleFormState = {
  code: undefined,
  title: {},
  content: {},
  contentWordCount: {},
  categoryId: '',
  archiveDate: null,
  noArchiveDate: false,
  publishDate: null,
  banner: { blobId: '' },
  bannerFileId: '',
  bannerFileName: '',
  bannerFileUrl: '',
  bannerFileType: 'Image',
  thumbnail: { blobId: '' },
  thumbnailFileId: '',
  thumbnailFileName: '',
  thumbnailFileUrl: '',
  tags: [],
  isSuggested: false,
  enquiryEmail: undefined,
  attachments: [],
  eligibleDesignations: undefined,
  eligibleAgents: undefined,
  excludedAgents: undefined,
  enrolmentType: EnrolmentType.BY_SEGMENT,
  agentListFileName: undefined,
  newsType: newsType.GENERAL,
  segments: [],
  segmentNames: [],
  isHeadline: false,
};

const detailToStateConvertor = (detail: NewsArticleItem): NewsArticleFormState => {
  const eligibleDesignations =
    detail.eligibleSegments && detail.eligibleSegments.designation ? detail.eligibleSegments.designation : [];
  return {
    content: detail.content || {},
    title: detail.title || {},
    contentWordCount: detail.wordCount || {},
    categoryId: detail.categoryId,
    archiveDate: detail.archiveDate || null,
    noArchiveDate: !!detail.noArchiveDate,
    publishDate: detail.publishDate || null,
    banner: detail.banner,
    thumbnail: detail.thumbnail,
    tags: detail.tags,
    isSuggested: detail.isSuggested ? detail.isSuggested : false,
    enquiryEmail: detail.enquiryEmail || undefined,
    attachments: detail.attachments || [],
    eligibleDesignations,
    eligibleAgents: detail.eligibleAgents,
    excludedAgents: detail.excludedAgents,
    enrolmentType: detail.enrolmentType,
    agentListFileName: detail.agentListFileName,
    newsType: detail.newsType,
    segments: detail.segments || [],
    segmentNames: detail.segmentNames || [],
    isHeadline: detail.isHeadline || false,
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof NewsArticleFormState;
    value: any;
  };
};

type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<NewsArticleFormState, 'title' | 'content' | 'contentWordCount'>;
    locale: string;
    value: any;
  };
};

type UploadBannerImageAction = {
  type: 'UPLOAD_BANNER_IMAGE';
  payload: {
    id: string;
    fileUrl: string;
    fileType: string;
  };
};

type UploadThumbnailImageAction = {
  type: 'UPLOAD_THUMBNAIL_IMAGE';
  payload: {
    id: string;
    fileUrl: string;
  };
};

type AddAttachmentRowAction = {
  type: 'ADD_ATTACHMENT_ROW';
};

type ModifyAttachmentRowAction = {
  type: 'MODIFY_ATTACHMENT_ROW';
  payload: {
    index: number;
    field: keyof NewsAttachmentItem;
    value: string;
  };
};

type DeleteAttachmentRowAction = {
  type: 'DELETE_ATTACHMENT_ROW';
  payload: {
    index: number;
  };
};

type ToggleDesignationAction = {
  type: 'TOGGLE_DESIGNATION';
  payload: {
    value: string;
  };
};

type SegmentCheckAction = {
  type: 'SEGMENT_CHECK';
  payload: {
    value: string;
  };
};

type NewsArticleFormAction =
  | ModifyFieldAction
  | ModifyLocaleFieldAction
  | UploadBannerImageAction
  | UploadThumbnailImageAction
  | AddAttachmentRowAction
  | ModifyAttachmentRowAction
  | DeleteAttachmentRowAction
  | ToggleDesignationAction
  | SegmentCheckAction;

const newsArticleFormReducer = (state: NewsArticleFormState, action: NewsArticleFormAction): NewsArticleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };
    case 'UPLOAD_BANNER_IMAGE':
      return {
        ...state,
        bannerFileUrl: action.payload.fileUrl,
        bannerFileType: action.payload.fileType,
      };

    case 'UPLOAD_THUMBNAIL_IMAGE':
      return {
        ...state,
        thumbnailFileUrl: action.payload.fileUrl,
      };

    case 'ADD_ATTACHMENT_ROW':
      return {
        ...state,
        attachments: state.attachments
          ? [...state.attachments, { filename: '', url: '', blobId: '' }]
          : [{ filename: '', url: '', blobId: '' }],
      };
    case 'MODIFY_ATTACHMENT_ROW':
      if (state.attachments && state.attachments[action.payload.index]) {
        let item = state.attachments[action.payload.index];
        item[action.payload.field] = action.payload.value;
      }
      return { ...state };
    case 'DELETE_ATTACHMENT_ROW':
      if (state.attachments && state.attachments[action.payload.index]) {
        let attachmentsArr = state.attachments;
        attachmentsArr.splice(action.payload.index, 1);
      }
      return { ...state };

    case 'TOGGLE_DESIGNATION':
      let newDesignation = [...(state.eligibleDesignations || [])];
      let itemIndex = newDesignation.findIndex((designation) => designation === action.payload.value);
      newDesignation[itemIndex] ? newDesignation.splice(itemIndex, 1) : newDesignation.push(action.payload.value);
      return {
        ...state,
        eligibleDesignations: newDesignation,
      };
    case 'SEGMENT_CHECK': {
      let newSegments = [...(state.segments || [])];
      let itemIndex = newSegments.findIndex((designation) => designation === action.payload.value);
      newSegments[itemIndex] ? newSegments.splice(itemIndex, 1) : newSegments.push(action.payload.value);
      return {
        ...state,
        segments: newSegments,
      };
    }
  }
};

function isValidDate(date: string | undefined, ignoreNull?: boolean) {
  if (ignoreNull) {
    return date !== 'Invalid Date';
  }
  return !!(date && date !== 'Invalid Date');
}

const categoryListParam = {
  limit: 5,
  page: 1,
};

const segmentListParam = {
  limit: 5,
  page: 1,
};

const NewsDetail: FC<NewsDetailProps> = ({ newsArticle, code, formMode, lastFileName, onReload, onRouteTo }) => {
  const mainLocale = regionLocale[0] || RegionLocale.ENGLISH;
  const locale = useServerLocale();
  const availableLocales = getAvailableServerLocale();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const [formState, formDispatch] = useReducer(
    newsArticleFormReducer,
    newsArticle ? detailToStateConvertor(newsArticle) : initialState,
  );
  const [uploadBannerProgress, setUploadBannerProgress] = useState<number>();
  // eslint-disable-next-line
  const [uploadThumbnailProgress, setUploadThumbnailProgress] = useState<number>();
  const [categoryList, setCategoryList] = useState<NewsCategoryPaginateList>();
  const [agentSegmentList, setAgentSegmentList] = useState<PaginateList<AgentSegmentItem>>();
  const [saveToDraftWarningDialog, setSaveToDraftWarningDialog] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<NewsCategoryItem | undefined>(undefined);
  const [noArchiveDateDialog, setNoArchiveDateDialog] = useState<boolean>(false);
  const [agentSegmentGroup, setAgentSegmentGroup] = useState<string[]>(formState.segments);

  const initializedDate = useRef<boolean>(false);

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    message: '',
  });

  const isDisabled = false;
  const shareLink: string = `${window.envConfig['REACT_APP_DEEPLINK_SCHEME']}://prunewsdetail?newsId=${
    code ? code : ''
  }`;

  let approverPermission = false;
  let archivePermission = false;
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    approverPermission = isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_APPROVE], jwtPermission);
    archivePermission = isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_ARCHIVE], jwtPermission);
  }

  const modifyTargetApplicant = (
    option: keyof Pick<
      NewsArticleFormState,
      'enrolmentType' | 'eligibleAgents' | 'eligibleDesignations' | 'excludedAgents' | 'agentListFileName'
    >,
    value: any,
  ) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: option, value } });
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      ...availableLocales.map((locale) => ({
        name: `title${locale}`,
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          let flag = false;
          if (!formState.title || !formState.title[locale]) {
            flag = true;
          }
          return flag;
        },
      })),
      {
        name: 'categoryId',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'archiveDateBeforePublishDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.publishDate && formState.archiveDate) {
            return !!moment(new Date(formState.publishDate)).isAfter(moment(new Date(formState.archiveDate)));
          } else {
            return false;
          }
        },
      },
      {
        name: 'archiveDateOutOfRange',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.publishDate && formState.archiveDate && selectedCategory?.maxArchiveDays !== undefined) {
            return !!moment(new Date(formState.archiveDate)).isAfter(
              moment(new Date(formState.publishDate)).add(selectedCategory.maxArchiveDays, 'days'),
            );
          } else {
            return false;
          }
        },
      },
      {
        name: 'bannerFileName',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'thumbnailFileName',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (
            formState.bannerFileType &&
            formState.bannerFileType?.indexOf('video') > -1 &&
            (!formState.thumbnailFileName || formState.thumbnailFileName === '')
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        name: 'enquiryEmail',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'emailFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          // if (formState.enquiryEmail) {
          //   let re;
          //   let channelConfig = localStorage.getItem('channelConfig');
          //   console.log('===',localStorage);
          //   if (channelConfig) {
          //     const emailDomainChecking = JSON.parse(channelConfig)?.emailDomain || '';
          //     re = new RegExp(
          //       `^([a-zA-Z0-9.!#$%&\'*+/=?^_\`{|}~-]+@${emailDomainChecking})+(\s*[,]\s*([a-zA-Z0-9.!#$%&\'*+/=?^_\`{|}~-]+@${emailDomainChecking}))*$`,
          //     );
          //   } else {
          //     // re = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@prudential.com.hk)+(\s*[,]\s*((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@prudential.com.hk))*$/;
          //     re = new RegExp(
          //       /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@prudential.com.hk)+(\s*[,]\s*((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@prudential.com.hk))*$/,
          //     );
          //   }
          //   return !re.test(String(formState.enquiryEmail).toLowerCase());
          // } else {
          //   return false;
          // }
          if (formState.enquiryEmail && !formState.enquiryEmail.includes('@prudential.com')) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        name: 'designation',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (
            formState.newsType == newsType.GENERAL &&
            (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
            (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
            (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
            (!formState.agentListFileName || formState.agentListFileName == '')
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        name: 'segments',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.newsType == newsType.SEGMENTED && (!formState.segments || formState.segments?.length == 0)) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        name: 'publishDate',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          return !isValidDate(formState.publishDate?.toString());
        },
      },
      {
        name: 'publishDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          return !isValidDate(formState.publishDate?.toString(), true);
        },
      },
      {
        name: 'archiveDate',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.noArchiveDate) return false;
          return !isValidDate(formState.archiveDate?.toString());
        },
      },
      {
        name: 'archiveDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          return !isValidDate(formState.archiveDate?.toString(), true);
        },
      },
    ],
  );
  useEffect(() => {
    immediateErrorValidator();
    // eslint-disable-next-line
  }, [
    formState.publishDate,
    formState.archiveDate,
    formState.enquiryEmail,
    formState.bannerFileType,
    formState.thumbnailFileName,
  ]);

  useEffect(() => {
    onDismissErrorHandler(
      'designation',
      !(
        formState.newsType == newsType.GENERAL &&
        (!formState.eligibleDesignations || formState.eligibleDesignations?.length == 0) &&
        (!formState.eligibleAgents || formState.eligibleAgents?.length == 0) &&
        (!formState.excludedAgents || formState.excludedAgents?.length == 0) &&
        (!formState.agentListFileName || formState.agentListFileName == '')
      ),
    );
    // eslint-disable-next-line
  }, [formState.eligibleDesignations, formState.eligibleAgents, formState.excludedAgents, formState.agentListFileName]);

  useEffect(() => {
    onDismissErrorHandler(
      'segments',
      !(formState.newsType == newsType.SEGMENTED && (!formState.segments || formState.segments?.length == 0)),
    );
    // eslint-disable-next-line
  }, [formState.segments]);

  useDataProvider<NewsCategoryPaginateList>(
    async () => {
      try {
        return await fetchNewsCategoryList(categoryListParam, dispatch);
      } catch (err) {}
    },
    setCategoryList,
    false,
  );

  useDataProvider<PaginateList<AgentSegmentItem>>(
    async () => {
      try {
        return await fetchAgentSegmentList({ enableInPruNews: true, pagination: false }, dispatch);
      } catch (err) {}
    },
    setAgentSegmentList,
    false,
  );

  useEffect(() => {
    let category: NewsCategoryItem | undefined = undefined;
    if (formState.categoryId && categoryList) {
      category = categoryList.categories.find((val) => val._id === formState.categoryId);
    }
    setSelectedCategory(category);
  }, [categoryList, formState.categoryId]);

  useEffect(() => {
    //TODO: auto-fill archive date when category or publishDate changed
    if (
      !formState.noArchiveDate &&
      formState.publishDate &&
      isValidDate(formState.publishDate.toString()) &&
      selectedCategory &&
      selectedCategory.maxArchiveDays
    ) {
      if (!initializedDate.current && formMode === NewsArticleFormMode.EDIT) {
        initializedDate.current = true;
        return;
      }
      formDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          field: 'archiveDate',
          value: moment(formState.publishDate).add(selectedCategory.maxArchiveDays, 'days'),
        },
      });
      onDismissErrorHandler('archiveDate', true);
    }
  }, [formState.noArchiveDate, selectedCategory, formState.publishDate, formMode]);

  const handleBannerFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      const fileType = file.type;
      e.target.value = '';
      const errMsgImage = TranslationWithVariable('global.max.file.size.allow', 5) || '';
      const errMsgVideo = TranslationWithVariable('global.max.file.size.allow', 300) || '';
      if (fileType.indexOf('image') > -1 && fileSize > 5) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsgImage,
            },
          ]),
        );
        return;
      } else if (fileType.indexOf('video') > -1 && fileSize > 300) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsgVideo,
            },
          ]),
        );
        return;
      }
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'prunews' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadBannerProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          onDismissErrorHandler('bannerFileName', file.name);

          formDispatch({
            type: 'UPLOAD_BANNER_IMAGE',
            payload: {
              id: result.blobId,
              fileUrl: result.url,
              fileType: result.mimeType,
            },
          });
        }

        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bannerFileId', value: createBlobRes.blobId } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bannerFileName', value: file.name } });

        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: Translation('newsArticle.success.uploadBanner', {
                name: file.name,
              }),
            },
          ]),
        );
      } catch (err) {
      } finally {
        setUploadBannerProgress(undefined);
      }
    }
  };
  const removeBannerFile = () => {
    formDispatch({
      type: 'UPLOAD_BANNER_IMAGE',
      payload: {
        id: '',
        fileUrl: '',
        fileType: 'Image',
      },
    });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bannerFileId', value: '' } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bannerFileName', value: '' } });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove banner file successfully`,
        },
      ]),
    );
  };

  // eslint-disable-next-line
  const removeThumbnailFile = () => {
    formDispatch({
      type: 'UPLOAD_THUMBNAIL_IMAGE',
      payload: {
        id: '',
        fileUrl: '',
      },
    });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileId', value: '' } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileName', value: '' } });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove thumbnail file successfully`,
        },
      ]),
    );
  };

  // eslint-disable-next-line
  const handleThumbnailFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024;
      const errMsg = TranslationWithVariable('global.max.file.size.allow', '') || '';
      e.target.value = '';
      //TODO: check the file size, must less than 300BK
      if (fileSize > 300) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }

      try {
        //TODO: check the resolution, must greater than 100*100
        const dimensions = await getImageSizeFromUrl(URL.createObjectURL(file));
        if (!dimensions || dimensions.height < 100 || dimensions.width < 100) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: '',
                content: Translation('global.max.file.resolution.allow'),
              },
            ]),
          );
          return;
        }
      } catch {}

      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'prunews' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadThumbnailProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          onDismissErrorHandler('thumbnailFileName', true);
          formDispatch({
            type: 'UPLOAD_THUMBNAIL_IMAGE',
            payload: {
              id: result.blobId,
              fileUrl: result.url,
            },
          });
        }
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileId', value: createBlobRes.blobId } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileName', value: file.name } });
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: Translation('newsArticle.success.uploadBanner', {
                name: file.name,
              }),
            },
          ]),
        );
      } catch (err) {
      } finally {
        setUploadThumbnailProgress(undefined);
      }
    }
  };

  useEffect(() => {
    async function fetchBannerImage() {
      if (formState.banner?.blobId) {
        const blobDetail = await getBlob({ resourceIds: formState.banner.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'UPLOAD_BANNER_IMAGE',
            payload: {
              id: result.blobId,
              fileUrl: result.url,
              fileType: result.mimeType,
            },
          });
          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bannerFileId', value: result.blobId } });
          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'bannerFileName', value: result.blobName } });
          if (result.mimeType.indexOf('video') < 0) {
            formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileId', value: '' } });
            formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileName', value: '' } });
          }
          onDismissErrorHandler('bannerFileName', result.blobName);
          onDismissErrorHandler('thumbnailFileName', result.blobName);
        }
      }
    }
    async function fetchThumbnailImage() {
      if (formState.thumbnail?.blobId) {
        const blobDetail = await getBlob({ resourceIds: formState.thumbnail.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'UPLOAD_THUMBNAIL_IMAGE',
            payload: {
              id: result.blobId,
              fileUrl: result.url,
            },
          });
          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileId', value: result.blobId } });
          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'thumbnailFileName', value: result.blobName } });
        }
      }
    }
    fetchBannerImage();
    fetchThumbnailImage();

    // eslint-disable-next-line
  }, []);

  const onConfirmNoArchiveDateClick = () => {
    setNoArchiveDateDialog(false);
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { field: 'noArchiveDate', value: true },
    });
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { field: 'archiveDate', value: null },
    });
    onDismissErrorHandler('archiveDate', true);
  };

  const onSubmitForm = async (submitStatus: string) => {
    setSaveToDraftWarningDialog('');
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      // Call Api and submit
      const details: NewsArticleEditItem = {
        thumbnail: formState.thumbnailFileId ? { blobId: formState.thumbnailFileId } : undefined,
        banner: formState.bannerFileId
          ? {
              blobId: formState.bannerFileId,
              type: formState.bannerFileType && formState.bannerFileType.indexOf('video') > -1 ? 'video' : 'image',
            }
          : undefined,
        categoryId: formState.categoryId,
        title: formState.title || {},
        content: generateContentPlainText(formState.content),
        wordCount: formState.contentWordCount || {},
        isSuggested: formState.isSuggested,
        publishDate: formState.publishDate ? formState.publishDate : undefined,
        archiveDate: formState.archiveDate ? formState.archiveDate : null,
        noArchiveDate: formState.noArchiveDate,
        tags: formState.tags,
        enquiryEmail: formState.enquiryEmail,
        attachments: formState.attachments && formState.attachments.length >= 1 ? formState.attachments : undefined,
        status: submitStatus,
        enrolmentType: formState.enrolmentType,
        eligibleSegments: {
          designation: formState.eligibleDesignations || [],
        },
        eligibleAgents: formState.eligibleAgents ? preFillZeroList(formState.eligibleAgents) : [],
        excludedAgents: formState.excludedAgents ? preFillZeroList(formState.excludedAgents) : [],
        agentListFileName: formState.agentListFileName || '',
        newsType: formState.newsType,
        segments: formState.segments,
        segmentNames: [],
        isHeadline: formState.isHeadline,
      };

      try {
        if (formMode === NewsArticleFormMode.CREATE) {
          await createNewNewsArticle(details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('newsArticle.success.saved'),
              },
            ]),
          );
        } else if (code && formMode === NewsArticleFormMode.EDIT) {
          await modifyNewsArticle(code, details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('newsArticle.success.updated', { code }),
              },
            ]),
          );
        }
        onRouteTo(`${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}`);
      } catch (err) {}
    }
  };

  const onApproveAndSubmitForm = async (submitStatus: string) => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      // Call Api and submit
      const details: NewsArticleEditItem = {
        thumbnail: formState.thumbnailFileId ? { blobId: formState.thumbnailFileId } : undefined,
        banner: formState.bannerFileId
          ? {
              blobId: formState.bannerFileId,
              type: formState.bannerFileType && formState.bannerFileType.indexOf('video') > -1 ? 'video' : 'image',
            }
          : undefined,
        categoryId: formState.categoryId,
        title: formState.title || {},
        content: generateContentPlainText(formState.content),
        wordCount: formState.contentWordCount || {},
        isSuggested: formState.isSuggested,
        publishDate: formState.publishDate ? formState.publishDate : undefined,
        archiveDate: formState.archiveDate ? formState.archiveDate : null,
        noArchiveDate: formState.noArchiveDate,
        tags: formState.tags,
        enquiryEmail: formState.enquiryEmail,
        attachments: formState.attachments && formState.attachments.length >= 1 ? formState.attachments : undefined,
        status: submitStatus,
        enrolmentType: formState.enrolmentType,
        eligibleSegments: {
          designation: formState.eligibleDesignations || [],
        },
        eligibleAgents: formState.eligibleAgents ? preFillZeroList(formState.eligibleAgents) : [],
        excludedAgents: formState.excludedAgents ? preFillZeroList(formState.excludedAgents) : [],
        agentListFileName: formState.agentListFileName || '',
        newsType: formState.newsType,
        segments: formState.segments,
        segmentNames: [],
        isHeadline: formState.isHeadline,
      };

      try {
        if (formMode === NewsArticleFormMode.CREATE) {
          await createAndApproveNewNewsArticle(details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('newsArticle.success.saved'),
              },
            ]),
          );
        } else if (code && formMode === NewsArticleFormMode.EDIT) {
          await modifyAndApproveNewsArticle(code, details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: Translation('newsArticle.success.updated', { code }),
              },
            ]),
          );
        }

        onRouteTo(`${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}`);
      } catch (err) {}
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {formMode === NewsArticleFormMode.CREATE
              ? Translation('newsArticle.common.newNewsArticle')
              : Translation('newsArticle.edit')}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            history.push(`${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}`);
          }}
        >
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}></div>
        {formMode === NewsArticleFormMode.EDIT ? (
          <Fragment>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.updatedAt')}:</span>
              </div>
              <div>
                <span className={classes.field}>
                  {' '}
                  {newsArticle?.updatedAt ? getDefaultDisplayDate(new Date(newsArticle.updatedAt), 'datetime') : ''}
                </span>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.updatedBy')}:</span>
              </div>
              <div>
                <span className={classes.field}>{newsArticle?.updatedBy}</span>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.createdBy')}:</span>
              </div>
              <div>
                <span className={classes.field}>{newsArticle?.createdBy ? newsArticle?.createdBy : 'N/A'}</span>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.likes')}:</span>
              </div>
              <div>
                <span className={classes.field}>{newsArticle?.likes}</span>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.bookmarked')}:</span>
              </div>
              <div>
                <span className={classes.field}>{newsArticle?.bookmarked}</span>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.comments')}:</span>
              </div>
              <div>
                <span className={classes.field}>{newsArticle?.comments}</span>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('newsArticle.common.shareLink')}:</span>
              </div>

              <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ flexGrow: 0.7, marginRight: '10px' }}>
                  <TextField disabled={true} margin="dense" variant="outlined" fullWidth value={shareLink} />
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    var textField = document.createElement('textarea');
                    textField.innerText = shareLink;
                    document.body.appendChild(textField);
                    textField.select();
                    document.execCommand('copy');
                    textField.remove();
                  }}
                >
                  {Translation('newsArticle.common.copyLink')}
                </Button>
              </div>
            </div>
          </Fragment>
        ) : null}

        {availableLocales.map((locale) => (
          <div key={locale} className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation(`newsArticle.common.title.${locale}`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                error={errorState.mandatory[`title${locale}`]}
                margin="dense"
                variant="outlined"
                fullWidth
                helperText={errorState.mandatory[`title${locale}`] && Translation('field_mandatory_helper_label')}
                value={formState.title[locale]}
                onChange={(e) => {
                  onDismissErrorHandler(`title${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_LOCALE_FIELD',
                    payload: { field: 'title', locale, value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        ))}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsArticle.common.enquiryEmail')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              error={errorState.mandatory.enquiryEmail || errorState.immediate.emailFormat}
              margin="dense"
              variant="outlined"
              fullWidth
              helperText={
                errorState.mandatory.enquiryEmail
                  ? Translation('field_mandatory_helper_label')
                  : errorState.immediate.emailFormat && Translation('newsArticle.error.invalidPrudentialEmailFormat')
              }
              value={formState.enquiryEmail}
              onChange={(e) => {
                onDismissErrorHandler('enquiryEmail', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enquiryEmail', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsArticle.common.category')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl margin="dense" variant="outlined">
            <Select
              native
              error={errorState.mandatory.categoryId}
              value={formState.categoryId}
              style={{ width: 200, marginRight: 20 }}
              onChange={(e) => {
                onDismissErrorHandler('categoryId', e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'categoryId', value: e.target.value as string },
                });
              }}
            >
              <option aria-label="None" value="" />
              {map(categoryList?.categories, (option: NewsCategoryItem) => (
                <option key={option._id} value={option._id}>
                  {option.name[locale]}
                </option>
              ))}
            </Select>
            {errorState.mandatory.categoryId && (
              <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
            )}
          </FormControl>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsArticle.common.publishDate')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <PruDateTimePicker
            disabled={isDisabled}
            slotProps={{
              textField: {
                error:
                  errorState.immediate.archiveDateBeforePublishDate ||
                  errorState.immediate.publishDate ||
                  errorState.mandatory.publishDate,
                helperText:
                  (errorState.immediate.publishDate && Translation('newsArticle.error.invalidDateFormat')) ||
                  (errorState.immediate.archiveDateBeforePublishDate &&
                    Translation('newsArticle.error.archiveDateBeforePublishDate')) ||
                  (errorState.mandatory.publishDate && Translation('field_mandatory_helper_label')),
              },
            }}
            ampm={false}
            format="DD/MM/YYYY HH:mm"
            value={formState.publishDate}
            onChange={(date) => {
              if (errorState.mandatory.publishDate) {
                onDismissErrorHandler('publishDate', true);
              }
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'publishDate', value: date } });
            }}
          />
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsArticle.common.archiveDate')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <PruDateTimePicker
            disabled={isDisabled || formState.noArchiveDate}
            slotProps={{
              textField: {
                error:
                  errorState.immediate.archiveDateOutOfRange ||
                  errorState.immediate.archiveDateBeforePublishDate ||
                  errorState.mandatory.archiveDate ||
                  errorState.immediate.archiveDate,
                helperText:
                  (errorState.immediate.archiveDate && Translation('newsArticle.error.invalidDateFormat')) ||
                  (errorState.immediate.archiveDateBeforePublishDate &&
                    Translation('newsArticle.error.archiveDateBeforePublishDate')) ||
                  (errorState.immediate.archiveDateOutOfRange && Translation('newsArticle.error.archiveDateExceeds')) ||
                  (errorState.mandatory.archiveDate && Translation('field_mandatory_helper_label')),
              },
            }}
            ampm={false}
            format="DD/MM/YYYY HH:mm"
            value={formState.archiveDate}
            onChange={(date) => {
              if (errorState.mandatory.archiveDate) {
                onDismissErrorHandler('archiveDate', true);
              }
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'archiveDate', value: date } });
            }}
          />
          <FormControlLabel
            disabled={!archivePermission}
            className={classes.checkboxNoArchiveContainer}
            control={
              <Checkbox
                checked={formState.noArchiveDate}
                onChange={(e) => {
                  if (e.target.checked) {
                    setNoArchiveDateDialog(true);
                  } else {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'noArchiveDate', value: e.target.checked },
                    });
                  }
                }}
              />
            }
            label={Translation('newsArticle.common.noArchiveDate')}
          />
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 10, marginBottom: 10 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('newsArticle.common.tags')} :</span>
          </div>
          <AddTags
            disabled={false}
            btnTxt={Translation('component.tag-list.add-btn')}
            onChange={(value) => {
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'tags', value: value } });
            }}
            value={formState.tags}
            // sameTag={Translation('components.addtag.sametag')}
          />
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer} style={{ alignSelf: 'flex-start', paddingTop: '8px' }}>
            <span className={classes.field}>
              {Translation('newsArticle.common.bannerImage')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-banner"
              hidden
              type="file"
              accept="image/jpg, image/jpeg, image/png, video/mp4"
              // disabled={!!uploadBannerProgress}
              onChange={handleBannerFile}
            />
            <div className={classes.rowContainer}>
              <Button
                style={{ border: errorState.mandatory.bannerFileName ? `1px solid #f018a6` : `0px` }}
                disabled={isDisabled}
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-banner')!.click()}
              >
                {Translation('newsArticle.common.chooseFile')}
              </Button>

              {formState.bannerFileUrl ? (
                <Button
                  style={{ marginLeft: 20 }}
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeBannerFile()}
                >
                  {Translation('newsArticle.common.removeFile')}
                </Button>
              ) : null}
            </div>
            {errorState.mandatory.bannerFileName && (
              <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
            )}
            <div>{Translation('component.hint.news-recommend-banner-format')}</div>

            {!!uploadBannerProgress && (
              <LinearProgress style={{ marginTop: 10 }} variant="determinate" value={uploadBannerProgress} />
            )}
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div>{formState.bannerFileName ? formState.bannerFileName : ''}</div>
            </div>
            <div className={classes.rowContainer}>
              {formState.bannerFileUrl && (
                <div>
                  {formState.bannerFileType && formState.bannerFileType.indexOf('video') > -1 ? (
                    <video style={{ width: 'auto', height: '150px' }} controls>
                      <source src={formState.bannerFileUrl} type={formState.bannerFileType} />
                    </video>
                  ) : (
                    <img style={{ width: 'auto', height: '120px' }} src={formState.bannerFileUrl} alt="Banner" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer} style={{ alignSelf: 'flex-start', paddingTop: '8px' }}>
            <div>
              <span className={classes.field}>{Translation('newsArticle.common.thumbnailImage')} :</span>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-thumbnail"
              hidden
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              // disabled={!!uploadThumbnailProgress}
              onChange={handleThumbnailFile}
            />
            <div className={classes.rowContainer}>
              <Button
                disabled={isDisabled}
                style={{ border: errorState.immediate.thumbnailFileName ? `1px solid #f018a6` : `0px` }}
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-thumbnail')!.click()}
              >
                {Translation('newsArticle.common.chooseFile')}
              </Button>
              {formState.thumbnailFileUrl ? (
                <Button
                  style={{ marginLeft: 20 }}
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeThumbnailFile()}
                >
                  {Translation('newsArticle.common.removeFile')}
                </Button>
              ) : null}
            </div>
            {errorState.immediate.thumbnailFileName && (
              <span className={classes.errorText}>
                {Translation('component.hint.news-thumbnail-required-only-for-video')}
              </span>
            )}
            <div>{Translation('component.hint.news-recommend-thumbnail-format1')}</div>
            <div>{Translation('component.hint.news-recommend-thumbnail-format2')}</div>
            {!!uploadThumbnailProgress && (
              <LinearProgress style={{ marginTop: 10 }} variant="determinate" value={uploadThumbnailProgress} />
            )}
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div>{formState.thumbnailFileName ? formState.thumbnailFileName : ''}</div>
            </div>
            <div className={classes.rowContainer}>
              {formState.thumbnailFileUrl && (
                <div>
                  <img
                    style={{ width: 'auto', height: '80px', marginBottom: '20px' }}
                    src={formState.thumbnailFileUrl}
                    alt="Thumbnail"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {availableLocales.map((locale, index) => (
          <div key={locale} className={classes.rowContainer} style={{ marginTop: index !== 0 ? 20 : 0 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation(`newsArticle.common.content.${locale}`)} :</span>
            </div>
            <div style={{ width: '75%' }}>
              {index > 0 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          formDispatch({
                            type: 'MODIFY_LOCALE_FIELD',
                            payload: {
                              field: 'content',
                              locale: locale,
                              value: formState.content[availableLocales[0]],
                            },
                          });
                          formDispatch({
                            type: 'MODIFY_LOCALE_FIELD',
                            payload: {
                              field: 'contentWordCount',
                              locale: locale,
                              value: formState.contentWordCount[availableLocales[0]],
                            },
                          });
                        }
                      }}
                    />
                  }
                  label={Translation('newsArticle.common.useMainLangVersionToFillIn', {
                    lang: Translation(regionLocaleMap[mainLocale].name),
                  })}
                  labelPlacement="end"
                />
              )}
              <Tinymce
                value={formState.content[locale]}
                disabled={false}
                onChange={(value) => {
                  formDispatch({ type: 'MODIFY_LOCALE_FIELD', payload: { field: 'content', locale, value } });
                }}
                onChangeWordCount={(value) => {
                  formDispatch({ type: 'MODIFY_LOCALE_FIELD', payload: { field: 'contentWordCount', locale, value } });
                }}
                module="prunews"
              />
            </div>
          </div>
        ))}
        <div className={classes.rowContainer} style={{ marginTop: '20px' }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('newsArticle.common.attachments')} :</span>
          </div>
          <div style={{ width: '75%' }}>
            <DisplayAttachmentsTable
              isDisabled={false}
              className={classes.settingTable}
              attachments={formState.attachments || []}
              onMoveRow={(from, to) => {
                return true;
              }}
              onAddRow={() => formDispatch({ type: 'ADD_ATTACHMENT_ROW' })}
              onModifyField={(index, field, value) =>
                formDispatch({ type: 'MODIFY_ATTACHMENT_ROW', payload: { index, field, value } })
              }
              onDeleteRow={(index) => formDispatch({ type: 'DELETE_ATTACHMENT_ROW', payload: { index } })}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsArticle.common.newsType')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ width: '75%' }}>
            <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel
                value="Enable"
                control={<Radio disabled={false} />}
                label={Translation('newsArticle.common.newsType.general')}
                checked={formState.newsType === newsType.GENERAL}
                onChange={() => {
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'newsType', value: newsType.GENERAL } });
                }}
              />
              <FormControlLabel
                value="Disable"
                control={<Radio disabled={false} />}
                checked={formState.newsType === newsType.SEGMENTED}
                label={Translation('newsArticle.common.newsType.segmented')}
                onChange={() => {
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'newsType', value: newsType.SEGMENTED } });
                }}
              />
            </RadioGroup>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}></div>
          {formState.newsType === newsType.SEGMENTED && (
            <div style={{ width: '75%' }}>
              {agentSegmentList?.docs &&
                map(agentSegmentList?.docs, (segment) => (
                  <FormControlLabel
                    key={`agent-segments-${segment._id}`}
                    style={{ flexBasis: '20%', margin: 0 }}
                    control={
                      <Checkbox
                        checked={formState.segments.includes(segment._id)}
                        onChange={(e) => {
                          formDispatch({ type: 'SEGMENT_CHECK', payload: { value: segment._id } });
                        }}
                      />
                    }
                    label={segment.name[locale]}
                    labelPlacement="end"
                  />
                ))}
              <div style={{ width: '75%' }}>
                {errorState.mandatory.segments && (
                  <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
                )}
              </div>
            </div>
          )}
        </div>
        {formState.newsType === newsType.GENERAL && (
          <div className={classes.rowContainer} style={{ marginTop: '20px' }}>
            <div className={classes.fieldContainer} style={{ alignSelf: 'flex-start', paddingTop: '8px' }}>
              <span className={classes.field}>
                {Translation('newsArticle.common.targetApplicants')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ width: '75%' }}>
              {channel == 'BROKER' && region == 'MO' ? (
                <TargetApplicationSection_MO_BROKER
                  isDisabled={isDisabled}
                  enrolmentType={formState.enrolmentType}
                  eligibleDesignations={formState.eligibleDesignations || []}
                  eligibleAgents={formState.eligibleAgents || []}
                  excludedAgents={formState.excludedAgents || []}
                  agentListFileName={formState.agentListFileName}
                  onModifyTarget={modifyTargetApplicant}
                  onToggleDesignation={(value) => {
                    formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
                  }}
                />
              ) : (
                <TargetApplicationSection
                  isDisabled={isDisabled}
                  enrolmentType={formState.enrolmentType}
                  eligibleDesignations={formState.eligibleDesignations || []}
                  eligibleAgents={formState.eligibleAgents || []}
                  excludedAgents={formState.excludedAgents || []}
                  agentListFileName={formState.agentListFileName}
                  onModifyTarget={modifyTargetApplicant}
                  onToggleDesignation={(value) => {
                    formDispatch({ type: 'TOGGLE_DESIGNATION', payload: { value } });
                  }}
                />
              )}

              {errorState.mandatory.designation && (
                <span className={classes.errorText}>{Translation('field_mandatory_helper_label')}</span>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Uploaded File Records */}
      <div className={classes.sectionMargin}>
        <div style={{ marginBottom: 15 }}>
          <span className={classes.subHeader}>{Translation('newsArticle.common.pastFilesRecord')} </span>
        </div>

        <div style={{ marginBottom: 15 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('newsArticle.common.agentListFileName')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>{lastFileName.agentListFileName || 'N/A'}</div>
        </div>
      </div>

      <div className={classes.footerContainer}>
        {
          <>
            <Button
              style={{ marginLeft: 25 }}
              variant="contained"
              color="secondary"
              // onClick={() => onSubmitForm(NewsArticleStatus.Draft)}
              onClick={() => {
                newsArticle?.status == NewsArticleStatus.Published
                  ? setSaveToDraftWarningDialog(NewsArticleStatus.Draft)
                  : onSubmitForm(NewsArticleStatus.Draft);
              }}
            >
              {Translation('app.button.saveAsDraft')}
            </Button>
            <Button
              style={{ marginLeft: 25 }}
              variant="contained"
              color="secondary"
              // onClick={() => onSubmitForm(NewsArticleStatus.Pending)}
              onClick={() => {
                newsArticle?.status == NewsArticleStatus.Published
                  ? setSaveToDraftWarningDialog(NewsArticleStatus.Pending)
                  : onSubmitForm(NewsArticleStatus.Pending);
              }}
            >
              {Translation('app.button.submitToApprove')}
            </Button>
            {approverPermission && (
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                onClick={() => onApproveAndSubmitForm(NewsArticleStatus.Published)}
              >
                {Translation('app.button.approveAndPublish')}
              </Button>
            )}
          </>
        }
      </div>

      <Dialog open={!!saveToDraftWarningDialog} onClose={() => setSaveToDraftWarningDialog('')}>
        <div className={classes.dialogContainer}>
          {saveToDraftWarningDialog == NewsArticleStatus.Pending
            ? Translation('newsArticle.common.submitToApproveWarning')
            : Translation('newsArticle.common.saveToDraftWarning')}
        </div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => onSubmitForm(saveToDraftWarningDialog)}
          >
            {Translation('global.text.yes')}
          </Button>
          <Button variant="contained" color="inherit" onClick={() => setSaveToDraftWarningDialog('')}>
            {Translation('global.text.cancel')}
          </Button>
        </div>
      </Dialog>

      <Dialog maxWidth="xs" open={!!noArchiveDateDialog} onClose={() => setNoArchiveDateDialog(false)}>
        <div className={classes.dialogContainer}>
          <label className={classes.dialogTitle}>{Translation('reminder_title')}</label>
          <label>{Translation('newsArticle.dialog.noArchiveDate.title')}</label>
        </div>
        <div className={classes.btnContainer} style={{ flexDirection: 'column' }}>
          <Button
            fullWidth
            style={{ marginBottom: 10 }}
            variant="contained"
            color="secondary"
            onClick={onConfirmNoArchiveDateClick}
          >
            {Translation('global.text.confirm')}
          </Button>
          <Button fullWidth variant="contained" color="inherit" onClick={() => setNoArchiveDateDialog(false)}>
            {Translation('global.text.cancel')}
          </Button>
        </div>
      </Dialog>
      {/** Error Dialog */}
      <Dialog
        onClose={() => {
          setErrorDialog((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        open={errorDialog.show}
      >
        <div className={classes.container}>
          <div>{errorDialog.message}</div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setErrorDialog((prev) => ({
                  ...prev,
                  show: false,
                }));
              }}
            >
              {Translation('global.text.okay')}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default NewsDetail;
