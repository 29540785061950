import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminEventsPermissionType,
  EVENT_BASE_PATH,
  EVENT_TITLE,
  EVENT_CATEGORY_TITLE,
  EVENT_LIST_TITLE,
  EVENT_CATEGORY_PATH,
  EVENT_LIST_PATH,
  EVENT_CATEGORIES_NAMESPACE,
  EVENT_LIST_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import EventCategoryPage from './pages/Category/EventCategoryPage';
import EventArticlePage from './pages/EventList/EventListPage';

export const eventsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(EVENT_TITLE),
    path: EVENT_BASE_PATH,
    icon: '/media/svg/icons/Home/Book-open.svg',
    enableRead: isPermitted(
      [
        AdminEventsPermissionType.EVENT_CATEGORY_READ,
        AdminEventsPermissionType.EVENT_CATEGORY_CREATE,
        AdminEventsPermissionType.EVENT_CATEGORY_UPDATE,
        AdminEventsPermissionType.EVENT_LIST_READ,
        AdminEventsPermissionType.EVENT_LIST_CREATE,
        AdminEventsPermissionType.EVENT_LIST_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(EVENT_CATEGORY_TITLE),
        path: `${EVENT_BASE_PATH}${EVENT_CATEGORY_PATH}`,
        namespace: EVENT_CATEGORIES_NAMESPACE,
        component: EventCategoryPage,
        enableRead: isPermitted(
          [
            AdminEventsPermissionType.EVENT_CATEGORY_READ,
            AdminEventsPermissionType.EVENT_CATEGORY_CREATE,
            AdminEventsPermissionType.EVENT_CATEGORY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminEventsPermissionType.EVENT_CATEGORY_CREATE], permissions),
        enableUpdate: isPermitted([AdminEventsPermissionType.EVENT_CATEGORY_UPDATE], permissions),
      },
      {
        title: Translation(EVENT_LIST_TITLE),
        path: `${EVENT_BASE_PATH}${EVENT_LIST_PATH}`,
        namespace: EVENT_LIST_NAMESPACE,
        component: EventArticlePage,
        enableRead: isPermitted(
          [
            AdminEventsPermissionType.EVENT_LIST_READ,
            AdminEventsPermissionType.EVENT_LIST_CREATE,
            AdminEventsPermissionType.EVENT_LIST_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminEventsPermissionType.EVENT_LIST_CREATE], permissions),
        enableUpdate: isPermitted([AdminEventsPermissionType.EVENT_LIST_UPDATE], permissions),
      },
    ],
  };
};
