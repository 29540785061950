import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import {
  Button,
  FormControlLabel,
  LinearProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './notification-config-list.style';
import { useNotificationConfigList } from './notification-config-list.hook';
import { AutoNotiTemplateEnum } from 'src/app/modules/Notification/types/notification-types';
import { getDefaultDisplayDate, getCurrentConfig } from 'src/app/common/utils';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import { PruTableRow } from 'src/app/common/components/Table/PruTableRow';
import { isEmptyObject } from 'jquery';

type PageProps = ParamsProps & RouteComponentProps;

export const NotificationConfigListPage: React.FC<PageProps> = (props: PageProps) => {
  const history = props.history;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const templateTitle = {
    sla_expiry_reminder: 'notificationTemplate.name.SLAExpired',
    system_turns_off_accept_lead_toggle_reminder: 'notificationTemplate.name.systemTurnOff',
    truned_off_accept_reminder: 'notificationTemplate.name.offToggle',
  };

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { loading, data, run, onEdit, templateList } = useNotificationConfigList({ id: 1, history });

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={commonStyles.header}>{Translation('component.formTitle.notification-template-table')}</div>
      </div>
      <TableContainer component={Paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <ProTableCell dataIndex="templateName">{Translation('event.common.name')}</ProTableCell>
              <ProTableCell dataIndex="templateAmount">
                {Translation('component.formLabel.notification-template-amount')}
              </ProTableCell>
              <ProTableCell dataIndex="updatedBy">{Translation('component.formLabel.last-updated-time')}</ProTableCell>
              <ProTableCell dataIndex="updatedAt">{Translation('component.formLabel.last-updated-by')}</ProTableCell>

              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PruTableLoading isLoading={loading} />
            <PruTableEmptyRow isEmpty={!data} />
            {isEmptyObject(templateList)
              ? null
              : Object.values(AutoNotiTemplateEnum).map((value, index) => {
                  const rowData = templateList[value];
                  return (
                    <PruTableRow key={index}>
                      <TableCell component="th" scope="row">
                        {Translation(templateTitle[value])}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {rowData.count}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getDefaultDisplayDate(new Date(rowData.lastUpdatedAt), 'datetime')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {rowData.lastUpdatedBy || '-'}
                      </TableCell>
                      <StickyTableCell className={`sticky-cell`}>
                        <div className={styles.operationContainer}>
                          <div className={`${styles.operationBtn} ${styles.action}`} onClick={() => onEdit(value)}>
                            {Translation('app.button.edit')}
                          </div>
                        </div>
                      </StickyTableCell>
                    </PruTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
