import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import SurveyCreator from '../../../../common/components/survey-v2/survey-creator';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

// import { initJson } from '../../../../common/components/survey-v2/survey-json';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { fetchApplicationFormSetting, updateApplicationFormSetting } from '../../network/application-form-crud';
import { RecruitGenericItem } from '../../types/recruit-generic-types';
import { ApplicationFormSettingTemplateParam } from '../../network/application-form-crud';

interface ResWithMessage extends RecruitGenericItem<ApplicationFormSettingTemplateParam> {
  message: string;
}

const OnboardingApplicationPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const [surveyData, setSurveyData] = useState<any>({});
  const { isLoading, refreshData } = useDataProvider(
    async () => {
      try {
        const res = await fetchApplicationFormSetting(dispatch);
        if (res?.status) {
          const resWithMessage = res as ResWithMessage;
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: resWithMessage.status,
                content: resWithMessage.message,
              },
            ]),
          );
        } else {
          return res;
        }
      } catch (err) {}
    },
    setSurveyData,
    false,
  );

  //update survey
  const updateSurvey = async (data: any) => {
    try {
      const res = await updateApplicationFormSetting(data, dispatch);
      if (res?.status) {
        const resWithMessage = res as ResWithMessage;
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: resWithMessage.status,
              content: resWithMessage.message,
            },
          ]),
        );
      } else {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Saved successfully',
            },
          ]),
        );
      }
      refreshData();
    } catch (err) {}
  };

  const MemoSurveyCreator = useMemo(() => {
    return (
      <SurveyCreator
        content={surveyData?.content || {}}
        onChangeContent={(data) => {
          updateSurvey(data);
        }}
        refreshData={refreshData}
        version={surveyData.version}
        templateId={surveyData.templateId}
        module="recruitment"
      />
    );
  }, [surveyData]);

  return <>{MemoSurveyCreator}</>;
};
export default OnboardingApplicationPage;
