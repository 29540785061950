import { downloadFile } from 'src/app/common/utils';
import { apiClient, ADMIN_URL, NOTIFICATION_URL } from 'src/app/common/network';
import { Dispatch } from 'react';

const apis = {
  getI18nConfig: async () => {
    return apiClient.get(`${NOTIFICATION_URL}/configs/langs`);
  },
  getEventDetail: async (id: string) => {
    return apiClient.get(`${ADMIN_URL}/events/${id}`);
  },
  createEvent: async (param: any) => {
    return apiClient.post(`${ADMIN_URL}/events`, param);
  },
  updateEvent: async (id: string, param: any) => {
    return apiClient.put(`${ADMIN_URL}/events/${id}`, param);
  },
  getEventCategories: async () => {
    return apiClient.get(`${ADMIN_URL}/events/categories/all`);
  },
  getEventTemplate: async (dispatch?: Dispatch<any>, Translation?: any) => {
    return downloadFile('template.xlsx', `${ADMIN_URL}/events/importfiles/template`, {}, dispatch, Translation);
  },
};
export default apis;
