export enum RegionLocale {
  ENGLISH = 'en',
  TRADITIONAL_CHINESE = 'zh-Hant',
  SIMPLIFIED_CHINESE = 'zh-Hans',
  BAHASA = 'id',
  SPANISH = 'es',
  JAPANESE = 'ja',
  GERMAN = 'de',
  FRENCH = 'fr',
  VIETNAMESE = 'vi',
}

export type I18nDataItem = Record<string, I18nDataValueItem>;

export type I18nDataValueItem = {
  name?: string;
};

export type I18nGenericData<T> = Record<string, T | undefined>;
