import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import { PruForm } from 'src/app/common/components/PruForm';
import { useIntl } from 'react-intl';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import tableConfig from './tableConfig';
import { styles } from './styles';
import { getAllCategory } from '../../../network/categoryCrud';
import { getEventList } from './api';
import { getCategoryLabel } from '../../../network/utils';
import { regionLocale } from 'src/app/i18n';
import { EVENT_BASE_PATH } from '../../../constants';

const CategoryList: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });

  const filterInitData = {
    timeRange: [],
  };

  const { enableRead, enableCreate, enableUpdate } = props;

  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 1,
    pageSize: 20,
  });
  const [listData, setListData] = useState<{ [key: string]: any }>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      await initialListData();
      setFormLoading(false);
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const initialListData = async () => {
    const data = {
      status: '',
    };

    const categories = await getAllCategory(data);

    const tempListData: any = {};

    let categoryList: any = [
      {
        label: '',
        value: '',
      },
    ];

    // eslint-disable-next-line
    categories.data &&
      categories.data.map((item: any) => {
        categoryList.push({
          label: getCategoryLabel(item),
          value: item.categoryId,
        });
      });

    tempListData.categoryList = categoryList;
    setListData(tempListData);
  };

  const fetchData = (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...pageData,
      ...newFilter,
    };

    requestParams.eventTimeStart = requestParams.timeRange?.[0] || '';
    requestParams.eventTimeEnd = requestParams.timeRange?.[1] || '';

    delete requestParams.timeRange;

    getEventList(requestParams)
      .then((res: any) => {
        setTableData(res.data ? res.data.values : []);
        setPageData({
          pageNum: res.data?.page || 1,
          pageSize: res.data?.size || 0,
          total: res.data?.total || 0,
        });
        setFormLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setFormLoading(false);
      });
  };

  const onSearch = () => {
    // setFilterData(filterData)
    console.log(filterData);
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    setFilterSavedData(filterData);
    fetchData({ ...filterData, ...newPageData });
  };

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChangePage = (pageNum: number) => {
    const newPageData = {
      ...pageData,
      pageNum,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onChangePageRows = (rows: any) => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
      pageSize: rows,
    };

    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const handleSort = (sorter: any) => {
    let sortItem;
    let sortOrder;
    Object.entries(sorter).forEach(([item, order]) => {
      sortItem = item;
      sortOrder = order;
    });
    const data = {
      ...filterSavedData,
      sortItem,
      sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
    };

    const newPageData = {
      ...pageData,
      pageNum: 1,
    };

    setFilterSavedData(data);
    setPageData(newPageData);
    fetchData({ ...data, ...newPageData });
  };

  const AddEvent = () => {
    // setPopUpVisible(true);
    console.log('add event');
    props.history.push(`${EVENT_BASE_PATH}/list/create`);
  };

  const refreshData = async () => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  return (
    <>
      <PruForm
        config={filterConfig(Translation, onSearch, onReset, listData)}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>

      <PruForm
        config={tableHeader(Translation, AddEvent, refreshData, enableCreate)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>

      <TableContainer component={Paper} className="event-list-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop} sorter={item.sorter} handleSort={handleSort}>
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      {enableRead && (
                        <Link to={`${EVENT_BASE_PATH}/list/view/${row.eventId}`}>
                          <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
                            {Translation('app.button.view')}
                          </div>
                        </Link>
                      )}

                      {enableUpdate && (
                        <Link to={`${EVENT_BASE_PATH}/list/edit/${row.eventId}`}>
                          <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
                            {Translation('app.button.edit')}
                          </div>
                        </Link>
                      )}

                      {enableCreate && (
                        <Link to={`${EVENT_BASE_PATH}/list/copy/${row.eventId}`}>
                          <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
                            {Translation('event.list.copy.new.label')}
                          </div>
                        </Link>
                      )}
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.pageNum - 1 || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page + 1)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default CategoryList;
