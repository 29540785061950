import { regionLocale } from 'src/app/i18n';

export const getCategoryLabel = (row: any) => {
  let label = '';
  regionLocale.forEach((code) => {
    const result = row.i18nDataList.find((item: any) => {
      return item.lang === code;
    });

    // eslint-disable-next-line
    label = label + ' ' + ((result && result.name) || '');
  });

  return label;
};
