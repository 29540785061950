import React, { FC} from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { EVENT_BASE_PATH, EVENT_CATEGORY_PATH } from "../../constants";
import PruRoute from "../../../../common/components/PruRoute";
import EventCategoryListPage from "./List";
import { ModulePermissionProps } from "../../../../common/module/types";

const eventCategoryPath = `${EVENT_BASE_PATH}${EVENT_CATEGORY_PATH}`;

const EventCategoryPage: FC<RouteComponentProps & ModulePermissionProps> = ({
    history,
    location,
    match,
    enableRead,
    enableCreate,
    enableUpdate
  }) => {
  return (
    <Switch>
      <Redirect exact from={eventCategoryPath}
        to={`${eventCategoryPath}/list`} />
      <PruRoute
        exact
        path={`${eventCategoryPath}/list`}
        render={(props) => (
          <EventCategoryListPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
    </Switch>
  )
};

export default EventCategoryPage;