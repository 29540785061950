import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { displayPublishStatus } from './utils';
import { regionLocale } from 'src/app/i18n';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const getNameFromI18nData = (code: string, row: any) => {
  const data = row.i18nDataList.find((item: any) => {
    return item.lang === code;
  });

  return data?.name;
};

const getNameColumns = (Translation: any) => {
  let namesColumns = regionLocale.map((code) => {
    const lang = Translation('navBar.lang.' + code);
    const nameColumn = {
      title: `${Translation('event.list.detail.name')} (${Translation(`navBar.lang.${code}`)})`,
      prop: `i18n_name_${code}`,
      render: (row: any) => <span>{getNameFromI18nData(code, row)}</span>,
    };

    return nameColumn;
  });

  return namesColumns;
};

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  const namesColumns = getNameColumns(Translation);

  return [
    ...namesColumns,
    {
      title: Translation('title.event.event.start.time'),
      prop: 'eventStartTime',
      sorter: true,
      render: (row: any) => (
        <span>{row.eventStartTime ? getDefaultDisplayDate(row.eventStartTime, 'datetime') : '-'}</span>
      ),
    },
    {
      title: Translation('title.event.event.end.time'),
      prop: 'eventEndTime',
      sorter: true,
      render: (row: any) => <span>{row.eventEndTime ? getDefaultDisplayDate(row.eventEndTime, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('component.formLabel.publish-status'),
      prop: 'publishStatus',
      render: (row: any) => <span>{displayPublishStatus(row.publishStatus, Translation)?.label}</span>,
    },
    {
      title: `${Translation('title.event.event.status')}`,
      prop: 'eventStatus',
      render: (row: any) => <span>{row.eventStatus ? Translation(`event.status.${row.eventStatus}.label`) : ''}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      prop: 'modifier',
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      sorter: true,
      prop: 'modifyTime',
      render: (row: any) => <span>{row.modifyTime ? getDefaultDisplayDate(row.modifyTime, 'datetime') : '-'}</span>,
    },
  ];
};

export default tableConfig;
