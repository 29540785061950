/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { PruForm } from 'src/app/common/components/PruForm';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import { TimeRangeOptsType, TimeRangeOpts as TimeRangeOptsOrigin, NoPruDNAResultType } from '../constants';
import { REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH, REPORT_RECRUITMENT_PATH, REPORT_BASE_PATH } from '../../../constants';
import { useLang } from 'src/app/i18n';
import moment from 'moment-timezone';
import { getDayEnd, getDayStart } from 'src/app/common/utils';
import apis from '../apis';
import { styles } from '../styles';

// eslint-disable-next-line
const PageId = 'prudna-summary-report';
const PRUDNASummaryReport: FC<void> = () => {
  const formRef = useRef<any>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const e = (s: string) => {
    return Translation('report.recruitment.summary.' + s.replace(/ /g, '_').toLowerCase());
  };
  const { classes } = styles();
  const history = useHistory();
  const langOrigin = useLang();
  const lang = { 'zh-Hant': 'zhHk', hk: 'zhHk', en: 'enUs' }[langOrigin as string] || langOrigin;
  const ytdTime =
    new Date(`${new Date().getFullYear()}-01-01 UTC`).getTime() + (new Date().getTimezoneOffset() / 60) * 3600 * 1000;
  const filterInitData = {
    timeRange: [new Date(ytdTime), getDayEnd(new Date())],
  };

  const [designationOpts, setDesignationOpts] = useState<{ label: string; value: any }[]>([]);
  const [zoneOpts, setZoneOpts] = useState<{ label: string; value: any }[]>([]);
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    page: 0,
    pageSize: 20,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isDownLoading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (window.envConfig['ENABLE_ZONE'] === 'true') {
      apis.getZones().then((res: any) => {
        const zones = res.data || [];
        setZoneOpts(
          [{ label: 'All', value: '' }].concat(
            zones.map((zone: any) => {
              return { label: zone.code, value: zone.code };
            }),
          ),
        );
      });
    }
    if (window.envConfig['ENABLE_DESIGNATION'] === 'true') {
      apis.getDesignation().then((res: any) => {
        const des = res.data || [];
        const opts = [{ value: '', label: 'All' }];
        setDesignationOpts(
          opts.concat(
            des.map((d: any) => {
              return { label: d.shortName, value: d.code };
            }),
          ),
        );
      });
    }
    moment.tz.guess(true); // clear cache
    fetchData();
    return;
    // eslint-disable-next-line
  }, []);

  const TimeRangeOpts = TimeRangeOptsOrigin.map((opt) => {
    return {
      value: opt.value,
      label: e(opt.label),
    };
  });
  const changeFilterData = (d: object) => {
    setFilterData({ ...filterData, ...d });
  };
  const onTimeTypeChange = (v: any) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const offsetTime = (new Date().getTimezoneOffset() / 60) * 3600 * 1000;

    const ytdTime = new Date(`${currentYear}-01-01 UTC`).getTime() + offsetTime;
    const currentMonthTime = new Date(`${currentYear}-${currentMonth}-01 UTC`).getTime() + offsetTime;
    const lastMonthTime = new Date(`${currentYear}-${currentMonth - 1}-01 UTC`).getTime() + offsetTime;

    switch (parseInt(v)) {
      case TimeRangeOptsType.YTD:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(ytdTime), getDayEnd(new Date())],
        });
        break;
      case TimeRangeOptsType.LastMonth:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(lastMonthTime), new Date(currentMonthTime)],
        });
        break;
      case TimeRangeOptsType.CurrentMonth:
        changeFilterData({
          timeRangeType: v,
          timeRange: [new Date(currentMonthTime), getDayEnd(new Date())],
        });
        break;
      case TimeRangeOptsType.CustomizedTimeRange:
        changeFilterData({
          timeRangeType: v,
          timeRange: [null, null],
        });
        break;
      default:
        return;
    }
  };

  const { timeRangeType } = filterData;
  const filterConfig = () => {
    return [
      [
        { type: 'header', label: e('Report Filter'), sm: true },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="inherit" onClick={onReset} style={{ marginRight: 20 }}>
              {Translation('golbal.filters.reset')}
            </Button>
          ),
        },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="secondary" onClick={onSearch}>
              {Translation('golbal.filters.search')}
            </Button>
          ),
        },
      ],
      [
        {
          type: 'select',
          label: e('Time Range'),
          opts: TimeRangeOpts,
          prop: 'timeRangeType',
          width: 400,
          onChange: onTimeTypeChange,
        },
        // { type: 'dateRange', label: 'Time Range', styles: {visibility:'hidden'}, prop: 'timeRange', }
        {
          type: timeRangeType === TimeRangeOptsType.CustomizedTimeRange + '' ? 'dateRange' : 'hide',
          label: '',
          prop: 'timeRange',
          width: 400,
          rules: [
            {
              type: 'array',
              len: 2,
              fields: {
                0: { type: 'date', required: true, message: e('Please input the time range') },
                1: { type: 'date', required: true, message: e('Please input the time range') },
              },
            },
          ],
        },
      ],
      [
        { type: 'input', label: e('Agent Name'), prop: 'agentName', width: 400 },
        { type: 'input', label: e('Agent Code'), prop: 'agentCode', width: 400 },
        {
          type: window.envConfig['ENABLE_DIVISION'] === 'true' ? 'input' : 'none',
          label: e('Division'),
          prop: 'division',
          width: 400,
        },
      ],
      [
        {
          type: window.envConfig['ENABLE_AGENCY_CODE'] === 'true' ? 'input' : 'none',
          label: e('Agency Code'),
          prop: 'agencyCode',
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_ZONE'] === 'true' ? 'select' : 'none',
          label: e('Zone'),
          prop: 'zone',
          opts: zoneOpts,
          width: 400,
        },
        {
          type: window.envConfig['ENABLE_DESIGNATION'] === 'true' ? 'select' : 'none',
          label: e('Designation'),
          prop: 'designation',
          opts: designationOpts,
          width: 400,
        },
      ],
    ];
  };
  const tableHeader = () => [
    [
      { type: 'header', label: e('Candidate & PRUDNA Summary Report'), sm: true },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" color="secondary" onClick={onExport}>
            {Translation('export.list.text')}
          </Button>
        ),
      },
    ],
  ];

  interface tableConfigType {
    title: string;
    prop: string;
    align?: any;
    render?: any;
    minWidth?: number;
  }
  const tableConfig = (): tableConfigType[] => {
    const config = [
      { title: e('Agent Code'), prop: 'agentCode' },
      {
        title: e('Agent Name'),
        prop: 'agentName',
        render: (row: any) => <span>{row.displayName ? row.displayName[lang as string] : ''}</span>,
      },
      {
        title: e('Designation'),
        prop: 'designation',
        render: (row: any) => {
          const disignation = designationOpts.find((d: any) => {
            return d.value === row.designation;
          });
          return <span>{disignation?.label} </span>;
        },
      },
      { title: e('Agency Code'), prop: 'agencyCode' },
      { title: e('Division'), prop: 'division' },
      { title: e('Zone'), prop: 'zone' },
      {
        title: e('No. of Candidate Invited'),
        prop: 'noOfCandidateInvited',
        render: (row: any) => {
          const num = parseInt(row.noOfCandidateInvited);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Invited)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
      {
        title: e('No. of Candidate Accepted'),
        prop: 'noOfCandidateAccepted',
        render: (row: any) => {
          const num = parseInt(row.noOfCandidateAccepted);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Accepted)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
      {
        title: e('No. of PRUDNA Invitations'),
        prop: 'noOfPruDNAInvitations',
        render: (row: any) => {
          const num = parseInt(row.noOfPruDNAInvitations);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Invitations)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
      {
        title: e('No. of PRUDNA Completed'),
        prop: 'noOfPruDNACompleted',
        render: (row: any) => {
          const num = parseInt(row.noOfPruDNACompleted);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Completed)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
      {
        title: e('No. of Proceed'),
        prop: 'noOfProceed',
        render: (row: any) => {
          const num = parseInt(row.noOfProceed);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.Proceed)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
      {
        title: e('No. of Proceed with Caution'),
        prop: 'noOfProceedWithCaution',
        render: (row: any) => {
          const num = parseInt(row.noOfProceedWithCaution);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.ProceedCaution)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
      {
        title: e('No. of Proceed with Extreme Caution'),
        prop: 'noOfProceedWithExtremeCaution',
        render: (row: any) => {
          const num = parseInt(row.noOfProceedWithExtremeCaution);
          return num ? (
            <a className={classes.link} onClick={() => onClickReport(row, NoPruDNAResultType.ProceedExtremeCaution)}>
              {' '}
              {num}
            </a>
          ) : (
            0
          );
        },
      },
    ];
    if (window.envConfig['ENABLE_DESIGNATION'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'designation'),
        1,
      );
    }
    if (window.envConfig['ENABLE_ZONE'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'zone'),
        1,
      );
    }
    if (window.envConfig['ENABLE_AGENCY_CODE'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'agencyCode'),
        1,
      );
    }
    if (window.envConfig['ENABLE_DIVISION'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'division'),
        1,
      );
    }

    return config;
  };

  const mapToApi = (params: any) => {
    params.size = params.pageSize;
    params.page = params.page + 1 || undefined;
    params.timeZone = moment.tz.guess();
    params.lan = lang;
    delete params.pageSize;

    params.timeRangeStart = params.timeRange && params.timeRange[0];
    params.timeRangeEnd = params.timeRange && params.timeRange[1];
    if (params.timeRangeType === TimeRangeOptsType.CustomizedTimeRange) {
      if (params.timeRangeEnd) {
        params.timeRangeEnd = getDayEnd(params.timeRangeEnd);
      }
      if (params.timeRangeStart) {
        params.timeRangeStart = getDayStart(params.timeRangeStart);
      }
    }
    delete params.timeRange;
    delete params.timeRangeType;
  };

  const formValidate = () => {
    const { validate } = formRef!.current;
    return validate && validate();
  };
  const fetchData = async (newState?: any) => {
    formValidate()
      .then((errs: any) => {
        if (!errs) {
          const pd = newState || {};
          const requestParams = {
            ...filterSavedData,
            ...pageData,
            ...pd,
          };
          mapToApi(requestParams);
          setIsLoading(true);
          apis
            .getSummaryReport(requestParams)
            .then((res: any) => {
              setTableData(res.data ? res.data.content : []);
              setPageData({
                page: res.data?.number || 0,
                pageSize: res.data?.size || 0,
                total: res.data?.totalElements || 0,
              });
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      })
      .catch((errs: any) => {
        console.error(errs);
      });
  };

  const onClickReport = (row: any, key: any) => {
    // const win = window.open("/report/prudna/detail", "_blank");
    // win?.focus();
    history.push(`${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH}`, {
      key,
      row,
      timeRange: filterData.timeRange,
      timeRangeType: filterData.timeRangeType,
    });
  };
  const onExport = async () => {
    try {
      const conditions = { ...filterSavedData, lan: lang };
      mapToApi(conditions);
      setIsDownloading(true);
      apis.getSummaryReportExcel(conditions).then((res) => {
        const blob = new Blob([res.data || res], { type: res.data.type });
        const fileName = `PRUDNA_Summary_Report_${new Date().toLocaleDateString()}.xlsx`;
        if ('msSaveOrOpenBlob' in navigator) {
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          setIsDownloading(false);
          return;
        }
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        if ('download' in document.createElement('a')) {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // 对不支持download进行兼容
          link.target = '_blank';
          link.click();
        }
        URL.revokeObjectURL(url);
        setIsDownloading(false);
      });
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  const onSearch = () => {
    setFilterSavedData(filterData);
    const newPageData = {
      ...pageData,
      page: 0,
    };
    setPageData(newPageData);
    fetchData({ ...filterData, ...newPageData });
  };

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const onChangePage = (page: number) => {
    setFilterData(filterSavedData);
    const newPageData = {
      ...pageData,
      page,
    };
    setPageData(newPageData);
    fetchData(newPageData);
  };
  const onChangePageRows = (rows: any) => {
    setFilterData(filterSavedData);
    const newPageData = {
      ...pageData,
      page: 0,
      pageSize: rows,
    };
    setPageData(newPageData);
    fetchData(newPageData);
  };

  const PruTableHeader = withStyles(TableCell, (theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  return (
    <>
      <PruForm
        ref={formRef}
        config={filterConfig()}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>

      <PruForm
        config={tableHeader()}
        data={[]}
        onChange={() => {}}
        space={10}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>
      <TableContainer
        component={Paper}
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, maxHeight: 'calc(100vh - 520px)' }}
      >
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig().map((item) => (
                <PruTableHeader key={item.prop} align={item.align || 'left'} style={{ minWidth: item.minWidth || 140 }}>
                  {Translation(item.title) || item.title}
                </PruTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={12} align="center">
                  <LinearProgress color="secondary" variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {tableData.length === 0 && (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <div style={{ margin: '30px 0 30px 0 ' }}>No record to display</div>
                </TableCell>
              </TableRow>
            )}
            {tableData.map((datas) => (
              <TableRow>
                {tableConfig().map((config) => (
                  <TableCell>{config.render ? config.render(datas) : datas[config.prop]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.page || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default PRUDNASummaryReport;
