import { FC, useEffect, useState } from 'react';
import { SurveyCreator as SurveyJSCreator, SurveyCreatorComponent } from 'survey-creator-react';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import 'survey-core/survey.i18n';
import './survey-patch-style.css';
import { surveyLocalization, Serializer, ComputedUpdater, ComponentCollection } from 'survey-core';
import { ICreatorOptions, settings as SurveyCreatorSetting } from 'survey-creator-core';
import { initJson } from './survey-json';
import { RegionPhone } from '../survey-v2/composite-question-types/region-phone';
import {
  pageProperty,
  panelProperty,
  questionProperty,
  enabledQuestionTypes,
  isPIIProperty,
  presetProperty,
  isAutoCapitalizeProperty,
  modalTitleProperty,
} from './survey-config';
import { regionLocale } from 'src/app/i18n';

interface localeInterface {
  [key: string]: string;
}

const localeMap: localeInterface = {
  'zh-Hans': 'zh-cn',
  'zh-Hant': 'zh-tw',
};

const localeList = regionLocale;

//format locale to surveyjs format
const getLocale = (locale: string) => {
  if (localeMap[locale]) {
    return localeMap[locale];
  }
  return locale;
};

const supportedLocales = localeList.map((locale) => getLocale(locale));

const registerTemplateQuestion = (isCustomMode: boolean) => {
  if (!isCustomMode) {
    ComponentCollection.Instance.add(RegionPhone);
  } else {
    ComponentCollection.Instance.clear();
  }
};
//set property visible or read only
const initConfig = () => {
  Serializer.findProperty('checkbox', 'hasNone').visible = false;
  Serializer.findProperty('checkbox', 'hasOther').visible = false;
  Serializer.findProperty('question', 'name').readOnly = true;
  Serializer.findProperty('panel', 'name').readOnly = true;
  Serializer.findProperty('page', 'name').readOnly = true;
  Serializer.findProperty('question', 'name').readOnly = true;
  Serializer.findProperty('panel', 'title').readOnly = true;
  Serializer.findProperty('page', 'title').readOnly = true;
  Serializer.getProperty('dropdown', 'choices').visible = true;
  Serializer.getProperty('checkbox', 'choices').visible = true;
  //add property for panel/page/question
  Serializer.addProperty('panel', panelProperty);
  Serializer.addProperty('question', questionProperty);
  Serializer.addProperty('page', pageProperty);
  Serializer.addProperty('checkbox', presetProperty);
  Serializer.addProperty('text', presetProperty);
  Serializer.addProperty('boolean', presetProperty);
  Serializer.addProperty('question', isPIIProperty);
  Serializer.addProperty('radiogroup', presetProperty);
  Serializer.addProperty('text', isAutoCapitalizeProperty);
  // Serializer.addProperty('dropdown', modalTitleProperty);
  Serializer.addProperty('text', modalTitleProperty);

  surveyLocalization.supportedLocales = supportedLocales;
  surveyLocalization.defaultLocale = 'en';
};
interface I18nData {
  default: string;
  [key: string]: string;
}

interface Pages {
  name: string;
  title: I18nData;
  elements: any[];
  fieldType?: string;
  state: string;
}

interface Content {
  pages: Pages[];
  templateId: string;
  version: string;
}

export enum CREATE_MODE {
  CUSTOM = 'CUSTOM', // allow user to create custom survey
  TEMPLATE = 'TEMPLATE',
}

type SurveyCreatorProps = {
  content: Content;
  onSavedContent: (surveyJson: Record<string, unknown>) => void;
  onModifiedContent?: (surveyJson: Record<string, unknown>) => void;
  mode?: CREATE_MODE;
};

const options: ICreatorOptions = {
  showJSONEditorTab: false,
  showTranslationTab: true,
  questionTypes: enabledQuestionTypes,
  isAutoSave: false,
  haveCommercialLicense: true,
  showSurveyTitle: false,
  showToolbox: false,
  showPageNavigator: false,
};

const SurveyCreator: FC<SurveyCreatorProps> = ({
  content,
  onSavedContent,
  onModifiedContent,
  mode = CREATE_MODE.TEMPLATE,
}) => {
  const isCustomMode = mode && mode === CREATE_MODE.CUSTOM;
  SurveyCreatorSetting.designer.showAddQuestionButton = isCustomMode;
  options.showToolbox = isCustomMode;

  const [creator, setCreator] = useState(new SurveyJSCreator(options));

  useEffect(() => {
    registerTemplateQuestion(isCustomMode);
    initConfig();
  }, []);

  useEffect(() => {
    const questionPropWhitelist = [
      'name',
      'title',
      'choices',
      'labelTrue',
      'labelFalse',
      'visible',
      'isRequired',
      'startWithNewLine',
      'placeholder',
      'requiredErrorText',
      'validators',
      'text',
      'value',
    ];
    const defaultMandatoryList = [
      'name',
      'title',
      'startWithNewLine',
      'placeholder',
      'requiredErrorText',
      'validators',
      'text',
    ];

    creator.onShowingProperty.add(function (_, options) {
      options.canShow = questionPropWhitelist.includes(options.property.name);
      if (options.obj.getType() === 'page' || options.obj.getType() === 'panel' || options.obj.getType() === 'survey') {
        options.canShow = false;
      }
      if (options.obj.getPropertyValue('name') === 'firstName') {
        options.canShow = defaultMandatoryList.includes(options.property.name);
      }
      if (options.obj.getPropertyValue('name') === 'regionPhone') {
        options.canShow = defaultMandatoryList.includes(options.property.name);
      }
      if (options.obj.getPropertyValue('name') === 'city') {
        options.canShow = [
          'name',
          'title',
          'visible',
          'isRequired',
          'startWithNewLine',
          'placeholder',
          'requiredErrorText',
          'validators',
          'text',
        ].includes(options.property.name);
      }
    });

    // hide survey settting toolbar
    for (let i = creator.toolbarItems.length - 1; i >= 0; i--) {
      const item = creator.toolbarItems[i];
      const hideList = ['svc-translation-export', 'svc-translation-import'];
      if (hideList.includes(item.id)) {
        creator.toolbarItems.splice(i, 1);
      }
      if (item.id === 'svd-settings') {
        creator.toolbarItems[i].visible = false;
      }
    }

    creator.onElementAllowOperations.add(function (_, options) {
      options.allowAddToToolbox = false;
      options.allowDelete = false;
      options.allowCopy = false;
      options.allowDragging = true;
      options.allowChangeType = false;
      options.allowChangeInputType = false;
      options.allowEdit = true;
      options.allowChangeRequired = false;
      if (options.obj.getPropertyValue('name') === 'city') {
        options.allowEdit = false;
      }
      if (options.obj.isNewQuestion) {
        options.allowDelete = true;
      }
    });

    // allow edit in translation tab
    creator.onActiveTabChanging.add(function (_, options) {
      if (options.tabName === 'translation') {
        Serializer.findProperty('panel', 'title').readOnly = false;
        Serializer.findProperty('page', 'title').readOnly = false;
      } else {
        Serializer.findProperty('panel', 'title').visible = false;
        Serializer.findProperty('page', 'title').visible = false;
      }
    });

    creator.onDefineElementMenuItems.add((_, options) => {
      const question = options.obj;
      const newLine = {
        id: 'startWithNewLine',
        css: new ComputedUpdater(() => (question.startWithNewLine ? 'sv-action-bar-item--secondary' : '')),
        title: 'On new line',
        iconName: new ComputedUpdater(() => {
          if (question.startWithNewLine) {
            return 'icon-switch-active_16x16';
          }
          return 'icon-switch-inactive_16x16';
        }),
        iconSize: 16,
        action: () => {
          question.startWithNewLine = !question.startWithNewLine;
        },
      };
      const visible = {
        id: 'setVisible',
        css: new ComputedUpdater(() => (question.visible ? 'sv-action-bar-item--secondary' : '')),
        title: 'Visible',
        iconName: new ComputedUpdater(() => {
          if (question.visible) {
            return 'icon-switch-active_16x16';
          }
          return 'icon-switch-inactive_16x16';
        }),
        iconSize: 16,
        action: () => {
          question.visible = !question.visible;
        },
      };

      const required = {
        id: 'setRequired',
        css: new ComputedUpdater(() => (question.isRequired ? 'sv-action-bar-item--secondary' : '')),
        title: 'Required',
        iconName: new ComputedUpdater(() => {
          if (question.isRequired) {
            return 'icon-switch-active_16x16';
          }
          return 'icon-switch-inactive_16x16';
        }),
        iconSize: 16,
        action: () => {
          question.isRequired = !question.isRequired;
        },
      };
      const reSortItems = () => {
        const hasVisible = options.items.some((item: any) => item?.id === 'setVisible');
        const hasRequired = options.items.some((item: any) => item?.id === 'setRequired');
        const hasNewLine = options.items.some((item: any) => item?.id === 'startWithNewLine');

        if (!hasVisible && question.name !== 'firstName' && question.name !== 'regionPhone') {
          options.items.push(visible);
        }

        if (!hasRequired && question.name !== 'firstName' && question.name !== 'regionPhone') {
          options.items.push(required);
        }

        if (!hasNewLine) {
          options.items.push(newLine);
        }
      };
      reSortItems();
    });

    creator.onItemValueAdded.add(function (_, options) {
      const obj = options.obj;
      if (obj?.preset === 'secondary') {
        const newId = options.newItem.id;
        if (!obj.defaultValue) {
          obj.defaultValue = [newId];
        } else {
          // filter the same id
          if (!obj.defaultValue.includes(newId)) {
            obj.defaultValue = [...obj.defaultValue, newId];
          }
        }
      }
    });

    creator.onModified.add(function (_, options) {
      if (options.type === 'PROPERTY_CHANGED' && options.name === 'isRequired' && options.newValue === true) {
        const question = creator.survey.getQuestionByName(options.target.name);
        Serializer.setObjPropertyValue(question, 'visible', true);
      }

      if (typeof onModifiedContent === 'function') {
        onModifiedContent(creator.JSON);
      }
    });

    creator.onQuestionAdded.add(function (_, options) {
      const newQuestion = options.question;

      // change the property of new question
      const propertyToModify = newQuestion.getPropertyByName('name');
      if (propertyToModify) {
        newQuestion.allowDelete = true;
        newQuestion.isNewQuestion = true;
      }
    });

    creator.onGetPropertyReadOnly.add(function (sender, options) {
      if (options.obj.isNewQuestion && options.propertyName === 'name') {
        options.readOnly = false;
      }
    });

    creator.onTranslationLocaleInitiallySelected.add(function (_, options) {
      options.isSelected = supportedLocales.includes(options.locale);
    });

    if (content) {
      creator.text = JSON.stringify(content);
    } else {
      creator.JSON = initJson;
    }

    creator.saveSurveyFunc = () => {
      onSavedContent(creator.JSON);
    };

    setCreator(creator);
  }, [content]);

  return <SurveyCreatorComponent creator={creator} />;
};

export default SurveyCreator;
