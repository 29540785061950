import { apiClient, ADMIN_URL } from 'src/app/common/network';

const apis = {
  getSummaryReport: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/summary`, { params });
  },
  getSummaryReportExcel: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/summary/excel`, { params, responseType: 'blob' });
  },
  getDetailReport: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/detail`, { params });
  },
  getDetailReportExcel: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/detail/excel`, { params, responseType: 'blob' });
  },
  getZones: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/agents/zones`, { params });
  },
  getDesignation: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/agents/designations`, { params });
  },
};
export default apis;
