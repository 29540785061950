import React, { FC, useEffect, useState } from 'react';
import { SurveyCreator as SurveyJSCreator, SurveyCreatorComponent } from 'survey-creator-react';
import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import 'survey-core/survey.i18n';
import './survey-patch-style.css';
import { Button, Tooltip, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCommonStyles } from '../../styles/common-styles';
import { surveyLocalization, Serializer, ComponentCollection } from 'survey-core';
import { ICreatorOptions, settings } from 'survey-creator-core';
import { ExpressionValidator } from 'survey-react';
import { initJson } from './survey-json';
import {
  pageProperty,
  panelProperty,
  questionProperty,
  enabledQuestionTypes,
  isPIIProperty,
  presetProperty,
  isAutoCapitalizeProperty,
  modalTitleProperty,
  disableChoicesProperty,
  contractMonthProperty,
  inputTypeList,
  triggerPopulateProperty,
  tilNowProperty,
  surveyRequiredErrorTextProperty,
  surveyInvalidNumberErrorTextProperty,
  TnCUploaderProperty,
  TnCLocaleProperty,
  maxFileNumberProperty,
  attachmentUrlProperty,
  acceptedTypesErrorTextProperty,
  maxFileNumberErrorTextProperty,
} from './survey-config';
import { registerYearPicker } from './custom-components';
import { regionLocale } from 'src/app/i18n';
interface localeInterface {
  [key: string]: string;
}

const localeMap: localeInterface = {
  'zh-Hans': 'zh-cn',
  'zh-Hant': 'zh-tw',
};

const localeList = regionLocale;

//format locale to surveyjs format
const getLocale = (locale: string) => {
  if (localeMap[locale]) {
    return localeMap[locale];
  }
  return locale;
};

export const supportedLocales = localeList.map((locale) => getLocale(locale));

const initSurveyCreator = () => {
  registerYearPicker();
  //set property config
  Serializer.findProperty('checkbox', 'hasNone').visible = false;
  Serializer.findProperty('checkbox', 'hasOther').visible = false;
  Serializer.findProperty('checkbox', 'hasSelectAll').visible = false;
  Serializer.findProperty('question', 'name').readOnly = true;
  Serializer.findProperty('panel', 'name').readOnly = true;
  Serializer.findProperty('page', 'name').readOnly = true;
  Serializer.findProperty('question', 'name').readOnly = true;
  Serializer.findProperty('panel', 'title').readOnly = true;
  Serializer.findProperty('page', 'title').readOnly = true;
  Serializer.findProperty('text', 'inputType').visible = false;
  Serializer.findProperty('text', 'inputType').setChoices(inputTypeList);
  Serializer.getProperty('dropdown', 'choices').visible = false;
  Serializer.getProperty('checkbox', 'choices').visible = false;
  Serializer.getProperty('paneldynamic', 'visible').visible = false;
  Serializer.getProperty('boolean', 'title').maxLength = 1000;
  Serializer.getProperty('file', 'maxSize').defaultValue = 4134320;

  //add property for panel/page/question
  Serializer.addProperty('panel', panelProperty);
  Serializer.addProperty('question', questionProperty);
  Serializer.addProperty('page', pageProperty);
  Serializer.addProperty('checkbox', presetProperty);
  Serializer.addProperty('text', presetProperty);
  Serializer.addProperty('boolean', presetProperty);
  Serializer.addProperty('question', isPIIProperty);
  Serializer.addProperty('radiogroup', presetProperty);
  Serializer.addProperty('text', isAutoCapitalizeProperty);
  Serializer.addProperty('dropdown', modalTitleProperty);
  Serializer.addProperty('text', modalTitleProperty);
  Serializer.addProperty('dropdown', disableChoicesProperty);
  Serializer.addProperty('text', contractMonthProperty);
  Serializer.addProperty('file', maxFileNumberErrorTextProperty);
  Serializer.addProperty('file', acceptedTypesErrorTextProperty);

  Serializer.addProperty('panel', 'titleLocation');

  Serializer.addProperty('text', triggerPopulateProperty);
  Serializer.addProperty('text', tilNowProperty);
  Serializer.addProperty('survey', surveyInvalidNumberErrorTextProperty);
  Serializer.addProperty('survey', surveyRequiredErrorTextProperty);
  Serializer.addProperty('boolean', TnCUploaderProperty);
  Serializer.addProperty('boolean', TnCLocaleProperty);
  Serializer.addProperty('boolean', attachmentUrlProperty);
  Serializer.addProperty('file', maxFileNumberProperty);
  surveyLocalization.supportedLocales = supportedLocales;
  surveyLocalization.defaultLocale = 'en';
  surveyLocalization.currentLocale = 'en';
};

let pageCounter = 1;

interface I18nData {
  default: string;
  [key: string]: string;
}

interface Pages {
  name: string;
  title: I18nData;
  elements: any[];
  fieldType?: string;
  state: string;
}

interface Content {
  pages: Pages[];
  templateId: string;
  version: string;
}

type SurveyCreatorProps = {
  content: Content;
  onChangeContent: (content: Record<string, unknown>) => void;
  version: string;
  templateId?: string;
  refreshData?: () => void;
  module?: string;
};

const options: ICreatorOptions = {
  showJSONEditorTab: false,
  showTranslationTab: true,
  questionTypes: enabledQuestionTypes,
  pageEditMode: 'bypage',
  isAutoSave: false,
};

const SurveyCreator: FC<SurveyCreatorProps> = ({
  content,
  onChangeContent,
  version,
  templateId,
  refreshData,
  module,
}) => {
  const [creator, setCreator] = useState(new SurveyJSCreator(options));
  const { classes: commonClasses } = useCommonStyles();
  creator.haveCommercialLicense = true;
  creator.showSurveyTitle = true;
  let esignatureModule: any;

  const handleSave = () => {
    const json = creator.JSON;
    if (json?.pages) {
      json.pages.push(esignatureModule);
    }
    const saveData = {
      content: json,
      version,
      templateId,
    };
    onChangeContent(saveData);
  };

  useEffect(() => {
    initSurveyCreator();
  }, []);

  creator.showSaveButton = false;

  useEffect(() => {
    const pageAndPanelPropWhitelist = [
      'name',
      'text',
      'validators',
      'title',
      'description',
      'fieldType',
      'isPII',
      'placeholder',
      'panelAddText',
      'addRowText',
      'choices',
    ];
    const questionPropWhitelist = [
      'name',
      'title',
      'description',
      'fieldType',
      'isPII',
      'placeholder',
      'choices',
      'labelTrue',
      'text',
      'validators',
      'labelFalse',
      'visible',
      'keyDuplicationError',
      'panelAddText',
      'addRowText',
      'contractMonth',
    ];

    const hideVisiblePanelName = [
      'formPreparation',
      'dataCollectionAndConsent',
      'personalInformation',
      'identificationInformation',
      'contactInformation',
      'accountInformation',
      'designationInformation',
      'documentUploadSection',
      'eSignatureSection',
      'termsNConditionsSection',
    ];

    const surveyWhitelist = ['invalidNumberErrorText', 'requiredErrorText', 'locale'];

    const hidePlaceholderName = ['recruiterAgentCode', 'recruiterName'];

    creator.onShowingProperty.add(function (_, options) {
      const type = options.obj.getType();
      const name = options?.obj?.name;
      const propertyName = options?.property?.name;
      let questionWhiteList = [...questionPropWhitelist];
      const standardWhiteList = [...pageAndPanelPropWhitelist];
      // hide `visible` property for the panel
      if (hideVisiblePanelName.includes(name)) {
        questionWhiteList = questionPropWhitelist.filter((item) => item !== 'visible');
      }
      // hide `placeholder` property
      if (hidePlaceholderName.includes(name)) {
        questionWhiteList = questionPropWhitelist.filter((item) => item !== 'placeholder');
      }
      // add uploader if preset does't equal to `confirm_checkbox_with_detail`
      if (options.obj.getPropertyValue('preset') === 'confirm_checkbox_with_detail') {
        questionWhiteList = questionPropWhitelist.filter((item) => item !== 'labelTrue' && item !== 'labelFalse');
        questionWhiteList.push('attachmentUrl', 'TnCUploader', 'TnCLocale');
        standardWhiteList.push('attachmentUrl', 'TnCUploader', 'TnCLocale');
      }
      // file can be set mandatory by admin self in documentUploadSection
      if (type === 'file' && options.obj?.parent.name === 'documentUploadSection') {
        questionWhiteList.push('isRequired');
        standardWhiteList.push('isRequired');
      }
      // add `requiredErrorText` for isRequired questions
      if (options.obj.getPropertyValue('isRequired') === true) {
        questionWhiteList.push('requiredErrorText');
        standardWhiteList.push('requiredErrorText');
      }
      // add the generic property config for questions
      options.canShow = questionWhiteList.includes(propertyName);
      // add property for survey level
      if (type === 'survey') {
        options.canShow = surveyWhitelist.includes(propertyName);
      }
      // set Standard property
      if (
        options.obj.getPropertyValue('fieldType') !== undefined &&
        options.obj.getPropertyValue('fieldType') === 'Standard'
      ) {
        options.canShow = standardWhiteList.includes(propertyName);
      }
      // add Standard property for page
      if (type === 'page') {
        options.canShow = standardWhiteList.includes(propertyName);
      }
      handleDefaultAddQuestionType(options, type, name);
    });

    const handleDefaultAddQuestionType = (options: any, type: string, questionName?: string) => {
      let name = '';
      const parentName = options?.obj?.parent?.name;
      name = parentName || questionName;
      const acceptedTypes = ['panel', 'page', 'boolean', 'file'];
      if (!name || !acceptedTypes.includes(type)) {
        return;
      }
      switch (name) {
        case 'termsNConditionsSection':
        case 'termsNConditionsModule':
          settings.designer.defaultAddQuestionType = 'boolean';
          break;
        case 'documentUploadModule':
        case 'documentUploadSection':
          settings.designer.defaultAddQuestionType = 'file';
          break;
        default:
          return;
      }
    };
    creator.onQuestionAdded.add(function (_, options) {
      options.question.title = options.question.name;
      if (options?.question.parent?.name === 'termsNConditionsSection') {
        options.question.preset = 'confirm_checkbox_with_detail';
        const tncExpressionValidator = new ExpressionValidator(`{${options.question.name}} = true`);
        tncExpressionValidator.text = 'Please confirm this option';
        (tncExpressionValidator.locText as any).values['zh-tw'] = '請確認此選項';
        options.question.validators = [tncExpressionValidator];
        options.question.titleLocation = 'left';
      }
      if (options?.question.parent?.name === 'documentUploadSection') {
        options.question.allowMultiple = true;
        options.question.acceptedTypes = '.jpeg, .jpg, .png, .pdf';
        options.question.isRequired = true;
        options.question.maxFileNumberErrorText =
          'The maximum number of files for this item is 5. Please remove one or more files.';
        options.question.localizableStrings.maxFileNumberErrorText.values['zh-tw'] = '請最多上載5份文件或圖片。';
        options.question.acceptedTypesErrorText = 'This file type is not accepted. Please select another file.';
        options.question.localizableStrings.acceptedTypesErrorText.values['zh-tw'] =
          '系統並不支持此文件類型。請重新選擇文件。';
        options.question.storeDataAsText = false;
        options.question.waitForUpload = true;
        options.question.maxeSize = 4194304;
        options.question.needConfirmRemoveFile = true;
        options.question.maxFileNumber = 5;
        options.question.description =
          'Please only upload PDF/ JPG/ JPEG/ PNG\n Each file should not be larger than 4MB';
        options.question.localizableStrings.description.values['zh-tw'] =
          '請僅上傳 PDF、JPG、JPEG 和 PNG 格式的文件\n 每個文件的大小不應超過4MB';
      }
    });

    creator.onDragDropAllow.add((sender, options) => {
      if (options.parent?.getType() === 'page') {
        options.allow = false;
      }
    });

    creator.onElementAllowOperations.add(function (_, options) {
      options.allowAddToToolbox = false;
      options.allowDelete = false;
      options.allowCopy = false;
      options.allowDragging = false;
      options.allowChangeRequired = false;
      options.allowChangeType = false;
      if (options.obj.getType() === 'dropdown' && options.obj.getPropertyValue('disableChoices') === true) {
        options.allowEdit = false;
      }
      if (
        (options.obj.getType() === 'boolean' &&
          options.obj.getPropertyValue('preset') === 'confirm_checkbox_with_detail') ||
        (options.obj.getType() === 'file' && options.obj?.parent?.name === 'documentUploadSection')
      ) {
        options.allowDelete = true;
        options.allowCopy = true;
        options.allowDragging = true;
      }
    });

    // hide the toolbox
    creator.showToolbox = false;

    // allow edit in translation tab
    creator.onActiveTabChanging.add(function (_, options) {
      if (options.tabName === 'translation') {
        Serializer.findProperty('panel', 'title').readOnly = false;
        Serializer.findProperty('page', 'title').readOnly = false;
        Serializer.getProperty('dropdown', 'choices').visible = true;
        Serializer.getProperty('checkbox', 'choices').visible = true;
      } else {
        Serializer.findProperty('panel', 'title').readOnly = true;
        Serializer.findProperty('page', 'title').readOnly = true;
        Serializer.getProperty('dropdown', 'choices').visible = false;
        Serializer.getProperty('checkbox', 'choices').visible = false;
      }
    });

    const getTranslationReadOnlyValue = () => {
      const values: string[] = [];
      content?.pages?.forEach((page: any) => {
        values.push(page.title.default);
        // get the panel title
        page.elements?.forEach((panel: any) => {
          values.push(panel.title.default);
          panel.elements?.forEach((question: any) => {
            if (question.type === 'dropdown' && question.disableChoices === true) {
              const choices = question.choices.map((choice: any) => choice.value);
              values.push(choices);
            }
          });
        });
      });
      // flatten array
      const readOnlyValues = values.flat();
      return readOnlyValues;
    };

    const translationReadOnlyValue = getTranslationReadOnlyValue();

    creator.onSurveyInstanceCreated.add(function (_, options) {
      // handle the tab change of translation tab
      if (options.reason === 'translation_strings') {
        options.survey.onMatrixCellCreated.add(function (_: any, options: any) {
          if (options.columnName.indexOf('default') !== -1) {
            // if cellQuestion.displayValue is included in panelOrPageDefaultTitle, then set cellQuestion.readOnly = true
            if (translationReadOnlyValue.includes(options.cellQuestion.displayValue)) {
              options.cellQuestion.readOnly = true;
            }
          }
        });
      }
      // handle change in designer tab
      if (options.reason === 'designer') {
        options.survey.onShowingChoiceItem.add(function (_: any, options: any) {
          if (options.item.id === 'newitem' && options.question.disableChoices === true) {
            options.visible = false;
          }
        });
      }
    });

    creator.onSurveyPropertyValueChanged.add(function (_, options) {
      const obj = options.obj;
      if (!options.newValue || options.newValue?.length === 0) {
        // if delete item, then handle it
        if (obj?.preset === 'secondary') {
          const removeId = options.oldValue[0].id;
          obj.defaultValue = obj.defaultValue.filter((id: string) => id !== removeId);
        }
      }
    });

    creator.onItemValueAdded.add(function (_, options) {
      const obj = options.obj;
      if (obj?.preset === 'secondary') {
        const newId = options.newItem.id;
        if (!obj.defaultValue) {
          obj.defaultValue = [newId];
        } else {
          // filter the same id
          if (!obj.defaultValue.includes(newId)) {
            obj.defaultValue = [...obj.defaultValue, newId];
          }
        }
      }
    });
    creator.onTranslationLocaleInitiallySelected.add(function (_, options) {
      options.isSelected = supportedLocales.includes(options.locale);
    });

    esignatureModule = content?.pages?.find((page) => page.name === 'eSignatureModule');
    const creatorContentPages = content?.pages?.filter((page) => page.name !== 'eSignatureModule');
    content.pages = creatorContentPages;

    if (content) {
      creator.text = JSON.stringify(content);
    } else {
      creator.JSON = initJson;
    }

    creator.saveSurveyFunc = () => handleSave();

    creator.onPageAdded.add(function (_, options) {
      const q = options.page;
      q.name = 'Module' + pageCounter;
      q.title = 'Module' + pageCounter;
      pageCounter++;
    });
    setCreator(creator);
  }, [content]);

  return (
    <div className="tw-w-full mx-auto tw-h-full tw-overflow-y-hidden">
      <div className="tw-w-full tw-flex tw-justify-start tw-items-center tw-mb-4 tw-p-4  tw-bg-white tw-rounded-md">
        <div className={`${commonClasses.header} tw-pl-1`}>Application Form Setting</div>
        <div className="tw-ml-auto">
          <Tooltip title="Refresh">
            <IconButton onClick={refreshData}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button variant="contained" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <div className="tw-w-full tw-h-5/6" id="onboarding">
        <SurveyCreatorComponent creator={creator} />
      </div>
    </div>
  );
};

export default SurveyCreator;
