import React, { useState, useEffect, useRef } from 'react';
import Form, { useForm } from '../../../../../../../common/components/Form';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Modal,
  Fade,
  FormGroup,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { LibraryItem, ResourceLibraryFormState } from '../../../types/library-types';
import {
  ResourceType,
  PublishStatus,
  LibraryPublishStatus,
  YesAndNo,
  ProductType,
  targetReceiver,
  productType,
} from '../../../constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubFormList from './SharedInfoForm';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import TableInput from './TableInput';
import { useCommonStyles } from '../../../../../../../common/styles/common-styles';
import ButtonDialog from '../../../../../../../common/components/ButtonDialog';
import DateTimeRangePicker from '../../../../../../../common/components/DateTimeRangePicker';
import moment from 'moment';
import EditMaterialSetList from '../../List/EditMaterialSetList';
import {
  NumberGreaterThanZeroPattern,
  disableInputInvalidValueForWeight,
  getEnabledCustomWeight,
} from 'src/app/common/utils';
import PruDialog from '../../../../../../../common/components/PruDialog';
import { removeMaterialFromSets } from '../../../utils/common-utils';
import { fetchCategoryItem, fetchCategoryList } from '../../../../Category/network/categoryCrud';
import { CategoryLevel, CategoryPaginateList } from '../../../../Category/types/category-types';
import { fetchAllColumns } from '../../../../Column/network/columnCrud';
import { fetchLibraryList, validateColumWeight } from '../../../network/libraryCrud';
// import { useLang } from 'src/app/i18n';
import { regionLocale, RegionLocale, regionLocaleMap } from 'src/app/i18n';

import TargetAgent from './TargetAgent';
import { useSelector } from 'react-redux';
import { Configuration } from 'src/app/modules/Config/types/config-type';
import { RootState } from 'src/redux/store';
import { get } from 'lodash';
import { useStyles, weightFormItem } from './main-form.style';

const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'];
const ENABLE_ZONE = window.envConfig['ENABLE_ZONE'];

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  let expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

export const renderDisplayStatusField = (rowData: ResourceLibraryFormState): string => {
  switch (rowData.status) {
    case LibraryPublishStatus.PublishToSetOnly:
      return 'component.status.publishedToSetOnly';
    case LibraryPublishStatus.Publish:
      return 'component.status.published';
    case LibraryPublishStatus.Unpublished:
      return 'component.status.unpublished';
    case LibraryPublishStatus.Draft:
    default:
      return 'component.status.draft';
  }
};

interface FormListProp {
  disabled: boolean;
  onSave: (formData: ResourceLibraryFormState, columnOptions: ColumnOptions) => void;
  blockName: string;
  categoryType: string;
  moduleType: string;
  blockType: string;
  columnType: string;
  initialValues: ResourceLibraryFormState;
  isEdit: boolean;
  history: any;
  location?: any;
  match?: any;
  currentId?: string;

  loadingFun?: () => void;
}

export type ColumnOptions = {
  label: string;
  value: string;
  id: string;
}[];

interface OptionItem {
  title: string | React.ReactNode;
  value: string;
  children?: OptionItem[];
}

const FormList: React.FC<FormListProp> = (props) => {
  // const locale = useLang();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { disabled, onSave, blockName, initialValues, isEdit, history, blockType, moduleType, currentId, loadingFun } =
    props;
  const [treeOptions, setTreeOptions] = useState<OptionItem[]>([]);
  const [columnOptions, setColumnOptions] = useState<ColumnOptions>([]);
  const regionLocales = regionLocale;
  const mainLocale = regionLocale[0] || RegionLocale.ENGLISH;
  const [panelExpand, setPanelExpand] = useState<any>(generateDefaultExpandPanel(regionLocales));
  const [openMaterialModal, setOpenMaterialModal] = useState<boolean>(false);
  const [removedMaterials, setRemovedMaterials] = useState<any[]>([]);
  const [publishItems, SetPublishItems] = useState<any[]>([]);
  // eslint-disable-next-line
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isAddColumn, setIsAddColumn] = useState<boolean>(false);
  const [form] = useForm();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  // validate rules
  const config = useSelector<RootState, Configuration | undefined>((state) => state.config.data);
  const productTypes = config?.productTypes ?? [];
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const isAdd = isEdit ? false : disabled ? false : true;
  const refEnabledCustomWeight = useRef<boolean>(getEnabledCustomWeight());

  const dropdownOptions = {
    rType: [
      { displayName: 'File', value: 'file' },
      { displayName: 'Link', value: 'link' },
      { displayName: 'Content', value: 'content' },
      { displayName: 'Material Set', value: 'materialSet' },
      { displayName: 'Text', value: 'text' },
      { displayName: 'Picture', value: 'picture' },
    ],
  };

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    formData.agent = 'designation';
    formData.removedMaterials = removedMaterials;
    if (ENABLE_DESIGNATION !== 'true') {
      formData.designations = [];
    }
    if (ENABLE_ZONE !== 'true') {
      formData.zones = [];
    }
    onSave(formData, columnOptions);
  };

  const DATE_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (refEnabledCustomWeight.current === false && (!value || !value[0])) {
          // effective start time is mandatory when weight off
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }
        if (value && value[0] && value[1]) {
          if (moment(value[0]).isAfter(moment(value[1]))) {
            return Promise.reject(new Error(Translation('component.form-date-compare')));
          }
        }
        return Promise.resolve();
      },
    },
  ];

  const onSaveDraft = () => {
    const formData = form.getFieldsValue(true);
    formData.agent = 'designation';
    if (ENABLE_DESIGNATION !== 'true') {
      formData.designations = [];
    }
    if (ENABLE_ZONE !== 'true') {
      formData.zones = [];
    }
    // const validationList = regionLocales.map((localeItem) => { return [localeItem, 'name'] });
    // set validate for all mandatroy fields not only information
    form
      .validateFields()
      .then((res) => {
        onSave(formData, columnOptions);
      })
      .catch((err) => {
        const { errorFields } = err;
        if (errorFields.length <= 0) {
          onSave(formData, columnOptions);
        }
      });

    return false;
  };

  const onCancel = () => {
    history.goBack();
  };

  const handleMaterialModal = (isOpen: boolean) => {
    setOpenMaterialModal(isOpen);
  };

  const addResoureceToSet = (resources: any) => {
    const allValues = form.getFieldsValue(true);
    const materialSet = form.getFieldValue('materialSet') || [];
    const newData = materialSet.concat(resources);
    const result = { ...allValues, materialSet: newData };
    form.setFieldsValue(result);
    handleMaterialModal(false);
  };

  const clearWeight = (row: number) => {
    if (refEnabledCustomWeight.current) {
      const data = form.getFieldsValue(true);
      data.moduleColumn[row].weight = '';
      form.setFieldsValue({
        ...data,
        moduleColumn: [...data.moduleColumn],
      });
    }
  };

  const handleChange = (locale: string) => (event: any, isExpanded: boolean) => {
    setPanelExpand({ ...panelExpand, [locale]: isExpanded });
  };

  const copyMainLangVersion = (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.checked) {
      const allValues = form.getFieldsValue(true);
      const to = locale;
      const from = mainLocale;
      const fromValue = allValues[from];
      const result = { ...allValues, [to]: { ...fromValue } };
      form.setFieldsValue(result);
    }
  };
  useEffect(() => {
    getCategoryOptionList();
    getColumnOptionList();
    if (initialValues.moduleColumn.length === 0) {
      initialValues.moduleColumn = [
        {
          column: '',
          weight: null,
        },
      ];
    }
    if (!initialValues.designations) {
      initialValues.designations = [];
    }

    if (!initialValues.zones) {
      initialValues.zones = [];
    }
    form.setFieldsValue({ ...initialValues });

    getDisplayStatus();
    // eslint-disable-next-line
  }, []);

  const opertaionColumn = Translation('component.formLabel.operation-column');
  const placeSelect = Translation('app.input.placeholder.please-select');
  const selected = Translation('component.formLabel.selected');
  const translatedBlockName = Translation(blockName);

  const getCategoryOptionList = async () => {
    let options: any[] = [];
    const resultList = await fetchCategoryList(
      {
        module: moduleType,
        page: 1,
        pageSize: 1000,
      },
      [],
    );
    options = await appendChildrenDataToList(resultList);
    setTreeOptions(options);
  };

  const getColumnOptionList = async () => {
    let options: any[] = [];
    let resultList = await fetchAllColumns(moduleType);
    resultList.map((item: any) => {
      const displayLabel = regionLocales.map((localItem, index) =>
        item.name[localItem] ? `${index > 0 ? ` (` : ``}${item.name[localItem]}${index > 0 ? `)` : ``}` : ``,
      );
      return options.push({ label: displayLabel, value: item.id, id: item.id });
    });
    setColumnOptions(options as ColumnOptions);
  };

  const appendChildrenDataToList = async (originalPaginateList: CategoryPaginateList): Promise<any[]> => {
    let originalList = originalPaginateList.categoryList;
    let resultList: any = [];
    //Remove if parentExist
    originalList = originalList.filter((listItem) => !listItem.parent);
    //Add childrenItem under
    for (let i = 0; i < originalList.length; i++) {
      let level2List: any[] = [];
      //@ts-ignore
      if (originalList[i].children?.count && originalList[i].children?.count > 0) {
        //Fetch level1 detail
        let listItemDetail = await fetchCategoryItem(originalList[i].id);
        //@ts-ignore
        for (let j = 0; j < listItemDetail.children.length; j++) {
          //Fetch level2 detail
          //@ts-ignore
          let level2listItemDetail = await fetchCategoryItem(listItemDetail.children[j].id);
          let level3List: any[] = [];
          //@ts-ignore
          if (level2listItemDetail.children && level2listItemDetail.children.length > 0) {
            //@ts-ignore
            for (let k = 0; k < level2listItemDetail.children.length; k++) {
              //@ts-ignore
              level3List.push({
                level: CategoryLevel.THREE,
                title: level2listItemDetail.children[k].name,
                value: level2listItemDetail.children[k].id,
              });
            }
          }
          //@ts-ignore
          level2List.push({
            level: CategoryLevel.TWO,
            title: level2listItemDetail.name,
            value: level2listItemDetail.id,
            children: level3List?.length > 0 ? level3List : undefined,
          });
        }
      }
      resultList.push({
        level: CategoryLevel.ONE,
        title: originalList[i].name,
        value: originalList[i].id,
        children: level2List?.length > 0 ? level2List : undefined,
      });
    }
    return resultList;
  };

  const renderTreeItem = (
    treeOption: OptionItem,
    level = 0,
    result: { title: React.ReactNode; value: string }[] = [],
  ): { title: React.ReactNode; value: string }[] => {
    result.push({
      title: (
        <span className="tree-select-option" style={{ marginLeft: 18 * level }}>
          {treeOption.title}
        </span>
      ),
      value: treeOption.value,
    });
    if (treeOption.children) {
      treeOption.children.forEach((child) => {
        renderTreeItem(child, level + 1, result);
      });
    }
    return result;
  };

  const renderTreeOption = (options: OptionItem[]) => {
    return options
      .map((option) => renderTreeItem(option))
      .flat()
      .map((child) => (
        <MenuItem key={child.value} value={child.value}>
          {child.title}
        </MenuItem>
      ));
  };

  const getSelectedMaterialSet = () => {
    return form.getFieldValue('materialSet');
  };

  // eslint-disable-next-line
  const getModuleColumn = () => {
    return form.getFieldValue('moduleColumn');
  };

  const getRules = (index: number, name: any) => {
    return [
      { required: true, message: Translation('component.form-weight-text') },
      {
        pattern: NumberGreaterThanZeroPattern,
        message: Translation('recruit-library-weight-greater-zero'),
      },
      {
        validator(_: any, value: string) {
          return validateWeight(index, name);
        },
      },
    ];
  };

  const getColumnRules = (index: number, name: any) => {
    return [DEFAULT_REQUIRED_RULES];
  };

  const validateWeight = async (index: number, name: any) => {
    const moduleColumn = getModuleColumn();
    const currentColumn = moduleColumn[index];
    const currentWeight = currentColumn.weight;

    if (!currentWeight || !currentColumn.column) {
      return true;
    }

    const validate = await validateColumWeight(currentColumn.column, currentWeight.toString());

    if (!validate) {
      return Promise.reject(new Error(Translation('component.form-weight-text')));
    }

    return true;
  };

  const onSubmitForm = (getFieldValue: any) => {
    const status = getFieldValue('status');

    if (status === 'Draft') {
      onSaveDraft();
      return;
    }

    if (
      status === 'Unpublished' &&
      initialValues.parentItems &&
      (initialValues.parentItems as LibraryItem[]).length > 0
    ) {
      setOpenDialog(true);
    } else {
      status === 'Unpublished' ? submitFormAnyWay() : form.submit();
    }
  };

  const removeFromSets = async () => {
    const { underMaterialSetIDs, resourceId } = initialValues;
    setOpenDialog(false);
    loadingFun && loadingFun();

    removeMaterialFromSets(underMaterialSetIDs, resourceId, blockType, () => {
      const status = form.getFieldValue('status');
      status === 'Unpublished' ? submitFormAnyWay() : form.submit();
    });
  };

  const submitFormAnyWay = () => {
    onSaveDraft();
  };

  const closeWaringDialog = () => {
    setOpenDialog(false);
  };

  const getRemovedMaterial = (items: any) => {
    removedMaterials.push(items);
    setRemovedMaterials(removedMaterials);
  };

  const getDisplayStatus = (selectedType: string = '') => {
    const isMaterialSet = selectedType
      ? selectedType === ResourceType.materialSet
      : form.getFieldValue('rType') === ResourceType.materialSet;
    const isPublishedItem =
      initialValues.status === LibraryPublishStatus.Publish || LibraryPublishStatus.PublishToSetOnly;
    const isDraft = initialValues.status === LibraryPublishStatus.Draft;
    const isUnpublished = initialValues.status === LibraryPublishStatus.Unpublished;

    let displayStatus: any = [];

    if (isMaterialSet) {
      displayStatus = displayStatus.concat([
        isPublishedItem ? undefined : PublishStatus.Draft,
        PublishStatus.Published,
        PublishStatus.Unpublished,
      ]);
    } else if (isAdd || isDraft) {
      displayStatus = displayStatus.concat([
        PublishStatus.Draft,
        PublishStatus.Published,
        PublishStatus.PublishedToSetOnly,
      ]);
    } else if (isPublishedItem) {
      displayStatus = displayStatus.concat([
        PublishStatus.Published,
        PublishStatus.PublishedToSetOnly,
        PublishStatus.Unpublished,
      ]);
    } else if (isUnpublished) {
      displayStatus = displayStatus.concat([
        PublishStatus.Published,
        PublishStatus.PublishedToSetOnly,
        PublishStatus.Unpublished,
      ]);
    } else {
      displayStatus = displayStatus.concat([
        PublishStatus.Draft,
        PublishStatus.Published,
        PublishStatus.PublishedToSetOnly,
        PublishStatus.Unpublished,
      ]);
    }

    SetPublishItems(displayStatus);
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} initialValues={{ ...initialValues }}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>
                {isEdit
                  ? Translation('component.formTitle.edit')
                  : disabled
                    ? Translation('component.formTitle.detail')
                    : Translation('component.formTitle.add')}
              </div>
            </div>
            <Button variant="contained" color="inherit" onClick={() => history.goBack()}>
              {Translation('global.back.btnText')}
            </Button>
          </div>
          {disabled && (
            <Form.Item label={Translation('component.formLabel.publish-status')}>
              <span>{Translation(renderDisplayStatusField(initialValues))}</span>
            </Form.Item>
          )}
          <Form.Item name="rType" label={Translation('component.formLabel.type')} rules={[DEFAULT_REQUIRED_RULES]}>
            <TextField
              disabled={disabled || initialValues.rType === ResourceType.materialSet}
              select
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder={placeSelect}
            >
              {dropdownOptions['rType'].map((item) => (
                <MenuItem key={item.value} value={item.value} onClick={() => getDisplayStatus(item.value)}>
                  {item.displayName}
                </MenuItem>
              ))}
            </TextField>
          </Form.Item>
          <Form.Item
            name="category"
            label={Translation('component.formLabel.category')}
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <TextField
              disabled={disabled}
              select
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder={placeSelect}
              className="tree-select"
            >
              {renderTreeOption(treeOptions)}
            </TextField>
          </Form.Item>

          {moduleType === 'Recruit' || productTypes.length < 2 ? null : (
            <Form.Item
              name="productType"
              label={Translation('component.formLabel.productType')}
              rules={[DEFAULT_REQUIRED_RULES]}
              shouldUpdate
              initialValue={initialValues.productType}
            >
              <StyledRadioGroup aria-label="contentType" name="contentType">
                {Object.entries(productType(intl)).map(([label, value]) => (
                  <FormControlLabel
                    key={value.key}
                    value={value.key}
                    control={<Radio disabled={disabled} />}
                    label={value.label}
                  />
                ))}
              </StyledRadioGroup>
            </Form.Item>
          )}
          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rType !== currentValues.rType}>
            {(_, meta, { getFieldValue }) => {
              const resourceType = getFieldValue('rType');
              return resourceType === 'materialSet' ? (
                <>
                  <div
                    className={classes.divideMargin}
                    style={{ marginLeft: '16px', height: '32px', lineHeight: '32px' }}
                  >
                    <span className={classes.subHeader}>{Translation('component.formTitle.information')}</span>
                  </div>
                  {!disabled ? (
                    <div className={classes.addNewMaterialSetBtn}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleMaterialModal(true);
                        }}
                      >
                        {Translation('component.materialset.addNew.text')}
                      </Button>
                    </div>
                  ) : null}
                  <Form.Item name="materialSet" label={selected + translatedBlockName}>
                    <TableInput
                      disabled={disabled}
                      getRemovedMaterial={getRemovedMaterial}
                      initialValue={initialValues.materialSet}
                    />
                  </Form.Item>
                  {/* <Divider /> */}
                </>
              ) : null;
            }}
          </Form.Item>
        </div>
        <div className={classes.accordContainer}>
          <div className={classes.divideMargin} style={{ marginLeft: '16px', height: '32px', lineHeight: '32px' }}>
            <span className={classes.subHeader}>{Translation('component.formTitle.information')}</span>
          </div>
          {regionLocales.map((locale, index) => {
            return (
              <Accordion
                key={index}
                expanded={panelExpand[locale as string]}
                onChange={handleChange(locale)}
                style={{ marginTop: 0 }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                  <Typography className={classes.accordHeading}>{Translation(regionLocaleMap[locale].name)}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  {index === 0 || disabled ? null : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            copyMainLangVersion(e, locale);
                          }}
                          name="copyMainLangVersion"
                          color="secondary"
                        />
                      }
                      label={Translation('newsArticle.common.useMainLangVersionToFillIn', {
                        lang: Translation(regionLocaleMap[mainLocale].name),
                      })}
                    />
                  )}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.rType !== currentValues.rType}
                  >
                    {(_, meta, { getFieldValue }) => {
                      const resourceType = getFieldValue('rType');
                      return (
                        <SubFormList
                          activeLang={locale}
                          form={form}
                          disabled={disabled}
                          resourceType={resourceType}
                          nameMaxSize={120}
                          descriptMaxSize={160}
                          getFieldValue={getFieldValue}
                          moduleType={moduleType}
                        />
                      );
                    }}
                  </Form.Item>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        <div className={classes.container}>
          <div className={classes.divideMargin} style={{ height: '32px', lineHeight: '32px' }}>
            <span className={classes.subHeader}>{Translation('component.formTitle.publishment-settings')}</span>
          </div>

          <TargetAgent form={form} {...props} />

          <Form.List name="moduleColumn">
            {(fields, { add, remove }) => {
              return fields.map((field, index) => (
                <div key={field.key} style={{ display: 'flex', alignContent: 'start' }}>
                  <Form.Item
                    {...field}
                    label={index === 0 ? opertaionColumn : ' '}
                    rules={getColumnRules(index, [field.name, 'column'])}
                    name={[field.name, 'column']}
                    style={{ flex: 1, flexGrow: 1 }}
                  >
                    <TextField
                      disabled={disabled}
                      select
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      placeholder={placeSelect}
                      onChange={() => clearWeight(index)}
                    >
                      {
                        // need to update the component to tree select
                        columnOptions.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Form.Item>
                  {refEnabledCustomWeight.current && (
                    <Form.Item
                      name={[field.name, 'weight']}
                      rules={getRules(index, [field.name, 'weight'])}
                      //required={false}
                      style={weightFormItem}
                    >
                      <TextField
                        type="number"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        placeholder={Translation('component.formLabel.placeholder-weight')}
                        disabled={disabled}
                        inputProps={disableInputInvalidValueForWeight()}
                      />
                    </Form.Item>
                  )}
                  {!disabled ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '100px',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: '-10px',
                      }}
                    >
                      <AddIcon
                        fontSize="large"
                        onClick={() =>
                          (() => {
                            add();
                            setIsAddColumn(true);
                          })()
                        }
                      />
                      {fields.length > 1 ? <RemoveIcon fontSize="large" onClick={() => remove(field.name)} /> : null}
                    </div>
                  ) : null}
                </div>
              ));
            }}
          </Form.List>
          <Form.Item
            required={refEnabledCustomWeight.current ? false : true}
            name="effectiveRange"
            label={Translation('component.formLabel.effective-date')}
            rules={DATE_VIDATOR}
          >
            <DateTimeRangePicker disabled={disabled} />
          </Form.Item>
          {!disabled ? (
            <Form.Item
              name="status"
              label={Translation('component.formLabel.publish-now')}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <RadioGroup aria-label="contentType" name="contentType" style={{ display: 'flex', flexDirection: 'row' }}>
                {publishItems &&
                  publishItems.map((item: any) => {
                    return (
                      item && (
                        <FormControlLabel
                          key={item.key}
                          value={item.key}
                          control={<Radio disabled={disabled} />}
                          // label={Translation(`component.formSelectItem.${item.value}`)}
                          label={Translation(item.label)}
                        />
                      )
                    );
                  })}
              </RadioGroup>
            </Form.Item>
          ) : null}
          <div className={classes.footerContainer}>
            {!disabled ? (
              <>
                <ButtonDialog
                  onOk={onCancel}
                  dialogTxt={Translation('component.hint.cancel-warning')}
                  btnTxt={Translation('app.button.cancel')}
                />
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.isPublish !== currentValues.isPublish}
                >
                  {(_, meta, { getFieldValue }) => {
                    return (
                      <Button
                        style={{ marginLeft: 20 }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          onSubmitForm(getFieldValue);
                        }}
                      >
                        {Translation('app.button.submit')}
                      </Button>
                    );
                  }}
                </Form.Item>
              </>
            ) : null}
          </div>
        </div>
      </Form>

      <Modal
        open={openMaterialModal}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleMaterialModal(false);
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <Fade in={openMaterialModal}>
          <div className={classes.modalBg}>
            <div>
              <Button
                onClick={() => {
                  handleMaterialModal(false);
                }}
                style={{ marginLeft: 20 }}
                variant="contained"
              >
                {Translation('global.back.btnText')}
              </Button>
            </div>
            <div>
              <EditMaterialSetList
                {...props}
                blockName={Translation('component.resource.selectNew')}
                blockType={blockType}
                selectedMaterialSet={getSelectedMaterialSet()}
                handleMaterialModal={handleMaterialModal}
                addResoureceToSet={addResoureceToSet}
                moduleType={moduleType}
              />
            </div>
          </div>
        </Fade>
      </Modal>

      <PruDialog
        open={openDialog}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={removeFromSets}
        confirmBtnText={Translation('global.text.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
      >
        {Translation('warning.message.unpublish.material')}
      </PruDialog>
    </div>
  );
};

export default FormList;
