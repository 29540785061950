export const SECRETARY_BASE_PATH = '/secretary';

export const SECRETARY_TITLE = 'title.secretary';

export const SECRETARY_NAMESPACE = 'SECRETARY';

export const dependentPermissionKeyList = [{ parent: 'READ_OWN_POLICY', child: ['READ_OWN_POLICY-DOCUMENT'] }];

export enum AdminSecretaryPermissionType {
  SECRETARY_READ = 'READ_ANY_SECRETARY',
  SECRETARY_UPDATE = 'UPDATE_ANY_SECRETARY',
  SECRETARY_APPROVE = 'APPROVE_ANY_SECRETARY',
}
