import React, { FC, useState, useEffect, useImperativeHandle } from 'react';
import { Button, Dialog, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns from './columns';
import { getEventList } from 'src/app/modules/Event/pages/EventList/List/api';
import { regionLocale } from 'src/app/i18n';
import { getAllCategory } from 'src/app/modules/Event/network/categoryCrud';
import eventsApi from 'src/app/modules/Event/pages/EventList/Detail/apis';

const useStyles = makeStyles()((theme) => ({
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    padding: '15px',
  },
}));

const SpecificDeeplinkList: FC<any> = (props) => {
  const { selectId, onSelectItem, isView } = props;

  const filterInitData = {
    timeRange: [],
    publishStatus: 1,
    eventStatus: 'NotExpired',
  };

  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<any>({});
  const [selectedRowsState, setSelectedRows] = useState<any>(null);

  useEffect(() => {
    const fetchAPI = async () => {
      await fetchCategoryList();
      if (selectId) {
        await fecthEventById(selectId);
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setPopUpVisible(false);
  };

  const onSelect = () => {
    setPopUpVisible(true);
  };

  const formatEventData = async (newFilterState?: any) => {
    const newFilter = newFilterState || {};
    const requestParams = {
      ...newFilter,
    };
    requestParams.page = requestParams.page || 1;
    requestParams.pageSize = requestParams.pageSize || 20;

    requestParams.eventTimeStart = requestParams.timeRange?.[0] || '';
    requestParams.eventTimeEnd = requestParams.timeRange?.[1] || '';
    delete requestParams.timeRange;
    const result = await getEventList(requestParams);
    const res = {
      data: result.data.values || [],
      success: true,
      total: result.data.total,
    };

    return res;
  };

  const getCategoryLabel = (row: any) => {
    let label = '';
    regionLocale.forEach((code) => {
      const result = row.i18nDataList.find((item: any) => {
        return item.lang === code;
      });

      // eslint-disable-next-line
      label = label + ' ' + ((result && result.name) || '');
    });

    return label;
  };

  const fetchCategoryList = async () => {
    const data = {
      status: '',
    };

    const categories = await getAllCategory(data);

    let categoryList: any = {
      '': {
        text: '',
        status: '',
      },
    };

    categories.data &&
      categories.data.forEach((item: any, index: number) => {
        const text = getCategoryLabel(item);
        categoryList[item.categoryId] = {
          text,
          status: item.categoryId,
        };
      });

    setCategoryList(categoryList);
  };

  const fecthEventById = async (eventId: string) => {
    const eventDetail = await eventsApi.getEventDetail(eventId);
    setSelectedRows(eventDetail.data);
  };

  const fetchData = (newFilterState?: any): Promise<any> => {
    setFormLoading(true);
    return formatEventData(newFilterState);
  };

  const onSubmit = () => {
    onSelectItem(selectedRowsState?.eventId);

    setPopUpVisible(false);
    getCategoryById(selectedRowsState.categoryId);
  };

  const getCategoryById = (categoryId: string) => {
    return categoryId ? categoryList[categoryId].text : '-';
  };

  return (
    <div style={{ width: '100%', alignSelf: 'flex-start' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {selectedRowsState && (
          <span style={{ marginRight: 15 }}>{`${selectedRowsState.i18nDataList[0].name} | ${getCategoryById(
            selectedRowsState.categoryId,
          )} | ${selectedRowsState.eventStatus}`}</span>
        )}

        {!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" style={{ marginTop: 15 }} onClick={onSelect}>
            {Translation('deeplink.select.event.label')}
          </Button>
        )}

        {!!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" style={{ marginTop: 15 }} onClick={onSelect}>
            {Translation('deeplink.clear.select.event.label')}
          </Button>
        )}
      </div>

      <Dialog open={popUpVisible} onClose={onCancel} fullScreen>
        <>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div style={{ padding: 20 }}>
            <ProTable
              rowKey="key"
              filterTitle={'Select Event Item'}
              headerTitle={''}
              columns={columns(Translation, categoryList)}
              request={(params, sorter, filter) => {
                return fetchData(params);
              }}
              toolBarRender={() => []}
              rowSelection={{
                onChange: (_: any, selectedRows: any) => {
                  setSelectedRows(selectedRows[0]);
                },
                getCheckboxProps: (record: any) => {
                  return {
                    disabled: !['Upcoming', 'On-going'].includes(record.eventStatus),
                  };
                },
                selectItems: [selectedRowsState],
              }}
              singleSelect={true}
              pageSize={20}
            />
          </div>

          <div className={classes.modalButtons}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('app.button.cancel')}
            </Button>
            <Button onClick={onSubmit} style={{ marginLeft: 20 }} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default SpecificDeeplinkList;
