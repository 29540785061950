import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { regionLocale } from 'src/app/i18n';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const getNameFromI18nData = (code: string, row: any) => {
  const data = row.i18nDataList.find((item: any) => {
    return item.lang === code;
  });

  return data?.name;
};

const getNameColumns = (Translation: any) => {
  let namesColumns = regionLocale.map((code) => {
    const lang = Translation('navBar.lang.' + code);
    const nameColumn = {
      title: `${Translation('component.formLabel.category')} (${Translation(`navBar.lang.${code}`)})`,
      prop: `i18n_name_${code}`,
      sorter: true,
      render: (row: any) => <span>{getNameFromI18nData(code, row)}</span>,
    };

    return nameColumn;
  });

  return namesColumns;
};

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  const namesColumns = getNameColumns(Translation);

  return [
    ...namesColumns,
    {
      title: Translation('title.event.category.table.header.description'),
      prop: 'description',
    },
    {
      title: Translation('title.event.category.table.header.weight'),
      prop: 'weight',
      sorter: true,
    },
    {
      title: Translation('title.event.category.table.header.status'),
      sorter: true,
      prop: 'status',
      render: (row: any) => (
        <span>
          {row.status === 1
            ? Translation('component.formSelectItem.active')
            : Translation('component.formSelectItem.inactive')}
        </span>
      ),
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      sorter: true,
      prop: 'modifyTime',
      render: (row: any) => <span>{getDefaultDisplayDate(row.modifyTime, 'datetime')}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      prop: 'modifier',
    },
  ];
};

export default tableConfig;
