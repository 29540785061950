import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Link, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { AttachmentDef } from 'src/app/common/types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { downloadAs, downloadBase64File, getFullName } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import {
  SecretaryStatusEnum,
  SecretaryStatusReasonEnum,
  UpdateSecretaryActionEnum,
  SecretaryApprovalEnum,
  AccessActionEnum,
  SecretaryItem,
  SecretaryTypeItem,
  SecretaryPermissionItem,
  DependentPermissionItem,
} from '../../../types/secretary-types';
import {
  UpdateSecretaryBody,
  SecretaryApprovalBody,
  fetchSecretaryFile,
  modifySecretaryItem,
  approveRejectSecretaryItem,
} from '../../../network/secretaryCrud';
import { getPermissionNameFromKey } from '../../../utils/secretary-utils';
import { secretaryPath } from '../../SecretaryRoutes';

type SecretaryFormProps = {
  id: string;
  secretaryItem: SecretaryItem;
  secretaryTypes: SecretaryTypeItem[];
  dependentPermissions: DependentPermissionItem[];
  reloadData: () => void;
};

type DialogState = {
  open: boolean;
  file?: AttachmentDef;
  sensitive?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  section: {
    padding: 25,
    marginBottom: 32,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    position: 'relative',
  },
  basicInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: 25,
    marginLeft: 10,
  },
  documentContainer: {
    marginLeft: 10,
    marginBottom: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: 0,
  },
  actionContainer: {
    textAlign: 'center',
    position: 'absolute',
    right: 32,
    bottom: 32,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  accessActionContainer: {
    textAlign: 'right',
    marginBottom: 12,
  },
  dialogPaper: {
    width: 450,
  },
  label: {
    marginRight: 5,
  },
  spacing: {
    marginRight: 15,
  },
  button: {
    color: 'white',
    width: 100,
  },
  blueText: {
    color: '#1976D2',
  },
  reject: {
    backgroundColor: '#E8192C',
    '&:hover': {
      backgroundColor: '#A2111E',
    },
  },
  approve: {
    backgroundColor: '#29CC6A',
    '&:hover': {
      backgroundColor: '#1C8E4A',
    },
  },
  infoIcon: {
    padding: 5,
    color: '#666666',
  },
}));

const disableAccessConditions = [
  SecretaryStatusReasonEnum.APPLICATION_PENDING,
  SecretaryStatusReasonEnum.APPLICATION_REJECTED,
];

const SecretaryForm: FC<SecretaryFormProps> = ({
  id,
  secretaryItem,
  secretaryTypes,
  dependentPermissions,
  reloadData,
}) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, params?: Record<string, string | number>) => intl.formatMessage({ id }, params);
  const isApplicationPending =
    secretaryItem.secretaryStatus === SecretaryStatusEnum.INACTIVE &&
    secretaryItem.statusReason === SecretaryStatusReasonEnum.APPLICATION_PENDING;

  const [reminderDialog, setReminderDialog] = useState<DialogState>({ open: false });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedParents, setSelectedParents] = useState<SecretaryPermissionItem[]>([]);

  const checkHasChildren = (item: SecretaryPermissionItem) => !!(item.child && item.child.length > 0);

  const renderDownloadLink = (file: AttachmentDef, sensitive = false) => {
    return (
      <Link
        color="secondary"
        onClick={() => {
          setReminderDialog({ open: true, file, sensitive });
        }}
      >
        {file.filename || '-'}
      </Link>
    );
  };

  const handleConfirmDialog = async () => {
    if (reminderDialog.file) {
      const { blobId, filename, url, type } = reminderDialog.file;
      if (!reminderDialog.sensitive) {
        if (url) {
          downloadAs(url, filename, true);
        }
      } else {
        try {
          const fileBase64Str = await fetchSecretaryFile(id, blobId, dispatch);
          if (fileBase64Str === '') {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: `Document not found - ${filename}`,
                },
              ]),
            );
          } else {
            downloadBase64File(fileBase64Str, `${type || 'application/pdf'};base64`);
          }
        } catch (err) {}
      }
    }
    setReminderDialog({ open: false });
  };

  const handleApproveReject = async (status: SecretaryApprovalEnum) => {
    try {
      const body: SecretaryApprovalBody = {
        status,
      };
      await approveRejectSecretaryItem(id, body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content:
              status === SecretaryApprovalEnum.APPROVED
                ? Translation('secretary.common.approve_message')
                : Translation('secretary.common.reject_message'),
          },
        ]),
      );
      reloadData();
    } catch (err) {}
  };

  const checkInvalidPermission = (newPermissions: string[]) => {
    for (const dependent of dependentPermissions) {
      for (const child of dependent.child) {
        if (!newPermissions.includes(dependent.parent._id) && newPermissions.includes(child._id)) {
          return { parent: dependent.parent, child };
        }
      }
    }
    return null;
  };

  const handleAccess = async (action: AccessActionEnum) => {
    try {
      let newPermissions = [...(secretaryItem.permissions || [])];
      if (action === AccessActionEnum.ENABLE) {
        const filteredIds = selectedIds.filter((id) => !newPermissions.includes(id));
        newPermissions = [...newPermissions, ...filteredIds];
        const invalidPermission = checkInvalidPermission(newPermissions);
        if (invalidPermission) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Error',
                content: Translation('secretary.error.dependent_permission', {
                  parent:
                    getPermissionNameFromKey(invalidPermission.parent.name, Translation) ||
                    invalidPermission.parent.resource.toUpperCase(),
                  child:
                    getPermissionNameFromKey(invalidPermission.child.name, Translation) ||
                    invalidPermission.child.resource.toUpperCase(),
                }),
              },
            ]),
          );
          return;
        }
        // Auto add parent if any of the children are selected
        selectedParents.forEach((parent) => {
          if (!newPermissions.includes(parent._id)) {
            newPermissions.push(parent._id);
          }
        });
      } else {
        newPermissions = newPermissions.filter((id) => !selectedIds.includes(id));
        // Auto remove parent if all children are unselected
        selectedParents.forEach((parent) => {
          if (parent.child && parent.child.every((childItem) => !newPermissions.includes(childItem._id))) {
            newPermissions = newPermissions.filter((id) => id !== parent._id);
          }
        });
      }
      const body: UpdateSecretaryBody = {
        action: UpdateSecretaryActionEnum.UPDATE_PERMISSIONS,
        permissions: newPermissions,
      };
      await modifySecretaryItem(id, body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Access ${action === AccessActionEnum.ENABLE ? 'granted' : 'removed'}`,
          },
        ]),
      );
      setSelectedIds([]);
      reloadData();
    } catch (err) {}
  };

  return (
    <>
      <Dialog open={reminderDialog.open} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>{Translation('reminder_title')}</DialogTitle>
        <DialogContent>{Translation('secretary.common.reminder_content')}</DialogContent>
        <DialogActions>
          <Button className={classes.blueText} onClick={() => setReminderDialog({ open: false })}>
            {Translation('app.button.cancel')}
          </Button>
          <Button className={classes.blueText} onClick={() => handleConfirmDialog()}>
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <div className={classes.section} style={{ paddingBottom: isApplicationPending ? 110 : 25 }}>
          <div className={classes.headerContainer}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>{Translation('secretary.common.basic_information')}</div>
            </div>
            <Button variant="contained" onClick={() => history.push(secretaryPath)}>
              {Translation('app.button.back')}
            </Button>
          </div>
          <div className={classes.basicInfoContainer}>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.secretary_name')}:</div>
              <div>{getFullName(secretaryItem.firstName, secretaryItem.lastName)}</div>
            </div>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.pruforce_id')}:</div>
              <div>{secretaryItem.secretaryId}</div>
            </div>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.access_type')}:</div>
              <div>{secretaryTypes.find((item) => item.code === secretaryItem.type)?.name || secretaryItem.type}</div>
            </div>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.phone_number')}:</div>
              <div>{secretaryItem.phoneNumber}</div>
            </div>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.email')}:</div>
              <div>{secretaryItem.email}</div>
            </div>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.identity_number')}:</div>
              <div>{secretaryItem.identityNumber}</div>
            </div>
            <div className={`col-4 ${classes.rowContainer}`}>
              <div className={classes.label}>{Translation('secretary.common.status')}:</div>
              <div>{Translation(`secretary.common.secretary_status.${secretaryItem.secretaryStatus}`)}</div>
            </div>
            {secretaryItem.secretaryStatus !== SecretaryStatusEnum.ACTIVE && (
              <div className={`col-8 ${classes.rowContainer}`}>
                <div className={classes.label}>{Translation('secretary.common.status_reason')}:</div>
                <div>{Translation(`secretary.common.status_reason.${secretaryItem.statusReason}`)}</div>
              </div>
            )}
          </div>
          {isApplicationPending && (
            <div className={classes.actionContainer}>
              <div>
                <Button
                  className={`${classes.button} ${classes.reject}`}
                  variant="contained"
                  onClick={() => handleApproveReject(SecretaryApprovalEnum.REJECTED)}
                >
                  {Translation('section.common.operation.reject')}
                </Button>
              </div>
              <div>
                <Button
                  className={`${classes.button} ${classes.approve}`}
                  variant="contained"
                  onClick={() => handleApproveReject(SecretaryApprovalEnum.APPROVED)}
                >
                  {Translation('section.common.operation.approve')}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={classes.section}>
          <div className={classes.headerContainer}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>{Translation('secretary.common.documents')}</div>
            </div>
          </div>
          <div className={classes.documentContainer}>
            <div>{Translation('secretary.common.identity_document')}:</div>
            {secretaryItem.identityDocuments.map((item, index) => (
              <div key={`identity-document-${index}`}>{renderDownloadLink(item, true)}</div>
            ))}
          </div>
          <div className={classes.documentContainer}>
            <div>{Translation('secretary.common.profile_photo')}:</div>
            <div>
              {renderDownloadLink({
                ...secretaryItem.profilePicture,
                filename: secretaryItem.profilePicture.filename || `${secretaryItem.firstName}_profile`,
              })}
            </div>
          </div>
          <div className={classes.documentContainer} style={{ marginBottom: 0 }}>
            <div>{Translation('secretary.common.secretary_contract')}:</div>
            {secretaryItem.contractDocuments.map((item, index) => (
              <div key={`contract-document-${index}`}>{renderDownloadLink(item, true)}</div>
            ))}
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.headerContainer} style={{ marginBottom: 0 }}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>
                <span>{Translation('secretary.common.feature_access_permission')}</span>
                <Tooltip
                  arrow
                  placement="top"
                  title={Translation('secretary.common.feature_access_permission.tooltip')}
                >
                  <IconButton size={'small'} className={classes.infoIcon}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.accessActionContainer}>
              <Button
                disabled={selectedIds.length === 0}
                className={classes.spacing}
                variant="contained"
                color="secondary"
                onClick={() => handleAccess(AccessActionEnum.DISABLE)}
              >
                {Translation('secretary.common.disable_access')}
              </Button>
              <Button
                disabled={selectedIds.length === 0}
                variant="contained"
                color="secondary"
                onClick={() => handleAccess(AccessActionEnum.ENABLE)}
              >
                {Translation('secretary.common.enable_access')}
              </Button>
            </div>
            <PruTable
              disablePagination
              disableToolbar
              disableBulkSelect={disableAccessConditions.includes(secretaryItem.statusReason)}
              renderChildren
              headerBtnDef={[]}
              operationDef={[]}
              columnDef={[
                {
                  keyIndex: 'module',
                  displayName: Translation('secretary.common.feature_access_permission.module'),
                  renderData: (row, _, parent) =>
                    parent
                      ? getPermissionNameFromKey(parent.name, Translation) || parent.resource.toUpperCase() || '-'
                      : getPermissionNameFromKey(row.name, Translation) || row.resource.toUpperCase() || '-',
                },
                {
                  keyIndex: 'feature',
                  displayName: Translation('secretary.common.feature_access_permission.feature'),
                  renderData: (row) =>
                    checkHasChildren(row)
                      ? '-'
                      : getPermissionNameFromKey(row.name, Translation) || row.resource.toUpperCase() || '-',
                },
                {
                  keyIndex: 'accessType',
                  displayName: Translation('secretary.common.access_type'),
                  renderData: (row) => (checkHasChildren(row) ? '-' : row.action.toUpperCase() || '-'),
                },
                {
                  keyIndex: 'permission',
                  displayName: Translation('secretary.common.feature_access_permission.permission'),
                  renderData: (row) =>
                    checkHasChildren(row) ? '-' : secretaryItem.permissions?.includes(row._id) ? 'On' : 'Off',
                },
                {
                  isId: true,
                  hidden: true,
                  keyIndex: '_id',
                  childKeyIndex: 'child',
                  displayName: '',
                  renderData: () => '',
                },
              ]}
              isLoading={false}
              dataSource={secretaryItem.fullPermissionList}
              bulkSelectCheckboxDisable={(rowData) => !rowData.selectable}
              currentSelectedRow={(rowData, parent) => {
                setSelectedIds(rowData.map((item: SecretaryPermissionItem) => item._id));
                if (parent) {
                  setSelectedParents([...selectedParents, parent]);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecretaryForm;
