import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { getDayStart, getDayEnd, ignoreTimezone, getDayStartForStrapi } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  BannerPaginateList,
  BannerItem,
  BannerEditItem,
  BannerPublishedMode,
  BannerValidMode,
  BannerListParam,
} from '../types/operation-type';

const qs = require('qs');

export const fetchBannerList = async (
  locale: string,
  params: BannerListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<BannerPaginateList> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::banner.banner?`;
  // Query for publish and valid status filter
  queryPath += filterQueryForPublishAndValid(params['isPublish'], params['isValid'], queryPath);

  // Query for other filter
  forEach(params, (param, key) => {
    if (key === 'name') {
      var escapedParam = (param as string).replace(/([!@#$%^&*()+=\[\]\\';,./{}|":<>?~_-])/g, '\\$1');
      // queryPath += param ? `${key}_contains=${escapedParam}&` : '';
      queryPath += param ? `filters[${key}][$containsi]=${escapedParam}&` : '';
    } else if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('publishdatestart') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][lastPublishTime_gte]=${getDayStartForStrapi(param)}&`;
          queryPath += `filters[lastPublishTime][$gte]=${getDayStartForStrapi(param)}&`;
        } else if (key.toLowerCase().includes('publishdateend') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][lastPublishTime_lte]=${ignoreTimezone(getDayEnd(param))}&`;
          queryPath += `filters[lastPublishTime][$lte]=${ignoreTimezone(getDayEnd(param))}&`;
        }
      }
    } else if (key !== 'isPublish' && key !== 'isValid') {
      if (typeof param === 'string') {
        // queryPath += param ? `${key}_contains=${param}&` : '';
        queryPath += param ? `filters[${key}][$containsi]=${param}&` : '';
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `_sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:DESC`;
  }

  //Query for current language
  queryPath += `&_locale=${locale}`;

  return apiClient
    .get(queryPath)
    .then((response) => {
      return {
        bannerList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchBannerItem = (id: string, dispatch?: Dispatch<any>): Promise<BannerItem> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::banner.banner/${id}`;
  return apiClient
    .get<BannerItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewBanner = async (
  data: BannerEditItem,
  locale: string,
  relatedId?: string,
  dispatch?: Dispatch<any>,
): Promise<BannerItem> => {
  let createURL = `${ADMIN_URL}/content-manager/collection-types/api::banner.banner?plugins[i18n][locale]=${locale}`;
  if (relatedId) {
    createURL += `&plugins[i18n][relatedEntityId]=${relatedId}`;
  }

  return apiClient
    .post<BannerItem>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyBanner = async (
  code: string,
  data: BannerEditItem,
  dispatch?: Dispatch<any>,
): Promise<BannerItem> => {
  const modifyURL = `${ADMIN_URL}/content-manager/collection-types/api::banner.banner/${code}`;
  return apiClient
    .put<BannerItem>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishBanner = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const publishURL = `${ADMIN_URL}/content-manager/collection-types/api::banner.banner/${dataId}/actions/publish`;
  return apiClient
    .post<void>(
      publishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishBanner = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const unpublishURL = `${ADMIN_URL}/content-manager/collection-types/api::banner.banner/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(
      unpublishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const filterQueryForPublishAndValid = (
  isPublishParam: string = '',
  isValidParam: string = '',
  queryPath: string,
): string => {
  let extendQuery: string = '';
  if (isPublishParam === BannerPublishedMode.PUBLISHED) {
    switch (isValidParam) {
      case BannerValidMode.VALID:
        extendQuery =
          `_publicationState=live&` +
          `_where[0][_or][0][effectiveDate_lte]=${ignoreTimezone(
            getDayStart(new Date()),
          )}&_where[0][_or][1][effectiveDate_null]=true&` +
          `_where[1][_or][0][expiredDate_gte]=${ignoreTimezone(
            getDayEnd(new Date()),
          )}&_where[1][_or][1][expiredDate_null]=true&`;
        break;
      case BannerValidMode.INVALID:
        extendQuery =
          `_publicationState=live&` +
          `_where[0][_or][0][effectiveDate_gt]=${ignoreTimezone(
            getDayStart(new Date()),
          )}&_where[0][_or][1][expiredDate_lt]=${ignoreTimezone(getDayEnd(new Date()))}&`;
        break;
      case BannerValidMode.ALL:
      default:
        extendQuery = `_publicationState=live&`;
        break;
    }
  } else if (isPublishParam === BannerPublishedMode.UNPUBLISHED) {
    switch (isValidParam) {
      case BannerValidMode.VALID:
        extendQuery = `_publicationState=live&published_at_null=true&`;
        break;
      case BannerValidMode.INVALID:
        extendQuery =
          `_publicationState=preview&published_at_null=true&` +
          `_where[0][_or][0][effectiveDate_gt]=${ignoreTimezone(
            getDayStart(new Date()),
          )}&_where[0][_or][1][expiredDate_lt]=${ignoreTimezone(getDayEnd(new Date()))}&`;
        break;
      case BannerValidMode.ALL:
      default:
        extendQuery = `_publicationState=preview&published_at_null=true&`;
        break;
    }
  } else {
    switch (isValidParam) {
      case BannerValidMode.VALID:
        extendQuery =
          `_publicationState=live&` +
          `_where[0][_or][0][effectiveDate_lte]=${ignoreTimezone(
            getDayStart(new Date()),
          )}&_where[0][_or][1][effectiveDate_null]=true&` +
          `_where[1][_or][0][expiredDate_gte]=${ignoreTimezone(
            getDayEnd(new Date()),
          )}&_where[1][_or][1][expiredDate_null]=true&`;
        break;
      case BannerValidMode.INVALID:
        extendQuery =
          `_where[0][_or][0][effectiveDate_gt]=${ignoreTimezone(
            getDayStart(new Date()),
          )}&_where[0][_or][1][expiredDate_lt]=${ignoreTimezone(getDayEnd(new Date()))}&` +
          `_where[0][_or][2][published_at_null]=true&`;
        break;
      case BannerValidMode.ALL:
      default:
        break;
    }
  }
  return extendQuery;
};
