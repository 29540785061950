import { apiClient, ADMIN_URL } from 'src/app/common/network';

export const getCategoryList = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/events/categories`, { params });
};

export const addCategory = async (data?: any) => {
  return apiClient.post(`${ADMIN_URL}/events/categories`, data);
};

export const editCategory = async (data?: any) => {
  return apiClient.put(`${ADMIN_URL}/events/categories`, data);
};

export const weightValidation = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/events/categories/validation`, { params });
};

export const getAllCategory = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/events/categories/all`, { params });
};
