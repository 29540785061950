import React, { FC, useEffect, useMemo, useState, useRef } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AlarmIcon from '@mui/icons-material/AddAlarm';
import { useIntl } from 'react-intl';
import { PruForm } from 'src/app/common/components/PruForm';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { RootState } from 'src/redux/store';
import { LayoutSplashScreen } from 'src/app/layout';
import { EventFormMode, EventTypeOpts } from './constants';
import { EVENT_BASE_PATH, EVENT_LIST_PATH } from '../../../constants';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import TargetApplicationSection from 'src/app/common/components/TargetApplicantSection';
import { createBlob, getBlob, apiErrorHandler } from 'src/app/common/network';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { fileUpload } from 'src/app/common/utils';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import apis from './apis';
import { styles } from './styles';
import { getCategoryLabel } from '../../../network/utils';
import { regionLocale } from 'src/app/i18n';

const requiredRule = (message: string) => {
  return {
    required: true,
    message,
  };
};
const inactiveRule = (message: string) => {
  return {
    validator(rule: any, value: any, cb: any) {
      if (value === '-') {
        cb(message);
      } else {
        cb();
      }
    },
  };
};

interface EventDetailPageProps {
  enableUpdate: boolean;
}
const EventDetailPage: FC<RouteComponentProps & EventDetailPageProps> = ({ history, enableUpdate }) => {
  const { classes } = styles();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const formRef = useRef<any>();
  const This = useRef<any>({});
  const sessionFormRef = useRef<any>();
  const sectionRef = useRef<any>();
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(() => {
    let formMode;
    if (window.location.href.endsWith(`/create`)) {
      formMode = EventFormMode.CREATE;
    } else if (window.location.href.endsWith(`/view/${id}`)) {
      formMode = EventFormMode.VIEW;
    } else if (window.location.href.endsWith(`/edit/${id}`)) {
      formMode = EventFormMode.EDIT;
    } else if (window.location.href.endsWith(`/copy/${id}`)) {
      formMode = EventFormMode.COPY;
    }
    return {
      formMode,
      code: id ? id : undefined,
    };
    // eslint-disable-next-line
  }, [window.location.href]);

  const intl = useIntl();
  const Translation = (idd: string) => {
    return intl.formatMessage({ id: idd, defaultMessage: idd });
  };
  const e = (s: string) => {
    return intl.formatMessage({ id: 'event.list.detail.' + s.replace(/ /g, '_').toLowerCase(), defaultMessage: s });
  };
  // const lang = { hk: 'zhHk', en: 'enUs' }[useLang() as string]
  // eslint-disable-next-line
  const lang = useLang();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [i18nConfig, setI18nConfig] = useState<string[]>([])
  // const [i18nConfig, setI18nConfig] = useState<{code:string, name:string}[]>([]);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [sessionsData, setSessionsData] = useState<{ [key: string]: any }[]>([]);
  // const [sessionsData, setSessionsData] = useState<{[key: string]:any }>([{i18nDataList:{}}])
  const [sessionForm, setSessionForm] = useState<{ [key: string]: any }>({});

  const [categoryOpts, setCategoryOpts] = useState<{ label: string; value: any }[]>([]);
  const [categoryInActiveOpts, setCategoryInActiveOpts] = useState<{ label: string; value: any }[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showEditSession, setShowEditSession] = useState<boolean>(false);
  const [currentSessionIndex, setCurrentSessionIndex] = useState<number | null>(null);
  const [publishedTo, setPublishedTo] = useState<number | string | null>(null);
  const [publishImmediately, setPublishImmediately] = useState<number | null>(null);
  // eslint-disable-next-line
  const [publishOption, setPublishOption] = useState<number | null>(null);
  // eslint-disable-next-line
  const [publishStatus, setPublishStatus] = useState<number | null>(null);
  const [richContentInitDatas, setRichContentInitDatas] = useState<any[]>([]);
  const [richContentDatas, setRichContentDatas] = useState<any[]>([]);
  // because use two way bind will cause infinite rerender targetApplication in custom component
  const [roleSet, setRoleSet] = useState<any>();
  // eslint-disable-next-line
  const [roleSetInit, setRoleSetInit] = useState<any>();
  const [zoneSet, setZoneSet] = useState<any>();
  // eslint-disable-next-line
  const [zoneSetInit, setZoneSetInit] = useState<any>();
  const richContentStatic = useRef<any>();
  const [autoValidate, setAutoValidate] = useState<any>();
  const [sessionAutoValidate, setSessionAutoValidate] = useState<any>();
  useEffect(() => {
    setAutoValidate(false);
    const reset = formRef?.current?.reset;
    if (reset) {
      reset();
    }
  }, [publishOption, formRef]);

  useEffect(() => {
    checkOptsValid();
  }, [categoryInActiveOpts, formData.categoryId]);

  const mapI18nDataToState = (data: any) => {
    const newData: any = {};
    for (const key in data) {
      if (key === 'i18nDataList') {
        data[key].forEach((d: any) => {
          const lang = d.lang;
          for (const i18nDataKey in d) {
            newData[`i18nDataList.${lang}.${i18nDataKey}`] = d[i18nDataKey];
            if (i18nDataKey === 'coverage') {
              if (d[i18nDataKey] && !d[i18nDataKey].url) {
                newData[`i18nDataList.${lang}.${i18nDataKey}`] = null;
              }
            }
          }
        });
      } else {
        newData[key] = data[key];
      }
    }
    return newData;
  };
  const mapDataToState = (data: any) => {
    setRoleSetInit(data.roleList);
    setZoneSetInit(data.zoneList);
    const newRichContentDatas: any = [];
    (data.i18nDataList || []).forEach((d: any) => {
      newRichContentDatas.push(d.details);
    });
    setRichContentInitDatas(newRichContentDatas);
    setRichContentDatas(newRichContentDatas);
    richContentStatic.current = newRichContentDatas;
    const newSessionDatas: any[] = (data.sessionList || []).map((s: any) => {
      return mapI18nDataToState(s);
    });
    newSessionDatas.forEach((s) => {
      s.sessionDate = moment.utc(s.startTime).toDate(); // not use startDate, incomplete date may cause date string cross day
      s.startTime = moment.utc(s.startTime).toDate();
      s.endTime = moment.utc(s.endTime).toDate();
    });
    setSessionsData(newSessionDatas);
    This.current.sessionsData = newSessionDatas;

    const newFormData = mapI18nDataToState(data);
    setPublishStatus(newFormData.publishStatus);
    // newFormData.eventType = newFormData.eventType + ''

    if (newFormData.publishStatus + '' === '3') {
      newFormData.publishOption = '1';
      setPublishOption(1);
    } else {
      newFormData.publishOption = newFormData.publishStatus;
      setPublishOption(newFormData.publishStatus);
    }
    setPublishedTo(newFormData.receiverType);
    newFormData.publishedTo = newFormData.receiverType;
    if (newFormData.receiverType + '' === '1') {
      newFormData.customFile = newFormData.includeFile ? { ...(newFormData.includeFile || {}) } : null;
      newFormData.includeFile = null;
    }
    setPublishImmediately(newFormData.publishImmediately);

    setFormData(newFormData);
  };

  const fetchData = () => {
    if (!code) {
      return;
    }
    setIsLoading(true);
    apis
      .getEventDetail(id)
      .then((res) => {
        mapDataToState(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw apiErrorHandler(err, dispatch);
      });
  };
  // const loadI18nConfig = () => {
  //   apis.getI18nConfig().then(res => {
  //     if (Array.isArray(res.data)){
  //       setI18nConfig(res.data)
  //     }
  //   }).catch(err => {
  //     throw apiErrorHandler(err, dispatch)
  //   })
  // }
  const loadCategory = () => {
    apis.getEventCategories().then((res) => {
      const opts = [{ label: '-', value: '' }];
      const optsInactive = [{ label: '-', value: '' }];
      res.data?.forEach((c: any) => {
        if (c.status === 1) {
          opts.push({
            label: getCategoryLabel(c),
            value: c.categoryId,
          });
        }
        if (c.status === 0) {
          optsInactive.push({
            label: getCategoryLabel(c),
            value: c.categoryId,
          });
        }
      });
      setCategoryOpts(opts);
      setCategoryInActiveOpts(optsInactive);
    });
  };
  const checkOptsValid = () => {
    if (categoryInActiveOpts.length === 0) {
      return;
    }
    if (!formData.categoryId || formData.categoryId === '-') {
      return;
    }

    const inActiveOptIndex = categoryInActiveOpts.findIndex((p) => {
      return p.value === formData.categoryId;
    });
    if (inActiveOptIndex !== -1) {
      const inActiveOpt = categoryInActiveOpts[inActiveOptIndex];
      categoryOpts.push({
        label: inActiveOpt.label,
        value: '-',
      });
      setCategoryOpts(JSON.parse(JSON.stringify(categoryOpts)));

      onChangeValue('categoryId', '-');
      return;
    }
    const activeOptIndex = categoryOpts.findIndex((p) => {
      return p.value === formData.categoryId;
    });
    if (activeOptIndex === -1) {
      categoryOpts.push({
        label: formData.categoryId,
        value: '-',
      });
      setCategoryOpts(JSON.parse(JSON.stringify(categoryOpts)));
      onChangeValue('categoryId', '-');
    }
  };

  const uploadFileMockStatus = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
    setLoadPercentage: (p: number) => void;
  }) => {
    const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'public', module: 'event' }, dispatch);
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0];
    if (result) {
      return result;
    } else {
      throw new Error('upload failed');
    }
  };

  const uploadFileRealStatus = async ({
    file,
    fileName,
    fileType,
    description = '',
    setLoadPercentage,
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
    setLoadPercentage: (p: number) => void;
  }) => {
    const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'public', module: 'event' }, dispatch);
    await fileUpload(createBlobRes.url, file, setLoadPercentage);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0];
    if (result) {
      return result;
    } else {
      throw new Error('upload failed');
    }
  };

  useEffect(() => {
    // loadI18nConfig()
    loadCategory();
    moment_timezone.tz.guess(true); // clear cache
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [code]);

  const PruTableHeader = withStyles(TableCell, (theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  // eslint-disable-next-line
  const onChangeValue = (field: string, values: any) => {
    const newData = { ...formData };
    newData[field] = values;
    setFormData(newData);
  };

  const onConfirmSession = () => {
    const { validate } = sessionFormRef?.current;
    validate()
      .then((errs: any) => {
        if (!errs) {
          setShowEditSession(false);
          if (currentSessionIndex === null) {
            sessionsData.push(sessionForm);
            setSessionForm({});
          } else {
            sessionsData[currentSessionIndex] = sessionForm;
            setSessionForm({});
          }
          setSessionsData([...sessionsData]);
          This.current.sessionsData = sessionsData;
          if (This.current.autoValidate) {
            const { validate } = formRef?.current;
            validate();
          }
        } else {
          setSessionAutoValidate(true);
        }
      })
      .catch((err: any) => console.debug(err));
  };
  const onDownloadTemplate = () => {
    apis.getEventTemplate(dispatch);
  };
  const mapI18nData = (data: { [key: string]: any }) => {
    const newData: any = {};
    let i18nDataList: any = {};
    regionLocale.forEach((conf: any) => {
      i18nDataList[conf] = {
        lang: conf,
      };
    });

    for (const key in data) {
      if (key.indexOf('i18nDataList.') >= 0) {
        const temp = key.split('.');
        i18nDataList[temp[1]][temp[2]] = data[key];
      } else {
        newData[key] = data[key];
      }
    }
    // i18n
    newData.i18nDataList = [];
    for (const data in i18nDataList) {
      newData.i18nDataList.push(i18nDataList[data]);
    }
    return newData;
  };
  const mapData = (createData: any) => {
    if (formMode !== EventFormMode.EDIT) {
      createData.creator = user && user.username;
    }
    createData.modifier = user && user.username;
    createData.timeZone = moment_timezone.tz.guess();
    createData.eventType = parseInt(createData.eventType);
    createData.publishOption = parseInt(createData.publishOption);
    createData.publishStatus = undefined;
    createData.receiverType = parseInt(createData.publishedTo);
    if (createData.receiverType + '' === '1') {
      createData.includeFile = createData.customFile;
      createData.excludeFile = undefined;
      createData.roleList = undefined;
      createData.zoneList = undefined;
    } else if (createData.receiverType + '' === '0') {
      createData.roleList = roleSet;
      createData.zoneList = zoneSet;
    } else {
      createData.roleList = undefined;
      createData.zoneList = undefined;
      createData.includeFile = undefined;
      createData.excludeFile = undefined;
    }
    createData.customFile = undefined;

    createData.publishImmediately = String(createData.publishImmediately) === 'true' ? true : false;
    createData.i18nDataList.forEach((d: any, index: number) => {
      d.details = richContentDatas[index];
      d.coverage =
        d.coverage && d.coverage.url
          ? {
              url: d.coverage.url,
              fileName: d.coverage.fileName,
              id: d.coverage?.blobId || d.coverage?.id,
            }
          : null;
    });
    createData.sessionList.forEach((s: any) => {
      s.sessionDate = moment(s.sessionDate).format('YYYY-MM-DD');
      s.startTime = moment(s.startTime).format('HH:mm:ss');
      s.endTime = moment(s.endTime).format('HH:mm:ss');
    });
    if (createData.attachment) {
      createData.attachment = createData.attachment.map((a: any) => {
        return {
          url: a.url,
          id: a.blobId || a.id,
          fileName: a.fileName,
        };
      });
    }
  };
  const onSubmitForm = () => {
    const submit = () => {
      const createData: any = mapI18nData(formData);
      createData.sessionList = sessionsData.map((session: any) => mapI18nData(session));
      mapData(createData);

      setIsSubmitting(true);
      if (formMode === EventFormMode.CREATE || formMode === EventFormMode.COPY) {
        apis
          .createEvent(createData)
          .then((res) => {
            setIsSubmitting(false);
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved successfully.`,
                },
              ]),
            );
            history.push(EVENT_BASE_PATH + EVENT_LIST_PATH + '/list');
          })
          .catch((err) => {
            setIsSubmitting(false);
          });
      } else {
        apis
          .updateEvent(code as string, createData)
          .then((res) => {
            setIsSubmitting(false);
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved successfully.`,
                },
              ]),
            );
            history.push(EVENT_BASE_PATH + EVENT_LIST_PATH + '/list');
          })
          .catch((err) => {
            setIsSubmitting(false);
          });
      }
    };

    if (!publishOption && publishOption !== 0) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'warning',
            content: e('publish tip'),
          },
        ]),
      );
      return;
    } else if (publishOption + '' === '1') {
      const { validate } = formRef?.current;
      validate().then((errs: any) => {
        if (!errs) {
          submit();
          return;
        }
        console.error(errs);
        setAutoValidate(true);
      });
    } else {
      let hasName = false;
      regionLocale.forEach((c) => {
        hasName ||= !!formData[`i18nDataList.${c}.name`];
      });
      if (!hasName) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'warning',
              content: e('draft tip'),
            },
          ]),
        );
        return;
      }
      submit();
    }
  };
  const disabled = formMode === EventFormMode.VIEW;

  const sessionTableConfig = () => [
    {
      title: e('Session Name'),
      render: (row: any, index: number) => (
        <span>
          {regionLocale.map((c) => (
            <div>{row[`i18nDataList.${c}.sessionName`]}</div>
          ))}
        </span>
      ),
    },
    {
      title: e('Session Date Time'),
      render: (row: any, index: number) => (
        <span>{`${moment(row.sessionDate).format('DD/MM/YYYY')} ${moment(row.startTime).format('HH:mm')}-${moment(
          row.endTime,
        ).format('HH:mm')}`}</span>
      ),
    },
    {
      title: e('Venue'),
      render: (row: any, index: number) => (
        <span>
          {regionLocale.map((c) => (
            <div>{row[`i18nDataList.${c}.venue`]}</div>
          ))}
        </span>
      ),
    },
    {
      title: e('Action'),
      render: (row: any, index: number) => (
        <span>
          <Button
            onClick={() => {
              setShowEditSession(true);
              setSessionForm({ ...row });
              setCurrentSessionIndex(index);
              setSessionAutoValidate(false);
            }}
            disabled={disabled}
            className={classes.action}
          >
            {' '}
            {Translation('app.button.edit')}{' '}
          </Button>
          <Button
            onClick={() => {
              sessionsData.splice(index, 1);
              const newSessions = [...sessionsData];
              setSessionsData(newSessions);
              This.current.sessionsData = newSessions;
              if (This.current.autoValidate) {
                const { validate } = formRef?.current;
                validate();
              }
            }}
            disabled={disabled}
            className={classes.action}
          >
            {' '}
            {Translation('app.button.delete')}{' '}
          </Button>
        </span>
      ),
    },
  ];
  // const validateDate = useCallback((cb, value => {
  //   if(moment.isDate(sessionForm.sessionDate)) {
  //     cb()
  //   } else
  //     cb(new Error(e('valid date')))
  // }, [sessionForm])

  const sessionFormConfig = () => {
    let config: any[] = [];
    regionLocale.forEach((item, index) => {
      config = config.concat([
        [
          {
            type: 'custom',
            render: () => (
              <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{Translation(`navBar.lang.${item}`)}</div>
            ),
          },
        ],
        [
          {
            type: index === 0 || disabled ? 'hide' : 'custom',
            label: '',
            render: () => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => fillSessionForm(e.target.checked, item, index)}
                    name="copyCard"
                    color="secondary"
                    // checked={checked}
                  />
                }
                label={Translation('component.formSelectItem.use-card-above').replace(
                  '%s',
                  Translation(`navBar.lang.${regionLocale[0]}`),
                )}
              />
            ),
          },
        ],
        [
          {
            type: 'input',
            label: e('Name'),
            prop: `i18nDataList.${item}.sessionName`,
            rules: [{ required: true, message: e('required_field') }],
          },
        ],
        [
          {
            type: 'input',
            label: e('Venue'),
            prop: `i18nDataList.${item}.venue`,
            rules: [{ required: true, message: e('required_field') }],
          },
        ],
      ]);
    });

    return config.concat([
      [
        {
          type: 'date',
          label: e('Date'),
          format: 'DD/MM/YYYY',
          prop: 'sessionDate',
          rules: [
            requiredRule(e('required_field')),
            {
              validator(rule: any, value: any, cb: any) {
                if (moment(value).isValid()) {
                  cb();
                } else {
                  cb(e('validate date'));
                }
              },
            },
            {
              validator(rule: any, value: any, cb: any) {
                const a = moment(value).startOf('day').valueOf();
                const b = moment(new Date()).startOf('day').valueOf();
                if (a >= b) {
                  cb();
                } else {
                  cb(e('validate_time1'));
                }
              },
            },
          ],
        },
      ],
      [
        {
          type: 'time',
          label: e('Start Time'),
          prop: 'startTime',
          rules: [
            requiredRule(e('required_field')),
            {
              validator(rule: any, value: any, cb: any) {
                if (moment(value).isValid()) {
                  cb();
                } else {
                  cb(e('validate date'));
                }
              },
            },
            {
              validator(rule: any, value: any, cb: any) {
                const a = moment(sessionForm.sessionDate).startOf('day').valueOf();
                const b = moment(new Date()).startOf('day').valueOf();
                const c = moment(value).format('HH:mm:ss');
                const d = moment(new Date()).format('HH:mm:ss');
                console.debug(c);
                console.debug(d);
                if (!!sessionForm.sessionDate && a === b && c <= d) {
                  cb(new Error(e('validate time1')));
                } else {
                  cb();
                }
              },
            },
            {
              validator(rule: any, value: any, cb: any) {
                if (moment(value).isSameOrAfter(moment(sessionForm.endTime))) {
                  cb(e('validate time2'));
                } else {
                  cb();
                }
              },
            },
          ],
        },
      ],
      [
        {
          type: 'time',
          label: e('End Time'),
          prop: 'endTime',
          rules: [
            requiredRule(e('required_field')),
            {
              validator(rule: any, value: any, cb: any) {
                if (moment(value).isValid()) {
                  cb();
                } else {
                  cb(e('validate date'));
                }
              },
            },
            {
              validator(rule: any, value: any, cb: any) {
                if (moment(value).isBefore(moment(sessionForm.startTime))) {
                  cb(e('validate time2'));
                } else {
                  cb();
                }
              },
            },
          ],
        },
      ],
    ]);
  };

  // const render1 = useMemo(() => (
  //   <Tinymce disabled={disabled}
  //     onChange={v => onChangeValue(`${item.code}.details`, v)}
  //     value={formData[`${item.code}.details`]}
  //   />
  // ), [])
  const publishOptions = () => {
    if (formMode === EventFormMode.CREATE || formMode === EventFormMode.COPY) {
      return [
        { label: e('Save as Draft'), value: 0 },
        { label: e('Publish'), value: 1 },
        // { label: e('Unpublish'), value: 2 },
      ];
    } else {
      if (publishStatus === 1 || publishStatus === 2) {
        return [
          { label: e('Publish'), value: 1 },
          { label: e('Unpublish'), value: 2 },
        ];
      } else if (publishStatus === 0) {
        return [
          { label: e('Save as Draft'), value: 0 },
          { label: e('Publish'), value: 1 },
        ];
      } else {
        return [
          { label: e('Save as Draft'), value: 0 },
          { label: e('Publish'), value: 1 },
          { label: e('Unpublish'), value: 2 },
        ];
      }
    }
  };
  useEffect(() => {
    This.current.autoValidate = autoValidate;
  }, [autoValidate]);

  // 这个会被闭包缓存，如果让tinymce刷新，又会每次变化都失去焦点
  const onChangeContent = (index: number, value: any) => {
    // const newData = JSON.parse(JSON.stringify(richContentDatas))
    const newData = JSON.parse(JSON.stringify(richContentStatic.current || []));
    newData[index] = value;
    setRichContentDatas(newData);
    richContentStatic.current = newData;
    // formRef?.current?.onChangeValue
    if (This.current.autoValidate) {
      const { validate } = formRef?.current;
      validate();
    }
  };

  const fillForm = (checked: boolean, item: any, index: number) => {
    if (checked) {
      const newData = JSON.parse(JSON.stringify(formData));
      newData[`i18nDataList.${item}.name`] = newData[`i18nDataList.${regionLocale[0]}.name`];
      newData[`i18nDataList.${item}.coverage`] = newData[`i18nDataList.${regionLocale[0]}.coverage`];
      newData[`i18nDataList.${item}.tags`] = newData[`i18nDataList.${regionLocale[0]}.tags`];
      newData[`i18nDataList.${item}.enquiry`] = newData[`i18nDataList.${regionLocale[0]}.enquiry`];
      newData[`i18nDataList.${item}.fee`] = newData[`i18nDataList.${regionLocale[0]}.fee`];

      const newContent = JSON.parse(JSON.stringify(richContentStatic.current || []));
      newContent[index] = newContent[0];
      setRichContentDatas(newContent);
      setRichContentInitDatas(newContent);
      richContentStatic.current = newContent;
      setFormData(newData);
    }
  };
  const fillSessionForm = (checked: boolean, item: any, index: number) => {
    if (checked) {
      const newData = JSON.parse(JSON.stringify(sessionForm));
      newData[`i18nDataList.${item}.sessionName`] = newData[`i18nDataList.${regionLocale[0]}.sessionName`];
      newData[`i18nDataList.${item}.venue`] = newData[`i18nDataList.${regionLocale[0]}.venue`];

      setSessionForm(newData);
    }
  };

  const eventTypeOpts = [{ value: '', label: '-' }].concat(
    EventTypeOpts.map((opt: any) => {
      return {
        value: opt.value,
        label: e(opt.label),
      };
    }),
  );
  const maxFileSize = 20;
  const allowedFileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'mp4'];

  const formConfig = () => {
    return (
      [
        [{ type: 'header', label: e(`${formMode} Event`), sm: true }],
        [
          {
            type: 'select',
            label: e('Category'),
            prop: 'categoryId',
            opts: categoryOpts,
            sm: true,
            disabled,
            rules: [requiredRule(e('required_field')), inactiveRule(e('lost effect'))],
          },
        ],
        [
          {
            type: 'select',
            label: e('Event Type'),
            prop: 'eventType',
            opts: eventTypeOpts,
            sm: true,
            disabled,
            rules: [requiredRule(e('required_field'))],
          },
        ],
        [
          {
            type: 'custom',
            render: () => <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{e('Event Description')}</div>,
          },
        ],
      ] as any[][]
    )
      .concat(
        regionLocale.map((item, index) => {
          return [
            {
              type: 'accordion',
              sm: true,
              style: { boxShadow: 'none !important', padding: '0 20px', margin: '0 -20px' },
              // style:{padding: 0},
              defaultExpended: true,
              opts: { labelWidth: 150 },
              summary: [
                [
                  {
                    type: 'custom',
                    render: () => (
                      <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{Translation(`navBar.lang.${item}`)}</div>
                    ),
                  },
                ],
              ],
              details: [
                [
                  {
                    type: index === 0 || disabled ? 'hide' : 'custom',
                    label: '',
                    render: () => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => fillForm(e.target.checked, item, index)}
                            name="copyCard"
                            color="secondary"
                            // checked={checked}
                          />
                        }
                        label={Translation('component.formSelectItem.use-card-above').replace(
                          '%s',
                          Translation(`navBar.lang.${regionLocale[0]}`),
                        )}
                      />
                    ),
                  },
                ],
                [
                  {
                    type: 'input',
                    label: e('Name'),
                    prop: `i18nDataList.${item}.name`,
                    disabled,
                    rules: [requiredRule(e('required_field'))],
                  },
                ],
                [
                  {
                    type: 'uploader',
                    label: e('Cover page'),
                    prop: `i18nDataList.${item}.coverage`,
                    upload: uploadFileMockStatus,
                    maxFileSize: 20,
                    disabled,
                    showPreview: true,
                    allowedFileTypes: ['jpg', 'png', 'jpeg'],
                    // download: ()=>{},
                    showAllowText: Translation('event.list.detail.category_tips'),
                    // onChange: () => {}
                    rules: [requiredRule(e('required_field'))],
                  },
                ],
                [{ type: 'tags', label: e('Tags'), prop: `i18nDataList.${item}.tags`, disabled }],
                [
                  {
                    type: 'input',
                    label: e('Enquiry'),
                    prop: `i18nDataList.${item}.enquiry`,
                    disabled,
                    rules: [requiredRule(e('required_field'))],
                  },
                ],
                [{ type: 'input', label: e('Fee(HKD)'), prop: `i18nDataList.${item}.fee`, sm: true, disabled }],
                [
                  {
                    type: 'custom',
                    label: (
                      <span>
                        {e('Details')}
                        <span style={{ color: 'red' }}>*</span>
                      </span>
                    ),
                    prop: 'details',
                    render: () => (
                      <Tinymce
                        disabled={disabled}
                        onChange={(v) => onChangeContent(index, v)}
                        value={richContentInitDatas[index]}
                        module="event"
                      />
                    ),
                    rules: [
                      {
                        validator(rule: any, value: any, cb: any) {
                          if (!richContentDatas[index]) {
                            cb(e('required_field'));
                          } else {
                            cb();
                          }
                        },
                      },
                    ],
                  },
                ],
              ],
            },
          ];
        }),
      )
      .concat([
        [
          {
            type: 'multiuploader',
            label: e('Additional Attachment'),
            prop: 'attachment',
            upload: uploadFileRealStatus,
            maxFileSize,
            maxFileCount: 5,
            disabled,
            showPreview: true,
            download: () => {},
            showAllowText:
              useIntl().formatMessage(
                { id: 'component.formSelectItem.fielType' },
                {
                  formats: allowedFileTypes.join('/').toUpperCase(),
                  size: `${maxFileSize}M`,
                },
              ) +
              ' ' +
              e('5 attachments at most'),
            allowedFileTypes,
            // onChange: () => {}
          },
        ],
        [
          {
            type: 'accordion',
            sm: true,
            style: { boxShadow: 'none !important', padding: '0 20px', margin: '0 -20px' },
            defaultExpended: true,
            opts: { labelWidth: 150 },
            summary: [
              [
                {
                  type: 'custom',
                  render: () => (
                    <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                      {e('Sessions')}
                      <span style={{ color: 'red' }}>*</span>
                      <span style={{ fontSize: '0.85rem', fontWeight: 'normal' }}>
                        &nbsp;&nbsp;{e('Sessions Tips')}
                      </span>
                    </div>
                  ),
                },
              ],
            ],
            details: [
              [
                {
                  type: 'custom',
                  prop: 'sessions',
                  rules: [
                    {
                      validator(rule: any, value: any, cb: any) {
                        // if (sessionsDataStatic?.current?.length === 0 || !sessionsDataStatic?.current){
                        if (sessionsData.length === 0) {
                          cb(e('required_field'));
                        } else {
                          cb();
                        }
                      },
                    },
                  ],
                  render: () => (
                    <>
                      {!disabled && (
                        <div>
                          <Button
                            onClick={() => {
                              setShowEditSession(true);
                              setCurrentSessionIndex(null);
                              setSessionAutoValidate(false);
                            }}
                            variant="contained"
                            color="secondary"
                            style={{ float: 'right', marginBottom: 10 }}
                          >
                            <AddIcon />
                            {e('Add Session')}
                          </Button>
                        </div>
                      )}
                      <TableContainer component={Paper} style={{ minWidth: 700 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {sessionTableConfig().map((item: any) => (
                                <PruTableHeader
                                  key={item.prop}
                                  align={'center'}
                                  style={{ minWidth: item.minWidth || 140 }}
                                >
                                  {Translation(item.title) || item.title}
                                </PruTableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sessionsData.length === 0 && (
                              <TableRow>
                                <TableCell colSpan={12} align="center">
                                  <div style={{ margin: '30px 0 30px 0 ' }}>{e('No Session Found')}</div>
                                </TableCell>
                              </TableRow>
                            )}
                            {sessionsData.map((datas: any, index: number) => (
                              <TableRow>
                                {sessionTableConfig().map((config) => {
                                  return <TableCell align="center">{config.render(datas, index)}</TableCell>;
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ),
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'accordion',
            style: { boxShadow: 'none !important', padding: '0 20px', margin: '0 -20px' },
            defaultExpended: true,
            opts: { labelWidth: 150 },
            summary: [
              [
                {
                  type: 'custom',
                  render: () => <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}> {e('Publish Settings')} </div>,
                },
              ],
            ],
            details: (
              [
                [
                  {
                    type: 'radio',
                    label: e('Published To'),
                    prop: 'publishedTo',
                    sm: false,
                    onChange: setPublishedTo,
                    disabled,
                    opts: [
                      { label: e('By Segment'), value: 0 },
                      { label: e('Target Receiver(s)'), value: 1 },
                    ],
                    rules: [requiredRule(e('required_field'))],
                  },
                ],
                [
                  {
                    type: publishedTo + '' === '0' ? 'custom' : 'hide',
                    label: ' ',
                    render: () => (
                      <TargetApplicationSection
                        onRef={sectionRef}
                        isDisabled={disabled}
                        eligibleDesignations={roleSetInit || []}
                        eligibleZones={zoneSetInit || []}
                        eligibleAgents={[]}
                        excludedAgents={[]}
                        onToggleDesignation={setRoleSet}
                        onToggleZones={setZoneSet}
                      />
                    ),
                  },
                ],
              ] as any
            )
              .concat(
                publishedTo + '' === '0'
                  ? [
                      // [{type: 'custom', label: ' ', render: () => (
                      //   <TargetSection
                      //     onRef={sectionRef}
                      //     isDisabled={disabled}
                      //     eligibleDesignations={formData.roleSet || []}
                      //     eligibleZones={formData.zoneSet || []}
                      //     eligibleAgents={[]}
                      //     excludedAgents={[]}
                      //     onToggleDesignation={v => onChangeValue('roleSet', v)}
                      //     onToggleZones={v => onChangeValue('zoneSet', v)}
                      //   />
                      // )}],
                      [
                        {
                          type: 'uploader',
                          label: e('Extra Include'),
                          prop: 'includeFile',
                          styles: { marginLeft: 160 },
                          upload: uploadFileMockStatus,
                          maxFileSize: 10,
                          disabled,
                          download: () => {},
                          showAllowText: e('extra_include_tips'),
                          // allowedFileTypes: ['xlsx'],
                          // onChange: () => {}
                        },
                      ],
                      [
                        {
                          type: 'uploader',
                          label: e('Extra Exclude'),
                          prop: 'excludeFile',
                          styles: { marginLeft: 160 },
                          upload: uploadFileMockStatus,
                          maxFileSize: 10,
                          disabled,
                          download: () => {},
                          showAllowText: e('extra_exclude_tips'),
                          // allowedFileTypes: ['xlsx'],
                          // onChange: () => {}
                        },
                      ],
                      [
                        {
                          type: 'custom',
                          sm: true,
                          render: () => (
                            <Button color="secondary" style={{ marginLeft: 320 }} onClick={onDownloadTemplate}>
                              {Translation('component.notification.downloadTemplate')}
                            </Button>
                          ),
                        },
                      ],
                    ]
                  : publishedTo + '' === '1'
                  ? [
                      [
                        {
                          type: 'uploader',
                          label: Translation('component.formLabel.customized-receiver'),
                          prop: 'customFile',
                          width: 300,
                          upload: uploadFileMockStatus,
                          maxFileSize: 10,
                          disabled,
                          download: () => {},
                          // allowedFileTypes: ['xlsx'],
                          // onChange: () => {}
                          rules: [requiredRule(e('required_field'))],
                        },
                        {
                          type: 'custom',
                          sm: true,
                          render: () => (
                            <Button color="secondary" onClick={onDownloadTemplate}>
                              {Translation('component.notification.downloadTemplate')}
                            </Button>
                          ),
                        },
                      ],
                    ]
                  : [],
              )
              .concat([
                [
                  {
                    type: 'radio',
                    label: e('Published Option'),
                    prop: 'publishOption',
                    sm: false,
                    disabled,
                    opts: publishOptions(),
                    rules: [requiredRule(e('required_field'))],
                    onChange: setPublishOption,
                  },
                ],
                [
                  {
                    type: publishOption + '' === '1' ? 'radio' : 'hide',
                    label: e('Publish Time'),
                    prop: 'publishImmediately',
                    sm: false,
                    disabled,
                    onChange: setPublishImmediately,
                    opts: [
                      { label: e('Publish Immediately'), value: true },
                      { label: e('Publish at specific time'), value: false },
                    ],
                    rules: [requiredRule(e('required_field'))],
                  },
                ],
                [
                  {
                    type: publishOption + '' === '1' && publishImmediately + '' === 'false' ? 'datetime' : 'none',
                    label: e('Specific Time'),
                    prop: 'publishTime',
                    disabled,
                    minutesStep: 5,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <AlarmIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                    rules: [
                      {
                        required: publishOption + '' === '1' && publishImmediately + '' === 'false',
                        message: e('required_field'),
                      },
                      {
                        validator(rule: any, value: any, cb: any) {
                          if (!(publishOption + '' === '1' && publishImmediately + '' === 'false')) {
                            cb();
                          }
                          if (moment(value).isSameOrBefore(moment())) {
                            cb(e('validate_specific_time1'));
                          }
                          let firstSessionDate: moment.Moment | undefined;
                          (This.current.sessionsData ?? []).forEach((s: any) => {
                            const ds =
                              moment(s.sessionDate).format('YYYY-MM-DD') + ' ' + moment(s.startTime).format('HH:mm:ss');
                            const d = moment(ds, 'YYYY-MM-DD HH:mm:ss');
                            if (!firstSessionDate || firstSessionDate.isAfter(d)) {
                              firstSessionDate = d;
                              console.debug(firstSessionDate.isSameOrBefore(moment(value)));
                              console.debug(firstSessionDate);
                              console.debug(moment(value));
                            }
                          });
                          if (!!firstSessionDate && firstSessionDate.isSameOrBefore(moment(value))) {
                            cb(e('validate_specific_time2'));
                          } else {
                            cb();
                          }
                        },
                      },
                    ],
                  },
                ],
              ]),
          },
        ],
      ]);
  };

  return (
    <div className={classes.container}>
      {isLoading || regionLocale.length === 0 ? (
        <LayoutSplashScreen />
      ) : (
        <PruForm
          ref={formRef}
          data={formData}
          autoValidate={autoValidate}
          config={formConfig()}
          onChange={setFormData}
          style={{ padding: 20 }}
          opts={{ labelWidth: 150 }}
          displayType="flex"
        ></PruForm>
      )}
      {formMode !== EventFormMode.VIEW ? (
        <div className={classes.footerContainer}>
          <ButtonDialog
            onOk={() => {
              history.goBack();
            }}
            dialogTxt={Translation('component.hint.cancel-warning')}
            btnTxt={Translation('app.button.cancel')}
          />
          <Button
            disabled={isSubmitting}
            style={{ marginLeft: 25 }}
            variant="contained"
            color="secondary"
            onClick={() => onSubmitForm()}
          >
            {Translation('app.button.submit')}
            {isSubmitting && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
          </Button>
        </div>
      ) : (
        <div className={classes.footerContainer}>
          <Button
            disabled={!enableUpdate || isSubmitting}
            style={{ marginLeft: 25 }}
            variant="contained"
            onClick={() => history.push(EVENT_BASE_PATH + EVENT_LIST_PATH + '/edit/' + code)}
          >
            {Translation('app.button.edit')}
          </Button>
          <Button
            disabled={isSubmitting}
            style={{ marginLeft: 25 }}
            variant="contained"
            color="secondary"
            onClick={() => history.push(EVENT_BASE_PATH + EVENT_LIST_PATH + '/list')}
          >
            {Translation('app.button.back')}
          </Button>
        </div>
      )}
      {
        <Dialog open={showEditSession} onClose={() => setShowEditSession(false)} fullWidth maxWidth="md">
          <DialogTitle>{currentSessionIndex != null ? e('Edit Session') : e('Add Session')}</DialogTitle>
          {
            <div style={{ paddingLeft: 20, paddingRight: 80 }}>
              <PruForm
                ref={sessionFormRef}
                data={sessionForm}
                config={sessionFormConfig()}
                onChange={setSessionForm}
                style={{ padding: 20 }}
                opts={{ labelWidth: 100 }}
                displayType="flex"
                autoValidate={sessionAutoValidate}
              ></PruForm>
              <div style={{ display: 'flex', marginBottom: 40, justifyContent: 'center' }}>
                <Button style={{ marginLeft: 25 }} variant="contained" onClick={() => setShowEditSession(false)}>
                  {Translation('app.button.cancel')}
                </Button>
                <Button
                  style={{ marginLeft: 25 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => onConfirmSession()}
                >
                  {Translation('app.button.submit')}
                </Button>
              </div>
            </div>
          }
        </Dialog>
      }
    </div>
  );
};

export default EventDetailPage;
