import { apiErrorHandler } from '../network';
import { Dispatch } from 'react';
import axiosUtil from '../network/axios';
import axios from 'axios'; // this is for fn downloadAs, DO NOT use apiClient here

export function formatBytes(bytes: number, decimals = 2): { value: number; type: number } {
  if (bytes === 0) return { value: 0, type: 0 };
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  console.log('sizetype', sizes[i]);
  return {
    value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    type: i,
  };
}

export function downloadFile(
  fileName = '',
  url: string,
  params?: any | {},
  dispatch?: Dispatch<any>,
  fileType?: string,
) {
  function downloadURL(url: string, name = '') {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    if ('download' in document.createElement('a')) {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // 对不支持download进行兼容
      link.target = '_blank';
      click(link);
    }
  }
  // clone https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
  function click(node: any) {
    try {
      node.dispatchEvent(new MouseEvent('click'));
    } catch (e) {
      var evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);
      node.dispatchEvent(evt);
    }
  }

  return axiosUtil
    .request({ url, responseType: 'blob', params })
    .then((resp) => {
      const temp: BlobPart = resp.data || resp;
      const type = resp.headers['content-type'];
      return new Blob([temp], { type: resp.data?.type || type });
    })
    .then((blob) => {
      if ('msSaveOrOpenBlob' in navigator) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
      }
      return URL.createObjectURL(blob);
    })
    .then((url) => {
      if (!url) return;
      downloadURL(url, fileName);
      URL.revokeObjectURL(url);
    })
    .catch(async (err) => {
      const reader = new FileReader();

      reader.onload = function () {
        if (reader.result === '413') {
          const errObj = {
            statusCode: 413,
            // eslint-disable-next-line
            message: err.response,
            code: '413',
            errors: [],
          };

          throw apiErrorHandler(errObj, dispatch);
        }
      };
      reader.readAsText(err);
      return Promise.reject(err);
    });
}

/**
 * get file extending name by regex, return empty if not match.
 * e.g. a/b/c/xx.pdf will return '.pdf'
 * @param fileName
 * @returns
 */
export function getFileExtendingName(filename: string) {
  // regex for file extending name
  const reg = /\.[^\.]+$/;
  const matches = reg.exec(filename);
  if (matches) {
    return matches[0];
  }
  return '';
}

/**
 * download file by url, and rename file with provided name
 * @param url file url for download
 * @param name rename name
 */
export function downloadAs(url: string, name: string, openInNewTab = false) {
  // do NOT use apiClient(axios-utils) here
  axios
    .get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    })
    .then((response) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      if (openInNewTab) {
        a.target = '_blank';
      } else {
        a.download = name;
      }
      a.click();
    })
    .catch((err) => {
      console.log('download error: ', err);
    });
}

export const downloadBase64File = (fileBase64Str: string, fileType: string) => {
  const byteCharacters = atob(fileBase64Str);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: fileType });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const fileUpload = (url: string, file: File, setProgress?: (progress: number) => void) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const buffer = reader.result;
        axios
          .put(url, buffer, { headers: { 'x-ms-blob-type': 'BlockBlob', 'Content-Type': file.type } })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      };
      reader.readAsArrayBuffer(file);
    } catch (err) {
      console.log('fileUpload', err);
      reject(err);
    }
  });
};
/**
 * get file size
 * @param url
 * @returns size in KB
 */
export const getFileSize = (url: string) => {
  return axios
    .get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    })
    .then((response) => {
      if (response.status === 200) {
        const contentLength = response.headers['content-length'];
        // convert to KB
        return Math.round(parseInt(contentLength, 10) / 1024);
      }
      throw new Error('Failed to fetch file: ' + url);
    });
};
