import React, { useState, useEffect, useMemo } from 'react';
import Form from '../../../../../../../common/components/Form';
import DateTimeRangePicker from '../../../../../../../common/components/DateTimeRangePicker';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BannerFormState, RedirectType, OpenByType, CanBeShared, IsPublish } from '../../../../../types/operation-type';
import SubFormList from './SubFormList';
import moment from 'moment';
import { NumberGreaterThanZeroPattern, disableInputInvalidValueForWeight } from 'src/app/common/utils';
import { regionLocale, RegionLocale, regionLocaleMap } from 'src/app/i18n';
import SpecificEventDeeplink from 'src/app/common/components/SpecificDeeplinkList/event/index';
import SpecificSurveyDeeplinkList from 'src/app/common/components/SpecificDeeplinkList/survey/index';
import { Routes, SPECIFIC_ROUTES } from 'src/app/common/constants';
import { useSelector } from 'react-redux';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { RootState } from 'src/redux/store';
import SpecificTrainingDeeplink from 'src/app/common/components/SpecificDeeplinkList/training';
import { isObject, isEqual, isEmpty } from 'lodash';
import { CampaignListComponent } from 'src/app/common/components/SpecificDeeplinkList/campaign/campaign.component';
import { CampaignItem } from 'src/app/modules/PulseLeads/types/campaign-types';
import { getRouteConfig } from 'src/channelConfig/util/get-config.util';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
}));

interface AddRecordProps {
  disabled: boolean;
  onSave: (formData: BannerFormState) => void;
  onCancel: () => void;
  confirmLoading: boolean;
  initialValues: any;
  allWeights: any;
  id: string;
}

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  const expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

const Create: React.FC<AddRecordProps> = ({
  disabled,
  onSave,
  onCancel,
  confirmLoading,
  initialValues,
  allWeights,
  id,
}) => {
  const { classes } = useStyles();

  const [form] = Form.useForm();
  const regionLocales = regionLocale;
  const mainLocale = regionLocale[0] || RegionLocale.ENGLISH;
  const [panelExpand, setPanelExpand] = useState<any>(generateDefaultExpandPanel(regionLocales));
  const [deeplink, setDeepLink] = useState<Record<string, any> | string>('');
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignItem | null>(null);

  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const AppRoutes = getRouteConfig(user, 'Banner');
  const DEFAULT_REQUIRED_RULES = [{ required: true, message: Translation('component.form-required-text') }];
  const LINK_VIDATOR = [
    DEFAULT_REQUIRED_RULES[0],
    {
      validator(_: any, value: string) {
        if (value && (value.startsWith('https://') || value.startsWith('http://'))) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-link-text')));
      },
    },
  ];
  const WEIGHT_VIDATOR = [
    DEFAULT_REQUIRED_RULES[0],
    {
      validator(_: any, value: string) {
        const activeToActive =
          initialValues['isPublish'] === form.getFieldValue('isPublish') &&
          initialValues['isPublish'] === IsPublish['save-publish'];

        if (!value) {
          return Promise.reject(new Error(Translation('component.form-weight-text')));
        }
        if (!id && Object.values(allWeights).includes(Number(value))) {
          return Promise.reject(new Error(Translation('component.not.in.column.form-weight-diff-text')));
        }
        if (
          id &&
          activeToActive &&
          Number(value) !== allWeights[id] &&
          Object.values(allWeights).includes(Number(value))
        ) {
          return Promise.reject(new Error(Translation('component.not.in.column.form-weight-diff-text')));
        }

        if (id && !activeToActive && Object.values(allWeights).includes(Number(value))) {
          return Promise.reject(new Error(Translation('component.not.in.column.form-weight-diff-text')));
        }
        return Promise.resolve();
      },
    },
    {
      pattern: NumberGreaterThanZeroPattern,
      message: Translation('recruit-library-weight-greater-zero'),
    },
  ];
  const DATE_VIDATOR = [
    DEFAULT_REQUIRED_RULES[0],
    {
      validator(_: any, value: string) {
        const linkType = form.getFieldValue('linkType');
        const linkTo = form.getFieldValue('linkTo');
        if (value && value[0] && value[1]) {
          if (moment(value[0]).isAfter(moment(value[1]))) {
            return Promise.reject(new Error(Translation('component.form-date-compare')));
          }
          if (linkTo === Routes.SPECIFIC_CAMPAIGN_LINK) {
            if (selectedCampaign) {
              const startDate = moment(selectedCampaign.startDate);
              const endDate = moment(selectedCampaign.endDate);
              if (moment(value[0]).isBefore(startDate)) {
                return Promise.reject(new Error(Translation('component.form-date-compare-campaign-start-date')));
              }
              if (moment(value[1]).isAfter(endDate)) {
                return Promise.reject(new Error(Translation('component.form-date-compare-campaign-end-date')));
              }
            }
          }
        } else if (linkTo === Routes.SPECIFIC_CAMPAIGN_LINK) {
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }
        return Promise.resolve();
      },
    },
  ];

  const onFinish = (values: any) => {
    const allValues = form.getFieldsValue(true);
    onSave(allValues);
  };

  const cancel = () => {
    onCancel();
  };

  const onSelectItem = (id: any) => {
    form.setFieldsValue({ linkParams: { id: id } });
    form.setFieldsValue({ linkParamsField: id });
  };

  const onSelectCampaignItem = (campaign: CampaignItem) => {
    form.setFieldsValue({ linkParams: { campaignId: campaign._id } });
    form.setFieldsValue({ linkParamsField: campaign._id });
    form.setFieldsValue({ effectiveRange: [campaign.startDate, campaign.endDate] });
    setSelectedCampaign(campaign);
  };

  const handleChange = (locale: string) => (event: any, isExpanded: boolean) => {
    setPanelExpand({ ...panelExpand, [locale]: isExpanded });
  };

  const copyMainLangVersion = (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.checked) {
      const allValues = form.getFieldsValue(true);
      const to = locale;
      const from = mainLocale;
      const fromValue = allValues[from];
      const result = { ...allValues, [to]: { ...fromValue } };
      form.setFieldsValue(result);
    }
  };

  const onSaveDraft = () => {
    const formData = form.getFieldsValue(true);
    const validationList = regionLocales.map((localeItem) => {
      return [localeItem, 'name'];
    });
    form
      .validateFields(validationList)
      .then((res) => {
        onSave(formData);
      })
      .catch((err) => {
        const { errorFields } = err;
        if (errorFields.length < 1) {
          onSave(formData);
        }
      });
  };

  const placeEnter = Translation('app.input.placeholder.please-enter');

  const linkTypeTransMap = {
    no: Translation('component.formSelectItem.no-link'),
    app: Translation('component.formSelectItem.page-in-app'),
    external: Translation('component.formSelectItem.external-url'),
  };

  const shouldDispaly = (linkType: any, linkTo: any) => {
    return (
      linkType === RedirectType.app &&
      (linkTo === Routes.SPECIFIC_EVENT_LINK ||
        linkTo === Routes.SPECIFIC_TRAINING_LINK ||
        linkTo === Routes.SPECIFIC_SURVEY_LINK ||
        linkTo === Routes.SPECIFIC_CAMPAIGN_LINK)
    );
  };
  const SPECIFIC_LINK_RULE = [
    {
      validator(_: any, value: string) {
        const linkType = form.getFieldValue('linkType');
        const linkTo = form.getFieldValue('linkTo');

        const linkParams = form.getFieldValue('linkParams');

        if (shouldDispaly(linkType, linkTo) && !linkParams?.id && !linkParams?.campaignId) {
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }

        return Promise.resolve();
      },
    },
  ];

  useEffect(() => {
    const link = initialValues.linkTo;
    const params = initialValues.linkParams;
    const isSpecificRoute = SPECIFIC_ROUTES.includes(link);

    if (!params || isEmpty(params) || isSpecificRoute) {
      setDeepLink(link);
    } else {
      setDeepLink(
        JSON.stringify({
          link,
          params,
        }),
      );
    }
  }, [initialValues]);

  const defaultEffectiveDate = useMemo(() => {
    return selectedCampaign ? [selectedCampaign.startDate, selectedCampaign.endDate] : [];
  }, [selectedCampaign]);

  return (
    <Form form={form} onFinish={onFinish} initialValues={{ ...initialValues }} className={classes.container}>
      <Form.Item name="linkType" label={Translation('component.formLabel.link-type')} rules={DEFAULT_REQUIRED_RULES}>
        <RadioGroup aria-label="linkType" name="linkType" style={{ display: 'flex', flexDirection: 'row' }}>
          {Object.entries(RedirectType).map(([label, value]) => {
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio
                    disabled={disabled}
                    onChange={() => {
                      form.setFieldsValue({ linkTo: '' });
                    }}
                  />
                }
                label={linkTypeTransMap[value]}
              />
            );
          })}
        </RadioGroup>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.linkType !== currentValues.linkType}>
        {(_, meta, { getFieldValue }) => {
          const linkType = getFieldValue('linkType');
          return linkType === RedirectType.app ? (
            <>
              <Form.Item name="linkTo" label={Translation('component.formLabel.url')} rules={DEFAULT_REQUIRED_RULES}>
                <>
                  <TextField
                    disabled={disabled}
                    select
                    margin="dense"
                    variant="outlined"
                    value={deeplink}
                    fullWidth
                    onChange={(e) => {
                      const currentValue: any = e.target.value;

                      let selectValue: any;

                      try {
                        selectValue = JSON.parse(currentValue);
                      } catch (e) {
                        selectValue = currentValue;
                      }

                      const selectedItem: any = Object.entries(AppRoutes).find(([label, value]: any) => {
                        const hasParams = isObject(selectValue);

                        if (typeof value != 'string' && hasParams) {
                          return value.link === selectValue.link && isEqual(value.params, selectValue.params);
                        }

                        return value === selectValue;
                      });

                      if (selectedItem && typeof selectedItem[1] !== 'string') {
                        form.setFieldsValue({ linkParams: null });
                        form.setFieldsValue({ linkParams: selectedItem[1].params });
                        form.setFieldsValue({ linkTo: selectedItem[1].link });

                        setDeepLink(
                          JSON.stringify({
                            link: selectedItem[1].link,
                            params: selectedItem[1].params,
                          }),
                        );
                      } else {
                        setDeepLink(currentValue);
                        form.setFieldsValue({ linkParams: null });
                        form.setFieldsValue({ linkTo: currentValue });
                      }
                    }}
                  >
                    {Object.entries(AppRoutes).map(([label, value]: any) => {
                      if (typeof value !== 'string') {
                        value = JSON.stringify(value);
                      }

                      return (
                        <MenuItem key={value} value={value}>
                          {Translation(label)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </>
              </Form.Item>
            </>
          ) : null;
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.linkType !== currentValues.linkType}>
        {(_, meta, { getFieldValue }) => {
          const linkType = getFieldValue('linkType');
          return linkType === RedirectType.external ? (
            <>
              <Form.Item name="linkTo" label={Translation('component.formLabel.url')} rules={LINK_VIDATOR}>
                <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth />
              </Form.Item>
              <Form.Item
                name="openBy"
                label={Translation('component.formLabel.open-by')}
                rules={DEFAULT_REQUIRED_RULES}
              >
                <RadioGroup aria-label="openBy" name="openBy" style={{ display: 'flex', flexDirection: 'row' }}>
                  {Object.entries(OpenByType).map(([label, value]) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio disabled={disabled} />}
                      label={Translation(`component.formSelectItem.${label}`)}
                    />
                  ))}
                </RadioGroup>
              </Form.Item>
            </>
          ) : null;
        }}
      </Form.Item>

      <Form.Item
        name="linkParamsField"
        label={' '}
        hideDot={true}
        rules={SPECIFIC_LINK_RULE}
        shouldUpdate={(prevValues, currentValues) => prevValues.linkTo !== currentValues.linkTo}
      >
        {(_: any, meta: any, { getFieldValue }: any) => {
          const linkType = getFieldValue('linkType');
          const linkTo = getFieldValue('linkTo');
          return (
            shouldDispaly(linkType, linkTo) && (
              <div>
                {linkTo === Routes.SPECIFIC_EVENT_LINK && (
                  <SpecificEventDeeplink
                    onSelectItem={onSelectItem}
                    selectId={initialValues?.linkParams?.id || ''}
                    isView={disabled}
                  />
                )}
                {linkTo === Routes.SPECIFIC_TRAINING_LINK && (
                  <SpecificTrainingDeeplink
                    onSelectItem={onSelectItem}
                    selectId={initialValues?.linkParams?.id || ''}
                    isView={disabled}
                  />
                )}
                {linkTo === Routes.SPECIFIC_SURVEY_LINK && (
                  <SpecificSurveyDeeplinkList
                    onSelectItem={onSelectItem}
                    selectId={initialValues?.linkParams?.id || ''}
                    isView={disabled}
                  />
                )}
                {linkTo === Routes.SPECIFIC_CAMPAIGN_LINK && (
                  <CampaignListComponent
                    onSelectItem={onSelectCampaignItem}
                    selectId={initialValues?.linkParams?.campaignId || ''}
                    isView={disabled}
                    onChange={setSelectedCampaign}
                  />
                )}
              </div>
            )
          );
        }}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.linkType !== currentValues.linkType || prevValues.openBy !== currentValues.openBy
        }
      >
        {(_, meta, { getFieldValue }) => {
          const linkType = getFieldValue('linkType');
          const openBy = getFieldValue('openBy');
          return linkType === RedirectType.external && openBy === OpenByType['pruforce-webview'] ? (
            <>
              <Form.Item
                name="canShared"
                label={Translation('component.formLabel.can-be-shared')}
                rules={DEFAULT_REQUIRED_RULES}
              >
                <RadioGroup aria-label="canShared" name="canShared" style={{ display: 'flex', flexDirection: 'row' }}>
                  {Object.entries(CanBeShared).map(([label, value]) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio disabled={disabled} />}
                      label={Translation(`app.select.${label}`)}
                    />
                  ))}
                </RadioGroup>
              </Form.Item>
            </>
          ) : null;
        }}
      </Form.Item>
      {regionLocales.map((locale, index) => {
        return (
          <Accordion
            expanded={panelExpand[locale as string]}
            onChange={handleChange(locale)}
            style={{ marginBottom: 0, marginLeft: -20, marginRight: -20 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography className={classes.accordHeading}>{Translation(regionLocaleMap[locale].name)}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {index === 0 || disabled ? null : (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        copyMainLangVersion(e, locale);
                      }}
                      name="copyMainLangVersion"
                      color="secondary"
                    />
                  }
                  label={Translation('newsArticle.common.useMainLangVersionToFillIn', {
                    lang: Translation(regionLocaleMap[mainLocale].name),
                  })}
                />
              )}
              <SubFormList activeLang={locale} form={form} disabled={disabled} />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Form.Item name="weight" label={Translation('component.formLabel.weight')} rules={WEIGHT_VIDATOR} required={true}>
        <TextField
          type="number"
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={placeEnter}
          disabled={disabled}
          inputProps={disableInputInvalidValueForWeight()}
        />
      </Form.Item>
      <Form.Item
        required={false}
        name="effectiveRange"
        label={Translation('component.formLabel.effective-date')}
        rules={DATE_VIDATOR}
      >
        <DateTimeRangePicker disabled={disabled} defaultValue={defaultEffectiveDate} />
      </Form.Item>
      {!disabled ? (
        <Form.Item
          name="isPublish"
          label={Translation('component.formLabel.publish-now')}
          rules={DEFAULT_REQUIRED_RULES}
        >
          <RadioGroup aria-label="isPublish" name="isPublish" style={{ display: 'flex', flexDirection: 'row' }}>
            {Object.entries(IsPublish).map(([label, value]) => {
              return (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio disabled={disabled} />}
                  label={Translation(`component.formSelectItem.${label}`)}
                />
              );
            })}
          </RadioGroup>
        </Form.Item>
      ) : null}
      <div className={classes.footerContainer}>
        {!disabled ? (
          <>
            <Button variant="contained" color="inherit" onClick={cancel}>
              {Translation('app.button.cancel')}
            </Button>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.isPublish !== currentValues.isPublish}
            >
              {(_, meta, { getFieldValue }) => {
                const isPublish = getFieldValue('isPublish');
                return isPublish === IsPublish['save-publish'] ? (
                  <Button
                    disabled={confirmLoading}
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="secondary"
                    // onClick={() => {}}
                  >
                    {Translation('app.button.submit')}
                    {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
                  </Button>
                ) : (
                  <Button
                    disabled={confirmLoading}
                    onClick={onSaveDraft}
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="secondary"
                  >
                    {Translation('component.formSelectItem.save-as-draft')}
                    {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
                  </Button>
                );
              }}
            </Form.Item>
          </>
        ) : null}
      </div>
    </Form>
  );
};

export default Create;
