import { FC, PropsWithChildren } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Poppins'].join(','),
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#17c191',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#3783e7',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#f018a6',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    components: {
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false,
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'inherit',
        },
      },
      MuiPopover: {
        defaultProps: {
          elevation: 1,
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          margin: 'dense',
          size: 'small',
        },
      },
      MuiTypography: {
        defaultProps: {
          fontFamily: 'inherit',
        },
      },
    },
  },
);

export const MaterialThemeProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
