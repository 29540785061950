// Event Detail Constants

export enum EventFormMode {
  CREATE = 'add',
  EDIT = 'edit',
  COPY = 'copy',
  VIEW = 'view'
}

export const EventTypeOpts = [
  { value: 0, label: 'Virtual Event' },
  { value: 1, label: 'Traditional Event' }
]