import { isEmpty, cloneDeep } from 'lodash';
import { supportedLocales } from './survey-creator';
import { surveyLocalization } from 'survey-core';
import { Serializer } from 'survey-react';

export const panelProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const questionProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const pageProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const isPIIProperty = {
  name: 'isPII',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visibleIndex: 3,
  readOnly: true,
};

export const presetProperty = {
  name: 'preset',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visibleIndex: 1,
  readOnly: true,
};

export const disableChoicesProperty = {
  name: 'disableChoices',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visibleIndex: 2,
  readOnly: true,
};

export const isAutoCapitalizeProperty = {
  name: 'isAutoCapitalize',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const modalTitleProperty = {
  name: 'modalTitle',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
  isLocalizable: true,
};

export const surveyRequiredErrorTextProperty = {
  name: 'requiredErrorText',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visible: true,
  readOnly: false,
  isLocalizable: true,
};

export const surveyInvalidNumberErrorTextProperty = {
  name: 'invalidNumberErrorText',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visible: true,
  readOnly: false,
  isLocalizable: true,
};

export const contractMonthProperty = {
  name: 'contractMonth',
  type: 'number',
  default: null,
  isRequired: false,
  category: 'general',
  visible: true,
  readOnly: false,
  dependsOn: ['inputType'],
  visibleIf: function (obj: any) {
    return obj.name === 'contractMonth';
  },
};

export const triggerPopulateProperty = {
  name: 'triggerPopulate',
  type: 'itemvalues',
  default: {
    path: '',
    triggerKey: '',
    type: '',
  },
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const tilNowProperty = {
  name: 'tilNow',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const TnCUploaderProperty = {
  name: 'TnCUploader',
  displayName: 'TnC Uploader',
  type: 'TnCUploader',
  category: 'TnC Config',
  visibleIndex: 4,
  visible: true,
  default: {},
  isSerializable: false,
  onSetValue: (question: any, value: any) => {
    if (!question || isEmpty(question) || !value?.url) return;
    question.setPropertyValue('TnCUploader', value);
    let locale = question.getPropertyValue('TnCLocale');
    if (locale === 'en') locale = 'default';
    const url = value.url;
    const localeTemp = cloneDeep(question.localizableStrings.attachmentUrl);
    localeTemp.values[locale] = url;
    question.setPropertyValue('attachmentUrl', url);
    question.attachmentUrl = url;
    question.localizableStrings.attachmentUrl = localeTemp;
  },
};

export const attachmentUrlProperty = {
  name: 'attachmentUrl',
  displayName: 'attachmentUrl',
  visible: true,
  type: 'text',
  category: 'TnC Config',
  readOnly: true,
  isLocalizable: true,
  visibleIndex: 1,
};

export const TnCLocaleProperty = {
  name: 'TnCLocale',
  default: surveyLocalization.defaultLocale,
  choices: () => supportedLocales,
  onSetValue: (question: any, value: any) => {
    question.setPropertyValue('TnCUploader', {});
    question.setPropertyValue('TnCLocale', value);
    let locale = question.getPropertyValue('TnCLocale');
    if (locale === 'en') locale = 'default';
    const localeTemp = cloneDeep(question.localizableStrings.attachmentUrl);
    const attachmentUrl = question.localizableStrings.attachmentUrl?.values?.[locale] || '';
    question.setPropertyValue('attachmentUrl', attachmentUrl);
    question.attachmentUrl = attachmentUrl;
    localeTemp.values[locale] = attachmentUrl;
    question.localizableStrings.attachmentUrl = localeTemp;
  },
  category: 'TnC Config',
  visibleIndex: 2,
  isSerializable: false,
};

export const maxFileNumberProperty = {
  name: 'maxFileNumber',
  type: 'number',
  default: 5,
  isRequired: true,
  category: 'general',
  visible: false,
  isSerializable: true,
};

export const maxFileNumberErrorTextProperty = {
  name: 'maxFileNumberErrorText',
  type: 'string',
  default: {
    default: 'The maximum number of files for this item is 5. Please remove one or more files.',
    'zh-tw': '請最多上載5份文件或圖片。',
  },
  category: 'general',
  visible: false,
  isLocalizable: true,
  isSerializable: true,
};

export const acceptedTypesErrorTextProperty = {
  name: 'acceptedTypesErrorText',
  type: 'string',
  default: {
    default: 'This file type is not accepted. Please select another file.',
    'zh-tw': '系統並不支持此文件類型。請重新選擇文件。',
  },
  category: 'general',
  visible: false,
  isLocalizable: true,
  isSerializable: true,
};

export const inputTypeList = ['number', 'month', 'date', 'tel', 'year', 'email'];

export const enabledQuestionTypes = [
  'boolean',
  'file'
];
