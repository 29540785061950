import { Dispatch } from 'react';
import { preFillZero } from 'src/app/common/utils';
import { apiClient, CORE_URL, apiErrorHandler } from 'src/app/common/network';
import { ConfigAgentEditItem, ConfigAgentItem, ConfigAgentPaginateList, Configuration } from '../types/config-type';
import { setConfig } from '../_redux/configSlice';

export type ConfigAgentTypeParam = {
  agentCode?: string;
};

export const fetchConfigAgentList = async (
  params: ConfigAgentTypeParam,
  dispatch?: Dispatch<any>,
): Promise<ConfigAgentPaginateList> => {
  let queryPath = `${CORE_URL}/configz`;
  return apiClient
    .get<ConfigAgentPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
const CONFIGURATION: any = {
  Sales: {
    prospect: { disableEngagement: true },
  },
};
export const fetchConfigs = async (
  dispatch: Dispatch<any>,
  options?: { region: string; channel: string },
): Promise<void> => {
  let requestHeader;
  if (options?.region && options?.channel) {
    requestHeader = {
      region: options.region,
      channel: options.channel,
    };
  }
  const response: any = await apiClient.get<any>(`${CORE_URL}/configs/appConfigs?app=admin`, {
    headers: requestHeader,
  });

  let configs: Configuration = { ...CONFIGURATION };

  try {
    const rawData: any = response.data.data;
    for (let index = 0; index < rawData.length; index++) {
      const configGroup = rawData[index];
      configs = { ...configs, ...configGroup.content };
    }
    dispatch?.(setConfig(configs));
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
};

export const fetchConfigAgentItem = async (code: string, dispatch?: Dispatch<any>): Promise<ConfigAgentItem> => {
  let queryPath = `${CORE_URL}/configz/${preFillZero(String(code))}`;
  return apiClient
    .get<ConfigAgentItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyConfigAgent = async (
  code: string,
  body: ConfigAgentEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${CORE_URL}/configz/${code}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createConfigAgent = async (body: ConfigAgentEditItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${CORE_URL}/configz`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
