import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { dependentPermissionKeyList } from '../../constants';
import { SecretaryItem, SecretaryTypeItem, DependentPermissionItem } from '../../types/secretary-types';
import { fetchSecretaryItem, fetchSecretaryTypes } from '../../network/secretaryCrud';
import SecretaryForm from './components/SecretaryForm';

const SecretaryDetailPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secretaryItem, setSecretaryItem] = useState<SecretaryItem>();
  const [secretaryTypes, setSecretaryTypes] = useState<SecretaryTypeItem[]>([]);
  const [dependentPermissions, setDependentPermissions] = useState<DependentPermissionItem[]>([]);

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchSecretaryItem(id, dispatch);
        setSecretaryItem(res);
        if (res.fullPermissionList) {
          const newDependentPermissions: DependentPermissionItem[] = [];
          dependentPermissionKeyList.forEach((dependent) => {
            const parentPermissionItem = res.fullPermissionList?.find(
              (permission) => permission.name === dependent.parent,
            );
            if (parentPermissionItem && parentPermissionItem.child) {
              const childPermissionItems = parentPermissionItem.child.filter((childItem) =>
                dependent.child.includes(childItem.name),
              );
              newDependentPermissions.push({ parent: parentPermissionItem, child: childPermissionItems });
            }
          });
          setDependentPermissions(newDependentPermissions);
        }
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const reloadTypes = async () => {
    try {
      const res = await fetchSecretaryTypes(dispatch);
      setSecretaryTypes(res.types);
    } catch (err) {}
  };

  useEffect(() => {
    reloadTypes();
  }, []);

  useEffect(() => {
    reloadData();
  }, [id]);

  return (
    <>
      {isLoading || !secretaryTypes || !secretaryItem ? (
        <LayoutSplashScreen />
      ) : (
        <SecretaryForm
          id={id}
          secretaryItem={secretaryItem}
          secretaryTypes={secretaryTypes}
          dependentPermissions={dependentPermissions}
          reloadData={reloadData}
        />
      )}
    </>
  );
};

export default SecretaryDetailPage;
