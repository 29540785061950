import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import EventListingPage from './List';
import EventDetailPage from './Detail/DetailPage';
import { ModulePermissionProps } from 'src/app/common/module/types';

const EventListPage: FC<RouteComponentProps & ModulePermissionProps> = ({ 
    history, 
    location, 
    match, 
    enableRead,
    enableCreate,
    enableUpdate 
  }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <EventListingPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/view/:id`}
        render={(props) => (
          <EventDetailPage
            {...props}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/create`}
        component={EventDetailPage}
      />
      <PruRoute
        exact
        path={`${match.path}/copy/:id`}
        component={EventDetailPage}
      />
      <PruRoute
        exact
        path={`${match.path}/edit/:id`}
        component={EventDetailPage}
      />
    </Switch>
  )
};

export default EventListPage;
