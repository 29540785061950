import { Dispatch } from 'react';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { RecruitGenericItem } from '../types/recruit-generic-types';
import {
  ApplicationFormEditHistoryDetailParam,
  editHistoryParam,
  ApplicationFormEditHistoryListParam,
  PaginateList,
} from '../types/edit-history-types';
import { forEach } from 'lodash';

const applicationFormSettingEndPoint = 'application-form-setting';
const applicationFormEditHistoryEndPoint = 'application-form-edit-history';

export interface ApplicationFormSettingTemplateParam {
  templateId: string;
  content: any;
  version: string;
}

export const fetchApplicationFormSetting = async (
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<ApplicationFormSettingTemplateParam>> => {
  return apiClient
    .get<RecruitGenericItem<ApplicationFormSettingTemplateParam>>(`${ADMIN_URL}/${applicationFormSettingEndPoint}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateApplicationFormSetting = async (
  body: ApplicationFormSettingTemplateParam,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<ApplicationFormSettingTemplateParam>> => {
  return apiClient
    .post<RecruitGenericItem<ApplicationFormSettingTemplateParam>>(
      `${ADMIN_URL}/${applicationFormSettingEndPoint}`,
      body,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApplicationFormEditHistoryList = async (
  params: editHistoryParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ApplicationFormEditHistoryListParam>> => {
  let queryPath = `${ADMIN_URL}/${applicationFormEditHistoryEndPoint}/list?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<PaginateList<ApplicationFormEditHistoryListParam>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApplicationFormEditHistoryDetail = async (
  hisotryId: string,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<ApplicationFormEditHistoryDetailParam>> => {
  return apiClient
    .get<RecruitGenericItem<ApplicationFormEditHistoryDetailParam>>(
      `${ADMIN_URL}/${applicationFormEditHistoryEndPoint}/detail/${hisotryId}`,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
