import React, { useState, useRef, forwardRef, useEffect, useCallback, useImperativeHandle, PropsWithChildren } from 'react';
import RangePicker from './DateRangePicker';
import styles from './styles';
import { makeStyles } from 'tss-react/mui';
import {
  Grid,
  FormHelperText,
  Select,
  TextField,
  Accordion,
  CircularProgress,
  AccordionDetails,
  AccordionSummary,
  TextFieldProps,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { PruDateTimePicker, PruDatePicker, PruTimePicker } from 'src/app/common/components/PruDatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from 'react-intl';
import Schema, { RuleItem, ErrorList, FieldErrorList } from 'async-validator';
import FileUploader from 'src/app/common/components/FileUploader';
import MultiFileUploader, { MultiFileUploaderProps } from 'src/app/common/components/MultiFileUploader';
import Tags from './Tags';

export enum PruFormItemType {
  custom = 'custom',
  header = 'header',
  radio = 'radio',
  input = 'input',
  select = 'select',
  datetime = 'datetime',
  date = 'date',
  time = 'time',
  dateRange = 'dateRange',
  accordion = 'accordion',
  hide = 'hide',
  none = 'none',
  textRange = 'textRange',
  uploader = 'uploader',
  multiuploader = 'multiuploader',
  tags = 'tags',
}

interface PruFormAccordionItemConfig {
  summary?: PruFormItemConfig[][];
  details?: PruFormItemConfig[][];
  defaultExpended?: boolean; // for accordion
  displayType?: string; // for accordion
}
const ErrorBorder: React.FunctionComponent<PropsWithChildren<{ error: boolean }>> = ({ error, children }) => {
  const { classes } = styles();
  return <div className={error ? classes.error : ''}>{children}</div>;
};

export interface PruFormItemConfig extends PruFormAccordionItemConfig, Partial<MultiFileUploaderProps> {
  type: string;
  label?: string;
  grid?: number;
  params?: { [key: string]: any };
  prop?: string; // dataIndex
  props?: Array<any>; // dataIndex
  rules?: any; //RuleItem[]
  render?: () => any;
  opts?: { label: string; value: string | number }[];
  xs?: any;
  sm?: any;
  styles?: object;
  onChange?: (v: any) => void;
  style?: object;
  width?: number;
  disabled?: boolean;
  placeholder?: string;
}

export type PruFormData = {
  [dataIndex: string]: any;
};

type PruFormProps = {
  config: PruFormItemConfig[][];
  data: PruFormData;
  onChange: (data: PruFormData) => void;
  space?: number;
  rowClass?: string;
  style?: object;
  className?: string;
  opts?: any;
  displayType?: string;
  autoValidate?: boolean;
  showRequired?: boolean;
};

export const PruForm = forwardRef(
  (
    {
      config,
      data,
      space,
      onChange,
      style,
      className,
      opts,
      displayType,
      autoValidate = true,
      showRequired = true,
    }: PruFormProps,
    ref,
  ) => {
    const subforms = useRef<any>({});
    const This = useRef<any>({});
    const [rules, setRules] = useState<{ [key: string]: RuleItem[] }>({});
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    useEffect(() => {
      This.current.data = data;
    }, [data]);

    useEffect(() => {
      const newRules: any = {};
      config.forEach((items) => {
        items.forEach((item) => {
          if (item.rules && item.prop) {
            newRules[item.prop as string] = item.rules;
          }
        });
      });
      setRules(newRules);
    }, [config]);

    const validate = useCallback(async () => {
      const ps = [];
      ps.push(
        new Promise((resolve, reject) => {
          const validator = new Schema(rules);
          validator.validate(This.current.data, undefined, (Errors: ErrorList, fields: FieldErrorList) => {
            if (Errors) {
              const newErrorState: any = {};
              Errors?.forEach((error) => {
                newErrorState[error.field] = error.message;
              });
              setErrors(newErrorState);
              resolve(Errors);
            } else {
              setErrors({});
            }

            resolve(null);
            // validation passed
          });
        }),
      );
      for (const ref in subforms.current) {
        ps.push(subforms.current[ref].validate());
      }
      const errss = await Promise.all(ps);
      let errsRes: ErrorList = [];
      errss.forEach((errs) => {
        if (errs) {
          errsRes = errsRes.concat(errs);
        }
      });
      return errsRes.length === 0 ? null : errsRes;
      // eslint-disable-next-line
    }, [rules]);

    const reset = () => {
      console.debug('form reset');
      setErrors({});
      for (const ref in subforms.current) {
        subforms.current[ref].reset();
      }
      This.current.rules = {};
    };

    useImperativeHandle(ref, () => ({ validate, reset }));

    const { classes } = styles();
    const { classes: itemStyles } = makeStyles()((theme) => ({
      container: {
        marginRight: space || 10,
        minHeight: 50,
        '& .MuiFormHelperText-root.Mui-error': {
          color: 'red',
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: 'red',
        },
      },
    }))();
    const [accordionExpended, setAccordionExpended] = useState<{ [key: string]: boolean }>({});
    const Header = (title: string) => {
      return <span className={classes.pruheader}>{title}</span>;
    };
    const onChangeValue = (item: PruFormItemConfig, v: any) => {
      const newData = {
        ...data,
      };
      newData[item.prop as string] = v;
      if (autoValidate && item.rules && item.prop) {
        const itemRules: any = This.current.rules || {};
        itemRules[item.prop] = item.rules;
        const validator = new Schema(itemRules);
        validator.validate(newData, undefined, (Errors: ErrorList, fields: FieldErrorList) => {
          const newErrorState: any = {};
          Errors?.forEach((error) => {
            newErrorState[error.field] = error.message;
          });
          setErrors(newErrorState);
        });
      }
      onChange(newData);
      if (item.onChange) {
        item.onChange(v);
      }
    };

    const renderItem = (item: PruFormItemConfig, indexs: number, index: number): any => {
      const itemProp = item.prop as string;
      const labelWidth = opts?.labelWidth || 100;
      const required = showRequired && item.rules?.some((rule: any) => rule.required);
      switch (item.type) {
        // case PruFormItemType.none:
        //   return null
        case PruFormItemType.hide:
          return '';
        case PruFormItemType.custom:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              {
                <div style={{ width: '100%' }}>
                  <ErrorBorder error={!!(errors[itemProp] && errors[itemProp].length !== 0)}>
                    {item.render ? item.render() : ''}
                  </ErrorBorder>
                  {errors[itemProp] && (
                    <FormHelperText variant="standard" style={{ color: 'red' }} error>
                      {errors[itemProp]}
                    </FormHelperText>
                  )}
                </div>
              }
            </div>
          );
        case PruFormItemType.header:
          return Header(item.label as string);
        case PruFormItemType.radio:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <div style={{ width: '100%' }}>
                <ErrorBorder error={!!errors[itemProp]}>
                  <RadioGroup
                    value={data[itemProp] + ''}
                    onChange={(e) => onChangeValue(item, e.target.value)}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {item.opts?.map((opt: any) => (
                      <FormControlLabel
                        disabled={item.disabled}
                        // 用數字不能識別
                        value={opt.value + ''}
                        key={opt.value + ''}
                        control={<Radio />}
                        label={opt.label}
                      />
                    ))}
                  </RadioGroup>
                </ErrorBorder>
                {errors[itemProp] && (
                  <FormHelperText style={{ color: 'red' }} error>
                    {errors[itemProp]}
                  </FormHelperText>
                )}
              </div>
            </div>
          );
        case PruFormItemType.input:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              {
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={data[itemProp] || ''}
                  disabled={item.disabled}
                  fullWidth
                  error={!!errors[itemProp]}
                  helperText={errors[itemProp]}
                  onChange={(v) => onChangeValue(item, v.target.value)}
                  placeholder={item.placeholder}
                />
              }
            </div>

            // <PruFormItem item={item}>
            //   <TextField
            //     margin="dense"
            //     variant="outlined"
            //     value={data[itemProp] || ''}
            //     fullWidth
            //     error={!!errors[itemProp]}
            //     helperText={errors[itemProp]}
            //     onChange={v => onChangeValue(item, v.target.value)}
            //   />
            // </PruFormItem>
          );
        case PruFormItemType.select:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <div style={{ width: '100%' }}>
                {item.opts && item.opts.length > 0 ? (
                  <Select
                    margin="dense"
                    variant="outlined"
                    native
                    disabled={item.disabled}
                    error={!!errors[itemProp]}
                    value={String(data[itemProp] ?? '')}
                    onChange={(v) => {
                      onChangeValue(item, v.target.value);
                    }}
                    style={{ minWidth: 100 }}
                    inputProps={{
                      name: 'incentiveStatus',
                      id: 'filled-age-native-simple',
                    }}
                  >
                    {item.opts.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.label}
                        </option>
                      );
                    })}
                  </Select>
                ) : (
                  <span style={{ width: '166px' }}>
                    <CircularProgress size="small" style={{ width: '20px' }} />
                  </span>
                )}
                {errors[itemProp] && (
                  <FormHelperText style={{ color: 'red' }} error>
                    {errors[itemProp]}
                  </FormHelperText>
                )}
              </div>
            </div>
          );
        case PruFormItemType.datetime: {
          // eslint-disable-next-line
          const { minutesStep, ampm, disabled, format, InputProps } = item as any;
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <PruDateTimePicker
                minutesStep={minutesStep || 1}
                slotProps={{
                  textField: {
                    error: !!errors[itemProp],
                    helperText: errors[itemProp],
                  },
                }}
                ampm={ampm || false}
                disabled={item.disabled}
                format={format || 'DD/MM/YYYY HH:mm'}
                value={data[itemProp] || null}
                onChange={(date) => {
                  onChangeValue(item, date);
                }}
              />
            </div>
          );
        }
        case PruFormItemType.date: {
          const { format } = item as any;
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <PruDatePicker
                format={format || 'MM/DD/YYYY'}
                slotProps={{
                  textField: {
                    error: !!errors[itemProp],
                    helperText: errors[itemProp],
                  },
                }}
                value={data[itemProp] || null}
                disabled={item.disabled}
                onChange={(v) => {
                  onChangeValue(item, v);
                }}
              />
            </div>
          );
        }
        case PruFormItemType.time: {
          // const { defaultValue, format } = item as TextFieldProps
          const { format, ampm } = item as any;
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <PruTimePicker
                ampm={ampm || false}
                slotProps={{
                  textField: {
                    error: !!errors[itemProp],
                    helperText: errors[itemProp],
                  },
                }}
                format={format || 'HH:mm'}
                value={data[itemProp] || null}
                disabled={item.disabled}
                onChange={(v) => {
                  onChangeValue(item, v);
                }}
              />
            </div>
          );
        }
        case PruFormItemType.dateRange:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <RangePicker
                errors={[!!errors[item.prop + '.0'], !!errors[item.prop + '.1']]}
                helperTexts={[errors[item.prop + '.0'], errors[item.prop + '.1']]}
                value={data[itemProp] || []}
                disabled={item.disabled}
                onChange={(v) => onChangeValue(item, v)}
              ></RangePicker>
            </div>
          );
        case PruFormItemType.textRange: {
          const v = data[itemProp];
          const props1 = Array.isArray(item.props) ? item.props[0] : {};
          const props2 = Array.isArray(item.props) ? item.props[1] : {};
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <TextField
                margin="dense"
                variant="outlined"
                value={(v && v[0]) || ''}
                {...(props1 as TextFieldProps)}
                disabled={item.disabled}
                onChange={(e) => {
                  const v = e.target.value;
                  const ov = data[itemProp];
                  const nv = [v, ov ? ov[1] || '' : ''];
                  const nd = { ...data };
                  nd[itemProp] = nv;
                  onChange(nd);
                }}
              />
              -
              <TextField
                margin="dense"
                variant="outlined"
                value={(v && v[1]) || ''}
                {...(props2 as TextFieldProps)}
                disabled={item.disabled}
                onChange={(e) => {
                  const v = e.target.value;
                  const ov = data[itemProp];
                  const nv = [ov ? ov[0] || '' : '', v];
                  const nd = { ...data };
                  nd[itemProp] = nv;
                  onChange(nd);
                }}
              />
            </div>
          );
        }
        case PruFormItemType.uploader:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <div style={{ width: '100%' }}>
                <ErrorBorder error={!!errors[itemProp]}>
                  <FileUploader
                    maxFileSize={item.maxFileSize}
                    disabled={item.disabled}
                    upload={item.upload}
                    download={item.download}
                    value={data[itemProp]}
                    // eslint-disable-next-line
                    allowedFileTypes={item.allowedFileTypes}
                    showAllowText={item.disabled ? null : item.showAllowText}
                    onChange={(v) => onChangeValue(item, v)}
                  />
                </ErrorBorder>
                {errors[itemProp] && (
                  <FormHelperText style={{ color: 'red' }} error>
                    {errors[itemProp]}
                  </FormHelperText>
                )}
              </div>
            </div>
          );
        case PruFormItemType.multiuploader:
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <div style={{ width: '100%' }}>
                <ErrorBorder error={!!errors[itemProp]}>
                  <MultiFileUploader
                    maxFileSize={item.maxFileSize}
                    maxFileCount={item.maxFileCount}
                    disabled={item.disabled}
                    upload={item.upload}
                    download={item.download}
                    values={data[itemProp]}
                    // eslint-disable-next-line
                    allowedFileTypes={item.allowedFileTypes}
                    showAllowText={item.disabled ? null : item.showAllowText}
                    onChange={(v) => onChangeValue(item, v)}
                  />
                </ErrorBorder>
                {errors[itemProp] && (
                  <FormHelperText style={{ color: 'red' }} error>
                    {errors[itemProp]}
                  </FormHelperText>
                )}
              </div>
            </div>
          );
        case PruFormItemType.tags: {
          const tags = data[itemProp] || [];
          return (
            <div
              key={item.prop}
              className={itemStyles.container}
              style={{ display: displayType || 'inline-flex', alignItems: 'center', ...(item.styles || {}) }}
            >
              {item.label && (
                <span className="prulabel" style={{ minWidth: labelWidth }}>
                  {item.label}
                  {required && <span style={{ color: 'red' }}>*</span>}:
                </span>
              )}
              <Tags
                disabled={item.disabled}
                btnTxt={Translation('component.tag-list.add-btn')}
                value={tags}
                onChange={(v) => onChangeValue(item, v)}
              />
            </div>
          );
        }
        default:
          throw new Error(`ProForm config item type not defined: ${item.type}`);
      }
    };

    return (
      <div className={`${classes.pruform} ${className || ''}`} style={{ ...(style || {}) }}>
        {config.map((items, indexs) => {
          return (
            <Grid container key={indexs} style={{ padding: '5px 0', display: 'flex' }}>
              {items.map((item, index) => {
                const sm = item.xs || item.width ? false : item.sm === undefined ? true : item.sm;
                if (item.type === PruFormItemType.none) {
                  return null;
                } else if (item.type === PruFormItemType.accordion) {
                  const expended =
                    accordionExpended[`${indexs}_${index}`] === undefined
                      ? !!item.defaultExpended
                      : accordionExpended[`${indexs}_${index}`];
                  return (
                    <Grid xs={item.xs} sm={sm} style={{ width: item.width }}>
                      <Accordion expanded={expended} style={item.style || {}} className={classes.accordion}>
                        <AccordionSummary
                          style={{ padding: 0 }}
                          expandIcon={
                            <span
                              style={{ padding: 10, margin: -10 }}
                              onClick={() => {
                                accordionExpended[`${indexs}_${index}`] = !accordionExpended[`${indexs}_${index}`];
                                setAccordionExpended({ ...accordionExpended });
                                if (item.onChange) {
                                  item.onChange(!accordionExpended[`${indexs}_${index}`]);
                                }
                              }}
                            >
                              <ExpandMoreIcon />
                            </span>
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <PruForm
                            ref={(el) => (subforms.current[`${indexs}_${index}_0`] = el)}
                            className="subpruform"
                            config={item.summary as PruFormItemConfig[][]}
                            data={data}
                            onChange={onChange}
                            opts={item.opts}
                            displayType={item.displayType || displayType}
                            autoValidate={autoValidate}
                          ></PruForm>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, display: 'block' }}>
                          <PruForm
                            ref={(el) => (subforms.current[`${indexs}_${index}_1`] = el)}
                            className="subpruform"
                            config={item.details as PruFormItemConfig[][]}
                            data={data}
                            onChange={onChange}
                            opts={item.opts}
                            displayType={item.displayType || displayType}
                            autoValidate={autoValidate}
                          ></PruForm>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid xs={item.xs} sm={sm} style={{ width: `${item.width}px` }}>
                      {renderItem(item, indexs, index)}
                    </Grid>
                  );
                }
              })}
            </Grid>
          );
        })}
      </div>
    );
  },
);
