import React, { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { useIntl } from 'react-intl';

type PruTableEmptyRowProps = {
  isEmpty: boolean;
  type?: string
};

const PruTableEmptyRow: FC<PruTableEmptyRowProps> = ({ isEmpty, type }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    isEmpty ? (
      <TableRow>
        <TableCell colSpan={12} align="center">
          <div style={{ margin: '30px 0 30px 0 '}}>
            { type === "participants" ? Translation('agencyCampaign.create.noParticipants') : Translation("prutable.noRecord")}
          </div>
        </TableCell>
      </TableRow>
    ) : (
      <></>
    )
  )
};

export default PruTableEmptyRow;
