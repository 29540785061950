import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './notification-config-edit.style';
import { useNotificationConfigEdit } from './notification-config-edit.hook';
import { NotiTemplateFormComponent } from './component/noti-template-form/noti-template-form.component';
import { isEmptyArray } from 'formik';
import { AutoNotiTemplateEnum } from '../../../types/notification-types';
import { LayoutSplashScreen } from 'src/app/layout';

type PageProps = ParamsProps & RouteComponentProps;

export const NotificationConfigEditPage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const templateName = useParams<{ template: AutoNotiTemplateEnum }>().template;
  const Translation = (id: string) => intl.formatMessage({ id });
  const templateTitles: { [index: string]: string } = {
    sla_expiry_reminder: 'notificationTemplate.name.SLAExpired',
    system_turns_off_accept_lead_toggle_reminder: 'notificationTemplate.name.systemTurnOff',
    truned_off_accept_reminder: 'notificationTemplate.name.offToggle',
  };
  const templateRules: { [index: string]: string } = {
    sla_expiry_reminder: 'notificationTemplate.description.SLAExpired',
    system_turns_off_accept_lead_toggle_reminder: 'notificationTemplate.description.systemTurnOff',
    truned_off_accept_reminder: 'notificationTemplate.description.offToggle',
  };

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    loading,
    formStates,
    errorStates,
    locale,
    isSubmit,
    formDispatch,
    errorDispatch,
    isLinkValid,
    addTemplate,
    removeTemplate,
    submitTemplate,
  } = useNotificationConfigEdit({
    template: templateName,
  });

  if (loading) {
    return <LayoutSplashScreen />;
  } else if (!formStates || isEmptyArray(formStates)) {
    return (
      <>
        <div className={commonStyles.headerContainer}>
          <div className={commonStyles.rowContainer}>
            <div className={commonStyles.header}>Edit {Translation(templateTitles[templateName])}</div>
          </div>
          <Button variant="contained" color="inherit" onClick={props.history.goBack}>
            {Translation('app.button.back')}
          </Button>
        </div>
        <div className={styles.container}>
          <div className={`${styles.rowContainer} ${styles.textRowHeight}`}>
            <div className={styles.fieldContainer}>
              <span className={styles.subHeader}>{Translation('event.common.name')}</span>
            </div>
            <div className={styles.content}>
              <span className={styles.subHeader}>{Translation(templateTitles[templateName])}</span>
            </div>
          </div>

          <div className={`${styles.rowContainer} ${styles.textRowHeight}`}>
            <div className={styles.fieldContainer}>
              <span className={styles.subHeader}>{Translation('event.common.triggerRule')}</span>
            </div>
            <div className={styles.content}>
              <span className={styles.subHeader}>{Translation(templateRules[templateName])}</span>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={`${styles.rowContainer} ${styles.textRowHeight}`}>
            No notification is scheduled on this event
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              addTemplate();
            }}
          >
            {Translation('app.button.newNotification')}
          </Button>
        </div>
        <div className={styles.submitButton}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              submitTemplate();
            }}
          >
            {Translation('app.button.submit')}
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={commonStyles.headerContainer}>
          <div className={commonStyles.rowContainer}>
            <div className={commonStyles.header}>Edit {Translation(templateTitles[templateName])}</div>
          </div>
          <Button variant="contained" color="inherit" onClick={props.history.goBack}>
            {Translation('app.button.back')}
          </Button>
        </div>
        <div className={styles.container}>
          <div className={`${styles.rowContainer} ${styles.textRowHeight}`}>
            <div className={styles.fieldContainer}>
              <span className={styles.subHeader}>{Translation('event.common.name')}</span>
            </div>
            <div className={styles.content}>
              <span className={styles.subHeader}>{Translation(templateTitles[templateName])}</span>
            </div>
          </div>

          <div className={`${styles.rowContainer} ${styles.textRowHeight}`}>
            <div className={styles.fieldContainer}>
              <span className={styles.subHeader}>{Translation('event.common.triggerRule')}</span>
            </div>
            <div className={styles.content}>
              <span className={styles.subHeader}>{Translation(templateRules[templateName])}</span>
            </div>
          </div>
        </div>

        {formStates &&
          formStates.map((template, index) => {
            return (
              <div key={index}>
                <NotiTemplateFormComponent
                  index={index}
                  locale={locale}
                  formState={template}
                  formDispatch={formDispatch}
                  errorDispatch={errorDispatch}
                  errorState={errorStates[index]}
                  isSubmit={isSubmit}
                  // triggerTimeValidator={triggerTimeValidator}
                  isLinkValid={isLinkValid}
                  removeTemplate={removeTemplate}
                />
              </div>
            );
          })}
        {formStates[0].template === AutoNotiTemplateEnum.SYS_TRUN_OFF_ACCEPT_REMINDER ? null : (
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                addTemplate();
              }}
            >
              {Translation('app.button.newNotification')}
            </Button>
          </div>
        )}

        <div className={styles.submitButton}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              submitTemplate();
            }}
          >
            {Translation('app.button.submit')}
          </Button>
        </div>
      </>
    );
  }
};
