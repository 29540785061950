import React from "react";
import { Button } from '@mui/material';

const filterConfig = (Translation: any, onSearch: any, onReset: any, listData: any) => {
    return [
        [
            { type: 'header', label: Translation('title.event.list.filter'), sm: true },
            {
                type: 'custom',
                sm: false,
                render: () =>
                    <Button variant="contained" color="inherit"  onClick={onReset}>
                        {Translation('golbal.filters.reset')}
                    </Button>
            },
            {
                type: 'custom',
                sm: false,
                render: () =>
                    <Button variant="contained" color="secondary" onClick={onSearch} >
                        {Translation('golbal.filters.search')}
                    </Button>
            },
        ],
        [
            { type: 'input', label: Translation('component.formLabel.name'), prop: 'name', width: 300 },
            { type: 'input', label: Translation('component.formLabel.last-updated-by'), prop: 'modifier', width: 300 },
            { type: 'select', label: Translation('event.list.detail.category'), prop: 'categoryId', opts: listData.categoryList, width: 300 },
        ],
        [
            {
                type: 'select', label: Translation('title.event.event.status'), prop: 'eventStatus', opts: [
                    { label: '', value: '' },
                    { label: Translation('event.status.Upcoming.label'), value: 'Upcoming' },
                    { label: Translation('event.status.On-going.label') , value: 'On-going' },
                    { label: Translation('event.status.Expired.label'), value: 'Expired' },
                ],
                width: 300
            },
            { type: 'dateRange', label: Translation('event.list.event.time.label'), styles: {}, prop: 'timeRange', format: "DD/MM/YYYY HH:mm:ss"}
        ],
        [
            {
                type: 'select', label: Translation('component.formLabel.publish-status'), prop: 'publishStatus', opts: [
                    { label: '', value: '' },
                    { label: Translation('event.publish.status.Draft.label'), value: '0' },
                    { label: Translation('event.publish.status.Published.label'), value: '1' },
                    { label: Translation('event.publish.status.Unpublished.label'), value: '2' },
                    { label: Translation('event.publish.status.WaitingForPublish.label'), value: '3' },
                ],
                width: 300
            },
        ]
    ];
};

export default filterConfig;