import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { regionLocale } from 'src/app/i18n';

const getNameFromI18nData = (code: string, row: any) => {
  const data = row.find((item: any) => {
    return item.lang === code;
  });
  // eslint-disable-next-line
  return (data && data.name) || '';
};

const getNameColumns = (Translation: any) => {
  let namesColumns = regionLocale.map((code) => {
    const lang = Translation('navBar.lang.' + code);
    const nameColumn = {
      title: `${Translation('component.formLabel.name')} (${lang})`,
      dataIndex: 'i18nDataList',
      key: 'i18n_name_' + code,
      hideInSearch: true,
      render: (row: any) => {
        return <div style={{ width: 150 }}>{getNameFromI18nData(code, row)}</div>;
      },
    };

    return nameColumn;
  });

  return namesColumns;
};

const columns = (Translation: any, categoryList: any) => {
  const namesColumns = getNameColumns(Translation);
  const columns = [
    {
      title: Translation('component.formLabel.name'),
      dataIndex: 'name',
      hideInTable: true,
    },
    {
      title: Translation('component.formLabel.category'),
      dataIndex: 'categoryId',
      key: 'categoryId',
      render: (_: any, data: any) => {
        return (
          <span>
            {data.categoryId ? (categoryList[data.categoryId] ? categoryList[data.categoryId].text : '-') : '-'}
          </span>
        );
      },
      valueEnum: categoryList,
    },
    {
      title: Translation('title.event.event.start.time'),
      dataIndex: 'eventStartTime',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>;
      },
    },
    {
      title: Translation('title.event.event.end.time'),
      dataIndex: 'eventEndTime',
      hideInSearch: true,
      render: (data: any) => <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>,
    },
    {
      title: `${Translation('title.event.event.status')}`,
      dataIndex: 'eventStatus',
      valueEnum: {
        NotExpired: { text: '', status: 'NotExpired' },
        Upcoming: { text: Translation('event.status.Upcoming.label'), status: 'Upcoming' },
        'On-going': { text: Translation('event.status.On-going.label'), status: 'On-going' },
      },
      render: (data: any) => <span>{data ? Translation(`event.status.${data}.label`) : ''}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      dataIndex: 'modifier',
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      dataIndex: 'modifyTime',
      key: 'modifyTime',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>;
      },
    },
  ];

  return [...namesColumns, ...columns];
};

export default columns;
