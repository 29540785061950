import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  REPORT_TITLE,
  REPORT_BASE_PATH,
  AdminReportType,
  REPORT_PERFORMANCE_TITLE,
  REPORT_PERFORMANCE_PATH,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_TITLE,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_PATH,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_TITLE,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_NAMESPACE,
  REPORT_PERFORMANCE_ADBU_TARGET_TITLE,
  REPORT_PERFORMANCE_ADBU_TARGET_PATH,
  REPORT_PERFORMANCE_ADBU_TARGET_NAMESPACE,
  // REPORT_INCENTIVE_TITLE,
  // REPORT_INCENTIVE_PATH,
  // REPORT_INCENTIVE_RESULT_TITLE,
  // REPORT_INCENTIVE_RESULT_PATH,
  // REPORT_INCENTIVE_RESULT_NAMESPACE,
  REPORT_RECRUITMENT_TITLE,
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_TITLE,
  REPORT_RECRUITMENT_PRUDNA_DETAIL_TITLE,
  REPORT_RECRUITMENT_PATH,
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_PATH,
  REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH,
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE,
  REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE,
  REPORT_NEWS_AGENTS,
  REPORT_NEWS_AGENTS_NAMESPACE,
  REPORT_NEWS_AGENTS_TITLE,
  REPORT_NEWS_COMMENTS,
  REPORT_NEWS_COMMENTS_NAMESPACE,
  REPORT_NEWS_COMMENTS_TITLE,
  REPORT_NEWS_PATH,
  REPORT_NEWS_TITLE,
  REPORT_NEWS_INFO_NAMESPACE,
  REPORT_NEWS_INFO,
  REPORT_NEWS_INFO_TITLE,
  REPORT_NEWS_AGENT_ACTIVITY,
  REPORT_NEWS_AGENT_ACTIVITY_NAMESPACE,
  REPORT_NEWS_AGENT_ACTIVITY_TITLE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import ProductionTargetPage from './pages/Performance/ProductionTarget/ProductionTargetPage';
import ProductionTargetMTDPage from './pages/Performance/ProductionTargetMTD/ProductionTargetMTDPage';
import AdbuTargetPage from './pages/Performance/AdbuTarget/AdbuTargetPage';
// import IncentiveResultPage from './pages/Incentive/Result/IncentiveResultPage';
import PruDNASummaryPage from './pages/Recruitment/SummaryReport/index';
import PruDNADetailPage from './pages/Recruitment/DetailReport/index';
import AgentsPage from './pages/News/Agents/AgentsPage';
import CommentsPage from './pages/News/Comments/CommentsPage';
import InfoPage from './pages/News/Info/InfoPage';
import AgentActivityPage from './pages/News/AgentActivity/AgentActicityPage';

export const reportModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(REPORT_TITLE),
    path: REPORT_BASE_PATH,
    icon: '/media/svg/icons/Communication/Chat1.svg',
    enableRead: isPermitted(Object.values(AdminReportType), permissions),
    child: [
      {
        title: Translation(REPORT_PERFORMANCE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_READ,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_READ,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE,
            AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_READ,
            AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_CREATE,
            AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_PERFORMANCE_PRODUCTION_TARGET_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_PRODUCTION_TARGET_PATH}`,
            namespace: REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE,
            component: ProductionTargetPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_READ,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH}`,
            namespace: REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_NAMESPACE,
            component: ProductionTargetMTDPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_READ,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_PERFORMANCE_ADBU_TARGET_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_ADBU_TARGET_PATH}`,
            namespace: REPORT_PERFORMANCE_ADBU_TARGET_NAMESPACE,
            component: AdbuTargetPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_READ,
                AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_CREATE,
                AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_UPDATE], permissions),
          },
        ],
      },
      // {
      //   title: Translation(REPORT_INCENTIVE_TITLE),
      //   path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}`,
      //   enableRead: isPermitted(
      //     [
      //       AdminReportType.REPORT_INCENTIVE_RESULT_READ,
      //       AdminReportType.REPORT_INCENTIVE_RESULT_CREATE,
      //       AdminReportType.REPORT_INCENTIVE_RESULT_UPDATE,
      //     ],
      //     permissions,
      //   ),
      //   child: [
      //     {
      //       title: Translation(REPORT_INCENTIVE_RESULT_TITLE),
      //       path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}${REPORT_INCENTIVE_RESULT_PATH}`,
      //       namespace: REPORT_INCENTIVE_RESULT_NAMESPACE,
      //       component: IncentiveResultPage,
      //       enableRead: isPermitted(
      //         [
      //           AdminReportType.REPORT_INCENTIVE_RESULT_READ,
      //           AdminReportType.REPORT_INCENTIVE_RESULT_CREATE,
      //           AdminReportType.REPORT_INCENTIVE_RESULT_UPDATE,
      //         ],
      //         permissions,
      //       ),
      //       enableCreate: isPermitted([AdminReportType.REPORT_INCENTIVE_RESULT_CREATE], permissions),
      //       enableUpdate: isPermitted([AdminReportType.REPORT_INCENTIVE_RESULT_UPDATE], permissions),
      //     },
      //   ],
      // },
      {
        title: Translation(REPORT_RECRUITMENT_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ,
            AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_RECRUITMENT_PRUDNA_SUMMARY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_SUMMARY_PATH}`,
            namespace: REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE,
            component: PruDNASummaryPage,
            enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_RECRUITMENT_PRUDNA_DETAIL_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH}`,
            namespace: REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE,
            component: PruDNADetailPage,
            enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_NEWS_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_NEWS_AGENTS_READ,
            AdminReportType.REPORT_NEWS_COMMENTS_READ,
            AdminReportType.REPORT_NEWS_INFO_READ,
            AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_NEWS_AGENTS_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_AGENTS}`,
            namespace: REPORT_NEWS_AGENTS_NAMESPACE,
            component: AgentsPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_AGENTS_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_AGENTS_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_AGENTS_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_NEWS_COMMENTS_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_COMMENTS}`,
            namespace: REPORT_NEWS_COMMENTS_NAMESPACE,
            component: CommentsPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_COMMENTS_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_COMMENTS_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_COMMENTS_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_NEWS_INFO_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_INFO}`,
            namespace: REPORT_NEWS_INFO_NAMESPACE,
            component: InfoPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_INFO_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_INFO_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_INFO_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_NEWS_AGENT_ACTIVITY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_AGENT_ACTIVITY}`,
            namespace: REPORT_NEWS_AGENT_ACTIVITY_NAMESPACE,
            component: AgentActivityPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_UPDATE], permissions),
          },
        ],
      },
    ],
  };
};
