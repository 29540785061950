export const EVENT_BASE_PATH = '/event-v1';
export const EVENT_CATEGORY_PATH = "/category";
export const EVENT_LIST_PATH = "/list";

export const DATE_ERROR_TEXT = 'Archive date must be after Publish date';
export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const EMAIL_FORMAT_ERROR_TEXT = "Please fill in a valid email address";

//  These constants map to aside menu, header menu to display title
export const EVENT_TITLE = "title.event";
export const EVENT_CATEGORY_TITLE = "title.event.category";
export const EVENT_LIST_TITLE = "title.event.list";

//  These constants map to permission control, identical to permission enum defined below
export const EVENT_CATEGORIES_NAMESPACE = "EVENT-CATEGORIES";
export const EVENT_LIST_NAMESPACE = "EVENT-LIST";


export enum AdminEventsPermissionType {
  EVENT_CATEGORY_READ = "READ_ANY_EVENT-CATEGORIES",
  EVENT_CATEGORY_CREATE = "CREATE_ANY_EVENT-CATEGORIES",
  EVENT_CATEGORY_UPDATE = "UPDATE_ANY_EVENT-CATEGORIES",
  EVENT_LIST_READ = "READ_ANY_EVENT-LIST",
  EVENT_LIST_CREATE = "CREATE_ANY_EVENT-LIST",
  EVENT_LIST_UPDATE = "UPDATE_ANY_EVENT-LIST",
};
