import React, { FC, useState, useEffect, useRef } from 'react';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  TableContainer,
  TableFooter,
  TablePagination,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  InputAdornment,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { PruForm } from 'src/app/common/components/PruForm';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import { TimeRangeOptsType, TimeRangeOpts as TimeRangeOptsOrigin, NoPruDNAResultType } from '../constants';
import { useLang } from 'src/app/i18n';
import { getDayEnd, getDayStart, getDefaultDisplayDate } from 'src/app/common/utils';
import moment from 'moment-timezone';
import apis from '../apis';
import { styles } from '../styles';

// eslint-disable-next-line
const PageId = 'prudna-detail-report';
const PRUDNADetailsReport: FC<any> = (prop: any) => {
  const formRef = useRef<any>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const e = (s: string) => {
    return Translation('report.recruitment.details.' + s.replace(/ /g, '_').toLowerCase());
  };
  const { classes } = styles();
  const langOrigin = useLang();
  const lang = { 'zh-Hant': 'zhHk', hk: 'zhHk', en: 'enUs' }[langOrigin as string] || langOrigin;
  const ytdTime =
    new Date(`${new Date().getFullYear()}-01-01 UTC`).getTime() + (new Date().getTimezoneOffset() / 60) * 3600 * 1000;
  const filterInitData = {
    candidateFirstInvited: [new Date(ytdTime), getDayEnd(new Date())],
    // candidateAcceptedDate:[new Date(ytdTime), getDayEnd(new Date())],
    // pruDNASent:[new Date(ytdTime), getDayEnd(new Date())],
    // pruDNAComplete:[new Date(ytdTime), getDayEnd(new Date())]
    candidateAcceptedDate: [],
    candidateAcceptedDateType: TimeRangeOptsType.CustomizedTimeRange,
    pruDNASent: [],
    pruDNASentType: TimeRangeOptsType.CustomizedTimeRange,
    pruDNAComplete: [],
    pruDNACompleteType: TimeRangeOptsType.CustomizedTimeRange,
  };

  const [designationOpts, setDesignationOpts] = useState<{ label: string; value: any }[]>([]);
  const [zoneOpts, setZoneOpts] = useState<{ label: string; value: any }[]>([]);
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    page: 0,
    pageSize: 20,
  });
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isDownLoading, setIsDownloading] = useState<boolean>(false);
  const [filterExpended, setFilterExpended] = useState<boolean>(true);

  useEffect(() => {
    if (window.envConfig['ENABLE_ZONE'] === 'true') {
      apis.getZones().then((res: any) => {
        const zones = res.data || [];
        setZoneOpts(
          [{ label: 'All', value: '' }].concat(
            zones.map((zone: any) => {
              return { label: zone.code, value: zone.code };
            }),
          ),
        );
      });
    }
    if (window.envConfig['ENABLE_DESIGNATION'] === 'true') {
      apis.getDesignation().then((res: any) => {
        const des = res.data || [];
        const opts = [{ value: '', label: 'All' }];
        setDesignationOpts(
          opts.concat(
            des.map((d: any) => {
              return { label: d.shortName, value: d.code };
            }),
          ),
        );
      });
    }

    const state = prop.history.location.state;
    if (state?.row) {
      const newfilter = handleLinkInit(state);

      setFilterData(newfilter);
      setFilterSavedData(newfilter);
      moment.tz.guess(true); // clear cache
      fetchData(newfilter);
    } else {
      fetchData();
    }

    return;
    // eslint-disable-next-line
  }, [prop]);

  const handleLinkInit = (state: { row: any; key: any; timeRange: any; timeRangeType: any }) => {
    const row = state.row;
    const newfilter: any = {
      agentCode: row.agentCode,
      agencyCode: row.agencyCode,
      agentName: row.displayName ? row.displayName[lang as string] : '',
      designation: row.designation,
      division: row.division,
      zone: row.zone,
      candidateFirstInvited: [],
      candidateFirstInvitedType: TimeRangeOptsType.CustomizedTimeRange,
      candidateAcceptedDate: [],
      candidateAcceptedDateType: TimeRangeOptsType.CustomizedTimeRange,
      pruDNASent: [],
      pruDNASentType: TimeRangeOptsType.CustomizedTimeRange,
      pruDNAComplete: [],
      pruDNACompleteType: TimeRangeOptsType.CustomizedTimeRange,
    };
    switch (state.key) {
      case NoPruDNAResultType.Invited:
        newfilter.candidateFirstInvited = state.timeRange;
        newfilter.candidateFirstInvitedType = state.timeRangeType;
        break;
      case NoPruDNAResultType.Accepted:
        newfilter.candidateAcceptedDate = state.timeRange;
        newfilter.candidateAcceptedDateType = state.timeRangeType;
        break;
      case NoPruDNAResultType.Invitations:
        newfilter.pruDNASent = state.timeRange;
        newfilter.pruDNASentType = state.timeRangeType;
        break;
      case NoPruDNAResultType.Completed:
        newfilter.pruDNAComplete = state.timeRange;
        newfilter.pruDNACompleteType = state.timeRangeType;
        break;
      case NoPruDNAResultType.Proceed:
        newfilter.pruDNAComplete = state.timeRange;
        newfilter.pruDNACompleteType = state.timeRangeType;
        newfilter.overallRecommendation = 'Proceed';
        break;
      case NoPruDNAResultType.ProceedCaution:
        newfilter.pruDNAComplete = state.timeRange;
        newfilter.pruDNACompleteType = state.timeRangeType;
        newfilter.overallRecommendation = 'ProceedWithCaution';
        break;
      case NoPruDNAResultType.ProceedExtremeCaution:
        newfilter.pruDNAComplete = state.timeRange;
        newfilter.pruDNACompleteType = state.timeRangeType;
        newfilter.overallRecommendation = 'ProceedWithExtremeCaution';
        break;
    }
    return newfilter;
  };

  const TimeRangeOpts = TimeRangeOptsOrigin.map((opt) => {
    return {
      value: opt.value,
      label: e(opt.label),
    };
  });
  const changeFilterData = (d: object) => {
    setFilterData({ ...filterData, ...d });
  };
  const onTimeTypeChange = (v: any, typeIndex: string, dataIndex: string) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const offsetTime = (new Date().getTimezoneOffset() / 60) * 3600 * 1000;

    const ytdTime = new Date(`${currentYear}-01-01 UTC`).getTime() + offsetTime;
    const currentMonthTime = new Date(`${currentYear}-${currentMonth}-01 UTC`).getTime() + offsetTime;
    const lastMonthTime = new Date(`${currentYear}-${currentMonth - 1}-01 UTC`).getTime() + offsetTime;

    const data: any = {};
    switch (parseInt(v)) {
      case TimeRangeOptsType.YTD:
        data[typeIndex] = v;
        data[dataIndex] = [new Date(ytdTime), getDayEnd(new Date())];
        changeFilterData(data);
        break;
      case TimeRangeOptsType.LastMonth:
        data[typeIndex] = v;
        data[dataIndex] = [new Date(lastMonthTime), new Date(currentMonthTime)];
        changeFilterData(data);
        break;
      case TimeRangeOptsType.CurrentMonth:
        data[typeIndex] = v;
        data[dataIndex] = [new Date(currentMonthTime), getDayEnd(new Date())];
        changeFilterData(data);
        break;
      case TimeRangeOptsType.CustomizedTimeRange:
        data[typeIndex] = v;
        data[dataIndex] = [null, null];
        changeFilterData(data);
        break;
      default:
        return;
    }
  };

  const filterConfig = () => {
    return [
      [
        { type: 'header', label: e('Report Filter'), sm: true },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="inherit" onClick={onReset} style={{ marginRight: 20 }}>
              {Translation('golbal.filters.reset')}
            </Button>
          ),
        },
        {
          type: 'custom',
          sm: false,
          render: () => (
            <Button variant="contained" color="secondary" onClick={onSearch}>
              {Translation('golbal.filters.search')}
            </Button>
          ),
        },
      ],
      [
        {
          type: 'accordion',
          sm: true,
          style: { boxShadow: 'none !important', margin: '0 -20px', padding: '0 20px' },
          onChange: (v: boolean) => {
            setFilterExpended(v);
          },
          summary: [
            [
              {
                type: window.envConfig['ENABLE_ZONE'] === 'true' ? 'select' : 'none',
                label: e('Zone'),
                prop: 'zone',
                opts: zoneOpts,
                width: 400,
              },
              {
                type: 'select',
                label: e('Candidate First Invited Time'),
                width: 300,
                opts: TimeRangeOpts,
                prop: 'candidateFirstInvitedType',
                onChange: (v: any) => onTimeTypeChange(v, 'candidateFirstInvitedType', 'candidateFirstInvited'),
              },
              {
                type:
                  filterData.candidateFirstInvitedType == TimeRangeOptsType.CustomizedTimeRange ? 'dateRange' : 'none',
                label: '',
                prop: 'candidateFirstInvited',
                width: 300,
                // rules:[{type: 'array', len: 2, fields:{
                //   0:{type:'date', required:true, message:e('Please input the time range')},
                //   1:{type:'date', required:true, message:e('Please input the time range')},
                // }}]
              },
            ],
            [
              {
                type: window.envConfig['ENABLE_DIVISION'] === 'true' ? 'input' : 'none',
                label: e('Division'),
                prop: 'division',
                width: 400,
              },
              { type: 'input', label: e('Agent Code'), prop: 'agentCode', width: 400 },
              { type: 'input', label: e('Agent Name'), prop: 'agentName', width: 400 },
            ],
          ],
          details: [
            [
              { type: 'input', label: e('Candidate Name'), prop: 'candidateName', width: 400 },
              {
                type: 'select',
                label: e('Candidate Accepted Time'),
                opts: TimeRangeOpts,
                width: 300,
                prop: 'candidateAcceptedDateType',
                onChange: (v: any) => onTimeTypeChange(v, 'candidateAcceptedDateType', 'candidateAcceptedDate'),
              },
              {
                type:
                  filterData.candidateAcceptedDateType == TimeRangeOptsType.CustomizedTimeRange ? 'dateRange' : 'none',
                label: '',
                prop: 'candidateAcceptedDate',
                width: 400,
                // rules:[{type: 'array', len: 2, fields:{
                //   0:{type:'date', required:true, message:e('Please input the time range')},
                //   1:{type:'date', required:true, message:e('Please input the time range')},
                // }}]
              },
            ],
            [
              {
                type: 'select',
                label: e('PRUDNA Assessment Status'),
                prop: 'pruDNAAssessmentStatus',
                width: 400,
                opts: [
                  { label: e('All'), value: '' },
                  { label: e('Completed'), value: 'Completed' },
                  { label: e('Not Completed'), value: 'InComplete' },
                  { label: e('Not Sent'), value: 'NotSend' },
                ],
              },
              {
                type: 'select',
                label: e('PRUDNA Sent Time'),
                opts: TimeRangeOpts,
                prop: 'pruDNASentType',
                width: 300,
                onChange: (v: any) => onTimeTypeChange(v, 'pruDNASentType', 'pruDNASent'),
              },
              {
                type: filterData.pruDNASentType == TimeRangeOptsType.CustomizedTimeRange ? 'dateRange' : 'none',
                label: '',
                prop: 'pruDNASent',
                width: 400,
                // rules:[{type: 'array', len: 2, fields:{
                //   0:{type:'date', required:true, message:e('Please input the time range')},
                //   1:{type:'date', required:true, message:e('Please input the time range')},
                // }}]
              },
            ],
            [
              {
                type: 'select',
                label: e('Overall Recommendation'),
                prop: 'overallRecommendation',
                width: 400,
                opts: [
                  { label: e('All'), value: '' },
                  { label: e('Proceed with Extreme Caution'), value: 'ProceedWithExtremeCaution' },
                  { label: e('Proceed with Caution'), value: 'ProceedWithCaution' },
                  { label: e('Proceed'), value: 'Proceed' },
                ],
              },
              {
                type: 'select',
                label: e('PRUDNA Completion Time'),
                opts: TimeRangeOpts,
                prop: 'pruDNACompleteType',
                width: 300,
                onChange: (v: any) => onTimeTypeChange(v, 'pruDNACompleteType', 'pruDNAComplete'),
              },
              {
                type: filterData.pruDNACompleteType == TimeRangeOptsType.CustomizedTimeRange ? 'dateRange' : 'none',
                label: '',
                prop: 'pruDNAComplete',
                width: 400,
                // rules:[{type: 'array', len: 2, fields:{
                //   0:{type:'date', required:true, message:e('Please input the time range')},
                //   1:{type:'date', required:true, message:e('Please input the time range')},
                // }}]
              },
            ],
            [
              {
                type: 'textRange',
                label: e('Probability of Success'),
                prop: 'probability',
                width: 400,
                props: [
                  {
                    type: 'number',
                    InputProps: {
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    },
                  },
                  {
                    type: 'number',
                    InputProps: {
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    },
                  },
                ],
              },
              {
                type: window.envConfig['ENABLE_DESIGNATION'] === 'true' ? 'select' : 'none',
                label: e('Designation'),
                prop: 'designation',
                opts: designationOpts,
                width: 400,
              },
              {
                type: window.envConfig['ENABLE_AGENCY_CODE'] === 'true' ? 'input' : 'none',
                label: e('Agency Code'),
                prop: 'agencyCode',
                width: 400,
              },
            ],
          ],
        },
      ],
    ];
  };
  const tableHeader = () => [
    [
      { type: 'header', label: e('Candidate & PRUDNA Details Report'), sm: true },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" color="secondary" onClick={onExport}>
            {Translation('export.list.text')}
          </Button>
        ),
      },
    ],
  ];

  interface tableConfigType {
    title: string;
    prop: string;
    align?: any;
    render?: any;
    minWidth?: number;
  }
  const tableConfig = (): tableConfigType[] => {
    const config = [
      { title: e('Agent Code'), prop: 'agentCode' },
      {
        title: e('Agent Name'),
        prop: 'agentName',
        render: (row: any) => <span>{row.displayName ? row.displayName[lang as string] : ''}</span>,
      },
      { title: e('Invitation Remark'), prop: 'invitationRemark' },
      { title: e('Candidate Name'), prop: 'candidateName' },
      { title: e('Candidate Mobile'), prop: 'candidateMobile' },
      {
        title: e('Candidate First Invited Time'),
        prop: 'candidateFirstInvitedTime',
        render: (row: any) =>
          row.candidateFirstInvitedTime &&
          getDefaultDisplayDate(new Date(row.candidateFirstInvitedTime), 'longDatetime'),
      },
      {
        title: e('Candidate Accepted Time'),
        prop: 'candidateAcceptedTime',
        render: (row: any) =>
          row.candidateAcceptedTime && getDefaultDisplayDate(new Date(row.candidateAcceptedTime), 'longDatetime'),
      },
      { title: e('Interview Arranged(Y/N)'), prop: 'interviewArranged' },
      { title: e('PRUDNA Assessment Sent(Y/N)'), prop: 'pruDNAAssessmentSent' },
      {
        title: e('Initial PRUDNA Sent Time'),
        prop: 'initPruDNASendTime',
        render: (row: any) =>
          row.initPruDNASendTime && getDefaultDisplayDate(new Date(row.initPruDNASendTime), 'longDatetime'),
      },
      { title: e('PRUDNA assessment Status'), prop: 'pruDNAAssessmentStatus' },
      { title: e('Probability of Success'), prop: 'successPossibility' },
      { title: e('Overall Recommendation'), prop: 'overallRecommendation' },
      {
        title: e('PRUDNA Report Link(Chi)'),
        prop: 'pruDNAReportLink',
        render: (row: any) => (
          <a className={classes.link} href={row.pruDNAReportLink} target="_blank" rel="noreferrer">
            {row.pruDNAReportLink}
          </a>
        ),
      },
      { title: e('PRUDNA Completion Time'), prop: 'pruDNACompletionTime' },
      {
        title: e('Designation'),
        prop: 'designation',
        render: (row: any) => {
          const disignation = designationOpts.find((d: any) => {
            return d.value === row.designation;
          });
          return <span>{disignation?.label} </span>;
        },
      },
      { title: e('Zone'), prop: 'zone' },
      { title: e('Agency Code'), prop: 'agencyCode' },
      { title: e('Division'), prop: 'division' },
    ];
    if (window.envConfig['ENABLE_DESIGNATION'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'designation'),
        1,
      );
    }
    if (window.envConfig['ENABLE_ZONE'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'zone'),
        1,
      );
    }
    if (window.envConfig['ENABLE_AGENCY_CODE'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'agencyCode'),
        1,
      );
    }
    if (window.envConfig['ENABLE_DIVISION'] !== 'true') {
      config.splice(
        config.findIndex((v) => v.prop === 'division'),
        1,
      );
    }

    return config;
  };

  const mapToApi = (params: any) => {
    params.size = params.pageSize;
    params.page = params.page + 1 || undefined;
    params.timeZone = moment.tz.guess();
    params.lan = lang;
    delete params.pageSize;

    const { candidateFirstInvited, candidateAcceptedDate, pruDNASent, pruDNAComplete, probability } = params;

    params.firstInvitedStart = candidateFirstInvited && candidateFirstInvited[0];
    params.firstInvitedEnd = candidateFirstInvited && candidateFirstInvited[1];
    if (params.candidateFirstInvitedType === TimeRangeOptsType.CustomizedTimeRange) {
      if (params.firstInvitedEnd) {
        params.firstInvitedEnd = getDayEnd(params.firstInvitedEnd);
      }
      if (params.firstInvitedStart) {
        params.firstInvitedStart = getDayStart(params.firstInvitedStart);
      }
    }

    params.acceptedStart = candidateAcceptedDate && candidateAcceptedDate[0];
    params.acceptedEnd = candidateAcceptedDate && candidateAcceptedDate[1];
    if (params.candidateAcceptedDateType === TimeRangeOptsType.CustomizedTimeRange) {
      if (params.acceptedEnd) {
        params.acceptedEnd = getDayEnd(params.acceptedEnd);
      }
      if (params.acceptedStart) {
        params.acceptedStart = getDayStart(params.acceptedStart);
      }
    }

    params.pruDNASentStart = pruDNASent && pruDNASent[0];
    params.pruDNASentEnd = pruDNASent && pruDNASent[1];
    if (params.pruDNASentType === TimeRangeOptsType.CustomizedTimeRange) {
      if (params.pruDNASentEnd) {
        params.pruDNASentEnd = getDayEnd(params.pruDNASentEnd);
      }
      if (params.pruDNASentStart) {
        params.pruDNASentStart = getDayStart(params.pruDNASentStart);
      }
    }

    params.pruDNACompleteStart = pruDNAComplete && pruDNAComplete[0];
    params.pruDNACompleteEnd = pruDNAComplete && pruDNAComplete[1];
    if (params.pruDNACompleteType === TimeRangeOptsType.CustomizedTimeRange) {
      if (params.pruDNACompleteEnd) {
        params.pruDNACompleteEnd = getDayEnd(params.pruDNACompleteEnd);
      }
      if (params.pruDNACompleteStart) {
        params.pruDNACompleteStart = getDayStart(params.pruDNACompleteStart);
      }
    }

    params.possibilityStart = probability && probability[0];
    params.possibilityEnd = probability && probability[1];

    delete params.candidateFirstInvited;
    delete params.candidateFirstInvitedType;
    delete params.candidateAcceptedDate;
    delete params.candidateAcceptedDateType;
    delete params.pruDNASent;
    delete params.pruDNASentType;
    delete params.pruDNAComplete;
    delete params.pruDNACompleteType;

    delete params.probability;
  };

  const formValidate = () => {
    const { validate } = formRef!.current;
    return validate && validate();
  };
  const fetchData = (newState?: any) => {
    formValidate()
      .then((errs: any) => {
        if (!errs) {
          const nf = newState || {};
          const requestParams = {
            ...filterSavedData,
            ...pageData,
            ...nf,
          };
          mapToApi(requestParams);
          apis
            .getDetailReport(requestParams)
            .then((res: any) => {
              setTableData(res.data ? res.data.content : []);
              setPageData({
                page: res.data?.number || 0,
                pageSize: res.data?.size || 0,
                total: res.data?.totalElements || 0,
              });
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      })
      .catch((errs: any) => {
        console.error(errs);
      });
  };
  const onExport = async () => {
    try {
      const conditions = { ...filterSavedData, lan: lang };
      mapToApi(conditions);
      setIsDownloading(true);
      await apis.getDetailReportExcel(conditions).then((res) => {
        const blob = new Blob([res.data || res], { type: res.data.type });
        const fileName = `PRUDNA_Details_Report_${new Date().toLocaleDateString()}.xlsx`;
        if ('msSaveOrOpenBlob' in navigator) {
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          return;
        }
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        if ('download' in document.createElement('a')) {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // 对不支持download进行兼容
          link.target = '_blank';
          link.click();
        }
        URL.revokeObjectURL(url);
      });
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  const onSearch = () => {
    setFilterSavedData(filterData);
    const newPageData = {
      ...pageData,
      page: 0,
    };
    setPageData(newPageData);
    fetchData({ ...filterData, ...newPageData });
  };
  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const onChangePage = (page: number) => {
    setFilterData(filterSavedData);
    const newPageData = {
      ...pageData,
      page,
    };
    setPageData(newPageData);
    fetchData(newPageData);
  };
  const onChangePageRows = (rows: any) => {
    setFilterData(filterSavedData);
    const newPageData = {
      ...pageData,
      page: 0,
      pageSize: rows,
    };
    setPageData(newPageData);
    fetchData(newPageData);
  };

  const PruTableHeader = withStyles(TableCell, (theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));
  return (
    <>
      <PruForm
        ref={formRef}
        config={filterConfig()}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
        opts={{ labelWidth: 100 }}
      ></PruForm>

      <PruForm
        config={tableHeader()}
        data={[]}
        onChange={() => {}}
        space={10}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>
      <TableContainer
        component={Paper}
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          maxHeight: `calc(100vh - ${520 + (filterExpended ? 0 : 280)}px)`,
        }}
      >
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig().map((item) => (
                <PruTableHeader key={item.prop} align={item.align || 'left'} style={{ minWidth: item.minWidth || 140 }}>
                  {Translation(item.title) || item.title}
                </PruTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={12} align="center">
                  <LinearProgress color="secondary" variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {tableData.length === 0 && (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <div style={{ margin: '30px 0 30px 0 ' }}>No record to display</div>
                </TableCell>
              </TableRow>
            )}
            {tableData.map((datas) => (
              <TableRow>
                {tableConfig().map((config) => (
                  <TableCell>{config.render ? config.render(datas) : datas[config.prop]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.page || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default PRUDNADetailsReport;
