import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { RECRUITMENT_BASE_PATH, RECRUITMENT_APPLICATION_FORM_PATH } from '../../constants';
import OnboardingApplicationDetailPage from './onboarding-application-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import PruRoute from '../../../../common/components/PruRoute';

export const onboardingApplicationBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}`;
const OnboardingApplicationRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${onboardingApplicationBasePath}`} to={`${onboardingApplicationBasePath}/setting`} />
      <PruRoute exact path={`${onboardingApplicationBasePath}/setting`} component={OnboardingApplicationDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default OnboardingApplicationRoutes;
